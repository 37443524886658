import "./style.less";
import {
  Panel,
  Container,
  Carousel,
  Affix,
  Button,
  IconButton,
  Icon,
  List,
} from "rsuite";
import React from "react";
import TestimonyCard from "../TestimonyCard";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";

const QuestionsPanel = (props) => {
  const isMobile = props.isMobile;
  const questionData = [
    {
      question: "faqs_page.body.faq.title_1",
      answer: "faqs_page.body.faq.text_1",
    },
    {
      question: "faqs_page.body.faq.title_2",
      answer: "faqs_page.body.faq.text_2",
    },
    {
      question: "faqs_page.body.faq.title_3",
      answer: "faqs_page.body.faq.text_3",
    },
    {
      question: "faqs_page.body.faq.title_4",
      answer: "faqs_page.body.faq.text_4",
      button: "all_pages.header.floating_bar.text_highlight",
    },
    {
      question: "faqs_page.body.faq.title_5",
      answer: "faqs_page.body.faq.text_5",
      button: "all_pages.header.floating_bar.text_highlight",
    },
    {
      question: "faqs_page.body.faq.title_6",
      answer: "faqs_page.body.faq.text_6",
    },
    {
      question: "faqs_page.body.faq.title_7",
      answer: "faqs_page.body.faq.text_7",
      bullets: [
        "faqs_page.body.faq.text_7 (2)",
        "faqs_page.body.faq.text_7 (3)",
        "faqs_page.body.faq.text_7 (4)",
        "faqs_page.body.faq.text_7 (5)",
        "faqs_page.body.faq.text_7 (6)",
        "faqs_page.body.faq.text_7 (7)",
        "faqs_page.body.faq.text_7 (8)",
        "faqs_page.body.faq.text_7 (9)",
        "faqs_page.body.faq.text_7 (10)",
        "faqs_page.body.faq.text_7 (11)",
      ],
    },
  ];
  return (
    <Translation>
      {(t) => (
        <React.Fragment>
          <Panel className="questions-panel">
            <h2>{t("faqs_page.body.title_2.text")}</h2>
            <Container className="panel-questions">
              <List>
                {questionData.map((question) => (
                  <Panel
                    className="question"
                    header={<h4>{t(question.question)}</h4>}
                    collapsible={true}
                  >
                    <h5>{t(question.answer)}</h5>
                    {question.bullets ? (
                      <ul>
                        {question.bullets.map((bullet) => (
                          <li>
                            <h5>{t(bullet)}</h5>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </Panel>
                ))}
              </List>
            </Container>
            <Link to="/pricing" className="pricing-link">
              <h4>{t("faqs_page.body.faq.link_prices")}</h4>
            </Link>
          </Panel>
        </React.Fragment>
      )}
    </Translation>
  );
};

export default QuestionsPanel;
