import React, { useEffect, useState } from "react";
import { Trans, Translation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Affix,
  Button,
  Divider,
  Dropdown,
  Icon,
  Panel,
  SelectPicker,
  Table,
} from "rsuite";
import i18n from "../../i18n";
import "./style.less";

const { Cell, Column, HeaderCell } = Table;

let data = [
  {
    id: 1,
    feature: "plans_page.body.plans_detail.feat_1.text",
    standard: "1",
    professional: "1",
    enterprise: "plans_page.body.enterprise_plan.text_main_features_4",
  },
  {
    id: 2,
    feature: "plans_page.body.plans_detail.feat_2.text",
    standard: "3",
    professional: "5",
    enterprise: "plans_page.body.enterprise_plan.text_main_features_4",
  },
  {
    id: 3,
    feature: "plans_page.body.plans_detail.feat_3.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 301,
    feature: "plans_page.body.plans_detail.feat_031.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 302,
    feature: "plans_page.body.plans_detail.feat_032.feat_032.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 4,
    feature: "plans_page.body.plans_detail.feat_4.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  /*
  {
    id: 5,
    feature: "plans_page.body.plans_detail.feat_5.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  */
  {
    id: 6,
    feature: "plans_page.body.plans_detail.feat_6.text",
    standard: "plans_page.body.plans_detail.feat_6.standard",
    professional: "plans_page.body.plans_detail.feat_6.professional",
    enterprise: "plans_page.body.plans_detail.feat_6.enterprise",
  },
  {
    id: 7,
    feature: "plans_page.body.plans_detail.feat_7.text",
    standard: "plans_page.body.plans_detail.feat_7.standard",
    professional: "plans_page.body.plans_detail.feat_7.professional",
    enterprise: "plans_page.body.plans_detail.feat_7.professional",
  },
  /*
  {
    id: 7,
    feature: "Idiomas.Centro.de.Ajuda",
    standard: ["PT", "EN"],
    professional: ["PT", "EN"],
    enterprise: "plans_page.body.enterprise_plan.text_main_features_4",
  },
  */
  {
    id: 8,
    feature: "plans_page.body.plans_detail.feat_8.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  /*
  {
  id: 801,
  feature: "plans_page.body.plans_detail.feat_081.text",
  standard: false,
  professional: false,
  enterprise: true,
  },
  */
  {
    id: 802,
    feature: "plans_page.body.plans_detail.feat_082.text",
    standard: false,
    professional: false,
    enterprise: true,
  },
  {
    id: 9,
    highlight: true,
    feature: "plans_page.body.plans_detail.title_1",
    standard: "",
    professional: "",
    enterprise: "",
  },
  {
    id: 10,
    feature: "plans_page.body.plans_detail.feat_9.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 11,
    feature: "plans_page.body.plans_detail.feat_10.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 101,
    feature: "plans_page.body.plans_detail.feat_101.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 12,
    feature: "plans_page.body.plans_detail.feat_11.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 13,
    highlight: true,
    feature: "plans_page.body.plans_detail.title_2",
    standard: "",
    professional: "",
    enterprise: "",
  },
  {
    id: 14,
    feature: "plans_page.body.plans_detail.feat_12.text",
    standard: "plans_page.body.plans_detail.feat_12.standard",
    professional: true,
    enterprise: true,
  },
  {
    id: 15,
    feature: "plans_page.body.plans_detail.feat_13.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 16,
    feature: "plans_page.body.plans_detail.feat_14.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 16,
    feature: "plans_page.body.plans_detail.feat_141.text",
    standard: "plans_page.body.plans_detail.feat_141.standard",
    professional: "plans_page.body.plans_detail.feat_141.professional",
    enterprise: "plans_page.body.plans_detail.feat_141.enterprise",
  },
  {
    id: 17,
    feature: "plans_page.body.plans_detail.feat_15.text",
    standard: false,
    professional: true,
    enterprise: true,
  },
  {
    id: 18,
    feature: "plans_page.body.plans_detail.feat_16.text",
    standard: false,
    professional: true,
    enterprise: true,
  },
  {
    id: 19,
    feature: "plans_page.body.plans_detail.feat_17.text",
    standard: false,
    professional: true,
    enterprise: true,
  },
  {
    id: 20,
    highlight: true,
    feature: "plans_page.body.plans_detail.title_3",
    standard: "",
    professional: "",
    enterprise: "",
  },
  {
    id: 21,
    feature: "plans_page.body.plans_detail.feat_171.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 22,
    feature: "plans_page.body.plans_detail.feat_18.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 23,
    feature: "plans_page.body.plans_detail.feat_19.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 24,
    feature: "plans_page.body.plans_detail.feat_5.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 25,
    feature: "plans_page.body.plans_detail.feat_21.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 26,
    feature: "plans_page.body.plans_detail.feat_22.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 27,
    feature: "plans_page.body.plans_detail.feat_23.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 28,
    feature: "plans_page.body.plans_detail.feat_24.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 29,
    feature: "plans_page.body.plans_detail.feat_25.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 29,
    feature: "plans_page.body.plans_detail.feat_251.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 29,
    feature: "plans_page.body.plans_detail.feat_252.text",
    standard: "plans_page.body.plans_detail.feat_252.standard",
    professional: "plans_page.body.plans_detail.feat_252.professional",
    enterprise: "plans_page.body.plans_detail.feat_252.enterprise",
  },
  {
    id: 29,
    feature: "plans_page.body.plans_detail.feat_20.text",
    standard: false,
    professional: true,
    enterprise: true,
  },
  {
    id: 30,
    feature: "plans_page.body.plans_detail.feat_26.text",
    standard: false,
    professional: true,
    enterprise: true,
  },
  {
    id: 31,
    feature: "plans_page.body.plans_detail.feat_27.text",
    standard: false,
    professional: false,
    enterprise: true,
  },
  {
    id: 32,
    highlight: true,
    feature: "plans_page.body.plans_detail.title_4",
    standard: "",
    professional: "",
    enterprise: "",
  },
  {
    id: 33,
    feature: "plans_page.body.plans_detail.feat_28.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 34,
    feature: "plans_page.body.plans_detail.feat_29.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 35,
    feature: "plans_page.body.plans_detail.feat_30.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 36,
    feature: "plans_page.body.plans_detail.feat_31.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 37,
    feature: "plans_page.body.plans_detail.feat_32.text",
    standard: false,
    professional: true,
    enterprise: true,
  },
  {
    id: 38,
    feature: "plans_page.body.plans_detail.feat_33.text",
    standard: false,
    professional: false,
    enterprise: true,
  },
  {
    id: 39,
    feature: "plans_page.body.plans_detail.feat_34.text",
    standard: false,
    professional: false,
    enterprise: true,
  },
  {
    id: 40,
    highlight: true,
    feature: "plans_page.body.plans_detail.title_5",
    standard: "",
    professional: "",
    enterprise: "",
  },
  {
    id: 41,
    feature: "plans_page.body.plans_detail.feat_35.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 41,
    feature: "plans_page.body.plans_detail.feat_351.text",
    standard: "plans_page.body.plans_detail.feat_351.standard",
    professional: "plans_page.body.plans_detail.feat_351.professional",
    enterprise: "plans_page.body.plans_detail.feat_351.enterprise",
  },
  {
    id: 42,
    feature: "plans_page.body.plans_detail.feat_36.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 43,
    feature: "plans_page.body.plans_detail.feat_371.text",
    standard: true,
    professional: true,
    enterprise: true,
  },
  {
    id: 43,
    feature: "plans_page.body.plans_detail.feat_372.text",
    standard: "plans_page.body.plans_detail.feat_372.standard",
    professional: "plans_page.body.plans_detail.feat_372.professional",
    enterprise: "plans_page.body.plans_detail.feat_372.enterprise",
  },
  {
    id: 43,
    feature: "plans_page.body.plans_detail.feat_373.text",
    standard: "plans_page.body.plans_detail.feat_373.standard",
    professional: "plans_page.body.plans_detail.feat_373.professional",
    enterprise: "plans_page.body.plans_detail.feat_373.enterprise",
  },
  {
    id: 43,
    feature: "plans_page.body.plans_detail.feat_37.text",
    standard: false,
    professional: true,
    enterprise: true,
  },
  {
    id: 43,
    feature: "plans_page.body.plans_detail.feat_374.text",
    standard: false,
    professional: false,
    enterprise: true,
  },
  {
    id: 46,
    feature: "plans_page.body.plans_detail.feat_38.text",
    standard: false,
    professional: false,
    enterprise: true,
  },
  {
    id: 47,
    feature: "plans_page.body.plans_detail.feat_39.text",
    standard: false,
    professional: false,
    enterprise: true,
  },
];

const ImageCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <Translation>
      {(t) => (
        <div>
          <h6>
            {typeof rowData[dataKey] === "boolean" ? (
              (rowData[dataKey] && <Icon icon="check" size="1x" />) || (
                <Icon icon="" />
              )
            ) : typeof rowData[dataKey] === "object" ? (
              <>{rowData[dataKey].join(" | ")}</>
            ) : rowData[dataKey] === "button" ? (
              <>
                <a
                  href={`https://app.urlbuilder.online/signup?lang=${i18n.language}`}
                  target="_blank"
                >
                  <Button
                    className={"button-secondary bordered green"}
                    onClick={props.onSelect}
                    style={{ width: "100%" }}
                  >
                    {t(
                      "plans_page.body.standard_plan.button_cta"
                    ).toUpperCase()}
                  </Button>
                </a>
              </>
            ) : rowData[dataKey] === "button-main" ? (
              <>
                {!props.isMobile && (
                  <a
                    href={`https://app.urlbuilder.online/signup?lang=${i18n.language}`}
                    target="_blank"
                  >
                    <Button
                      className={"button-primary blue"}
                      style={{ width: "100%" }}
                    >
                      {t(
                        "plans_page.body.standard_plan.button_cta"
                      ).toUpperCase()}
                    </Button>
                  </a>
                )}
              </>
            ) : rowData[dataKey] === "button-enterprise" ? (
              <>
                {!props.isMobile && (
                  <Link to="/pricing?contact">
                    <Button
                      className={"button-secondary bordered green"}
                      onClick={props.onSelect}
                      style={{ width: "100%" }}
                    >
                      {t(
                        "plans_page.body.enterprise_plan.button_cta"
                      ).toUpperCase()}
                    </Button>
                  </Link>
                )}
              </>
            ) : (
              t(rowData[dataKey])
            )}
          </h6>
        </div>
      )}
    </Translation>
  </Cell>
);

function FeatureDetailsTable(props) {
  const [plan, setPlan] = useState("professional");
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(false);
    window.setTimeout(() => {
      setShow(true);
    }, 100);
  }, []);

  return (
    <Translation>
      {(t) => (
        <Panel
          className="feature-details-panel"
          id={props.isMobile ? "mobile-features" : "desktop-features"}
        >
          <h2 className="feature-details-title">
            {t("plans_page.body.plans_detail.title")}
          </h2>

          {(!props.isMobile && (
            <div className="feature-table">
              <Affix top={66}>
                <div className="feature-header">
                  <div className="feature-cell">
                    <h4>{"  "}</h4>
                  </div>
                  <div className="feature-cell">
                    <h4>{t("plans_page.body.standard_plan.text_plan_name")}</h4>
                  </div>
                  <div className="feature-cell">
                    <h4>
                      {t("plans_page.body.professional_plan.text_plan_name")}
                    </h4>
                  </div>
                  <div className="feature-cell">
                    <h4>
                      {t("plans_page.body.enterprise_plan.text_plan_name")}
                    </h4>
                  </div>
                </div>
              </Affix>
              {data.map((row) => (
                <div
                  className={`feature-row ${
                    row.highlight ? "highlighted" : ""
                  }`}
                >
                  <div className="feature-cell">
                    <h6>{t(row.feature)}</h6>
                  </div>
                  <div className="feature-cell">
                    {typeof row.standard === "boolean" ? (
                      row.standard ? (
                        <h6>
                          {" "}
                          <Icon icon="check" size="1x" />
                        </h6>
                      ) : (
                        <h6>
                          {" "}
                          <Icon icon="" size="1x" />
                        </h6>
                      )
                    ) : (
                      <span>{t(row.standard)}</span>
                    )}
                  </div>
                  <div className="feature-cell">
                    {typeof row.professional === "boolean" ? (
                      row.professional ? (
                        <h6>
                          {" "}
                          <Icon icon="check" size="1x" />
                        </h6>
                      ) : (
                        <h6>
                          {" "}
                          <Icon icon="" size="1x" />
                        </h6>
                      )
                    ) : (
                      <span>{t(row.professional)}</span>
                    )}
                  </div>
                  <div className="feature-cell">
                    {typeof row.enterprise === "boolean" ? (
                      row.enterprise ? (
                        <h6>
                          {" "}
                          <Icon icon="check" size="1x" />
                        </h6>
                      ) : (
                        <h6>
                          {" "}
                          <Icon icon="" size="1x" />
                        </h6>
                      )
                    ) : (
                      <span>{t(row.enterprise)}</span>
                    )}
                  </div>
                </div>
              ))}
              <div className={`feature-row`}>
                <div className="feature-cell"></div>
                <div className="feature-cell">
                  <a
                    href={`https://app.urlbuilder.online/signup?lang=${i18n.language}`}
                    target="_blank"
                  >
                    <Button
                      className={"button-secondary bordered green"}
                      onClick={props.onSelect}
                      style={{ width: "90%" }}
                    >
                      {t(
                        "plans_page.body.standard_plan.button_cta"
                      ).toUpperCase()}
                    </Button>
                  </a>
                </div>
                <div className="feature-cell">
                  <a
                    href={`https://app.urlbuilder.online/signup?lang=${i18n.language}`}
                    target="_blank"
                  >
                    <Button
                      className={"button-primary blue"}
                      onClick={props.onSelect}
                      style={{ width: "90%" }}
                    >
                      {t(
                        "plans_page.body.standard_plan.button_cta"
                      ).toUpperCase()}
                    </Button>
                  </a>
                </div>
                <div className="feature-cell">
                  <Link to={`/pricing?contact`}>
                    <Button
                      className={"button-secondary bordered green"}
                      onClick={props.onSelect}
                      style={{ width: "90%" }}
                    >
                      {t(
                        "plans_page.body.enterprise_plan.button_cta"
                      ).toUpperCase()}
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          )) || (
            <div className="feature-table mobile">
              <Affix top={50}>
                <div className={`feature-header ${plan}`}>
                  <SelectPicker
                    data={[
                      { label: "STANDARD", value: "standard" },
                      { label: "PROFESSIONAL", value: "professional" },
                      { label: "ENTERPRISE", value: "enterprise" },
                    ]}
                    onChange={(val) => setPlan(val)}
                    appearance="subtle"
                    value={plan}
                    cleanable={false}
                    searchable={false}
                    renderMenuItem={(value, item) => <h4>{t(item.label)}</h4>}
                    renderValue={(value, item) => <h4>{t(item.label)}</h4>}
                    style={{ width: 224 }}
                  />
                </div>
              </Affix>
              {data.map((row) => (
                <div
                  className={`feature-row ${
                    row.highlight ? "highlighted" : ""
                  }`}
                >
                  <div className="feature-cell">
                    <h6>{t(row.feature)}</h6>
                  </div>
                  <div className="feature-cell">
                    {typeof row[plan] === "boolean" ? (
                      row[plan] ? (
                        <h6>
                          {" "}
                          <Icon icon="check" size="1x" />
                        </h6>
                      ) : (
                        <h6>
                          {" "}
                          <Icon icon="" size="1x" />
                        </h6>
                      )
                    ) : (
                      <span>{t(row[plan])}</span>
                    )}
                  </div>
                </div>
              ))}
              <div className={`feature-row`}>
                <div className="feature-cell">
                  {plan === "enterprise" ? (
                    <Link to={`/pricing?contact`}>
                      <Button
                        className={
                          plan === "professional"
                            ? "button-primary blue"
                            : "button-secondary bordered green"
                        }
                        onClick={props.onSelect}
                        style={{ width: "90%" }}
                      >
                        {t(
                          "plans_page.body.enterprise_plan.button_cta"
                        ).toUpperCase()}
                      </Button>
                    </Link>
                  ) : (
                    <a
                      href={`https://app.urlbuilder.online/signup?lang=${i18n.language}`}
                      target="_blank"
                    >
                      <Button
                        className={
                          plan === "professional"
                            ? "button-primary blue"
                            : "button-secondary bordered green"
                        }
                        onClick={props.onSelect}
                        style={{ width: "90%" }}
                      >
                        {t(
                          "plans_page.body.standard_plan.button_cta"
                        ).toUpperCase()}
                      </Button>
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
        </Panel>
      )}
    </Translation>
  );
}

export default FeatureDetailsTable;
