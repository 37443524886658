import React from "react";
import { I18nContext, Translation } from "react-i18next";
import HeaderPanel from "../../../components/HeaderPanel";
import { Button, Divider, Panel } from "rsuite";
import { Table } from "rsuite";

import "./style.less";
import i18n from "../../../i18n";

import i18next from "i18next";

import pt from "./Current/pt-PT.json";
import en from "./Current/en-GB.json";
import es from "./Current/es-ES.json";
import pl from "./Current/pl-PL.json";

import SEO from "../../../components/SEO";

i18next.addResourceBundle("pt", "namespace1", pt);
i18next.addResourceBundle("en", "namespace1", en);
i18next.addResourceBundle("es", "namespace1", es);
i18next.addResourceBundle("pl", "namespace1", pl);

const lang = i18n.language;

const { Column, HeaderCell, Cell } = Table;

function Cookie(props) {
	return (
		<Translation>
			{(t) => (
				<div>
					<SEO pageTitle="Cookies | URL Builder Online" />
					<HeaderPanel header="all_pages.footer.column_1.text_7" />
					<Panel className="legal-panel">
						<h4 className="terms">
							<br />
							{t("cookie_policy.intro.text1", { ns: "namespace1" })}
							<br />
							<br />
							<Panel className="cookie-consent-container" shaded>
								<h3> {t("cookie_policy.intro.link", { ns: "namespace1" })}</h3>
								<a href="#" onClick={() => window.ppms.cm.api("openConsentForm", function (e) {})}>
									<Button className="button-primary green">
										{t("all_pages.footer.column_1.text_8")}
									</Button>
								</a>
							</Panel>
							<br />
							<br />
							<br />
							<strong>{t("cookie_policy.intro.title", { ns: "namespace1" })}</strong>
							<br />
						</h4>
						<h4 style={{ display: "flex", alignItems: "center" }}>
							{t("cookie_policy.intro.text2", { ns: "namespace1" })}{" "}
							<img src="./images/email_dpo.png" alt="dpo email address" />
							<br />
						</h4>
						<br />
						<Divider />
						<h3>
							<strong>{t("cookie_policy.1.title.1", { ns: "namespace1" })}</strong>
						</h3>
						<h4>
							<br />
							{t("cookie_policy.1.paragraph.1", { ns: "namespace1" })}
							<ul>
								<li>{t("cookie_policy.1.bullet.1", { ns: "namespace1" })}</li>
								<li>{t("cookie_policy.1.bullet.2", { ns: "namespace1" })}</li>
							</ul>
							<br />
							{t("cookie_policy.1.paragraph.2", { ns: "namespace1" })}
							<br />
							<br />
						</h4>
						<Divider />
						<h3>
							<strong>{t("cookie_policy.2.title.2", { ns: "namespace1" })}</strong>
						</h3>
						<h4>
							<br />
							{t("cookie_policy.2.paragraph.2", { ns: "namespace1" })}
							<br /> <br />
							<strong>{t("cookie_policy.2.title.21", { ns: "namespace1" })}</strong>
							<br />
							<br />
							{t("cookie_policy.2.paragraph.21", { ns: "namespace1" })}
							<br /> <br />
							<strong> {t("cookie_policy.2.title.21a", { ns: "namespace1" })} </strong>
							<br />
							<br />
							{t("cookie_policy.2.paragraph.21a", { ns: "namespace1" })}
							<br /> <br />
						</h4>
						{(i18n.language === "pt" && (
							<Table
								bordered
								cellBordered
								autoHeight
								wordWrap={true}
								headerHeight={80}
								className="cookie-table"
								data={[
									{
										host: ".app.urlbuilder.online",
										cookies: "__stripe_mid",
										type: "Origem",
										objective:
											"Este cookie é necessário para fazer transações com cartão de crédito no site. O serviço é fornecido pela Stripe.com, que permite transações on-line sem armazenar nenhuma informação de cartão de crédito.",
										length: "365 dias",
									},
									{
										host: ".app.urlbuilder.online",
										cookies: "__stripe_sid",
										type: "Origem",
										objective:
											"Este cookie é necessário para fazer transações com cartão de crédito no site. O serviço é fornecido pelo Stripe.com, que permite transações on-line sem armazenar nenhuma informação de cartão de crédito.",
										length: "Sessão",
									},
									{
										host: "www.urlbuilder.online",
										cookies: "ppms_privacy_<appID>",
										type: "Origem",
										objective:
											"Este cookie armazena o consentimento do visitante para a recolha e utilização dos dados.",
										length: "365 dias",
									},
									{
										host: ".app.urlbuilder.online",
										cookies: "ppms_privacy_<appID>",
										type: "Origem",
										objective:
											"Este cookie armazena o consentimento do visitante para a recolha e utilização dos dados.",
										length: "365 dias",
									},
									{
										host: "m.stripe.com",
										cookies: "m",
										type: "Terceiros",
										objective:
											"Este cookie ajuda a Stripe a avaliar o risco associado a uma tentativa de transação no seu site. Para detecção de fraude.",
										length: "400 dias",
									},
									{
										host: ".calendly.com",
										cookies: "__cf_bm",
										type: "Terceiros",
										objective:
											"Este cookie é utilizado para distinguir entre humanos e robots. Isto é benéfico para o sítio web, a fim de fazer relatórios válidos sobre a utilização do seu sítio web.",
										length: "Sessão",
									},
									{
										host: "sendinblue.com",
										cookies: "__cf_bm",
										type: "Terceiros",
										objective:
											"Este cookie é utilizado para distinguir entre humanos e robots. Isto é benéfico para o sítio web, a fim de fazer relatórios válidos sobre a utilização do seu sítio web.",
										length: "Sessão",
									},
								]}
							>
								<Column width={160}>
									<HeaderCell>Nome</HeaderCell>
									<Cell dataKey="cookies" />
								</Column>
								<Column width={200}>
									<HeaderCell>Anfitrião</HeaderCell>
									<Cell dataKey="host" />
								</Column>
								<Column width={150}>
									<HeaderCell>Tipo</HeaderCell>
									<Cell dataKey="type" />
								</Column>
								<Column width={590}>
									<HeaderCell>Finalidade</HeaderCell>
									<Cell dataKey="objective" />
								</Column>
								<Column width={160}>
									<HeaderCell>Duração</HeaderCell>
									<Cell dataKey="length" />
								</Column>
							</Table>
						)) ||
							(i18n.language === "en" && (
								<Table
									bordered
									cellBordered
									autoHeight
									wordWrap={true}
									headerHeight={80}
									className="cookie-table"
									data={[
										{
											host: ".app.urlbuilder.online",
											cookies: "__stripe_mid",
											type: "1st Party",
											objective:
												"This cookie is necessary for making credit card transactions on the website. The service is provided by Strip e.com which allows online transactions without storing any credit card information.",
											length: "365 days",
										},
										{
											host: ".app.urlbuilder.online",
											cookies: "__stripe_sid",
											type: "1st Party",
											objective:
												"This cookie is necessary for making credit card transactions on the website. The service is provided by Stripe.com which allows online transactions without storing any credit card information.",
											length: "Session",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "ppms_privacy_<appID>",
											type: "1st Party",
											objective:
												"This cookie stores the visitor’s consent to data collection and usage.",
											length: "365 Days",
										},
										{
											host: "app.urlbuilder.online",
											cookies: "ppms_privacy_<appID>",
											type: "1st Party",
											objective:
												"This cookie stores the visitor’s consent to data collection and usage.",
											length: "365 Days",
										},
										{
											host: "m.stripe.com",
											cookies: "m",
											type: "3rd Party",
											objective:
												"This cookie helps Stripe assess the risk associated with an attempted transaction on the your website. For fraud detection.",
											length: "400 days",
										},
										{
											host: ".calendly.com",
											cookies: "__cf_bm",
											type: "3rd Party",
											objective:
												"This cookie is used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website.",
											length: "Session",
										},
										{
											host: "sendinblue.com",
											cookies: "__cf_bm",
											type: "3rd Party",
											objective:
												"This cookie is used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website.",
											length: "Session",
										},
									]}
								>
									<Column width={160}>
										<HeaderCell>Name</HeaderCell>
										<Cell dataKey="cookies" />
									</Column>
									<Column width={200}>
										<HeaderCell>Host</HeaderCell>
										<Cell dataKey="host" />
									</Column>
									<Column width={150}>
										<HeaderCell>Type</HeaderCell>
										<Cell dataKey="type" />
									</Column>
									<Column width={590}>
										<HeaderCell>Purpose</HeaderCell>
										<Cell dataKey="objective" />
									</Column>
									<Column width={160}>
										<HeaderCell>Expiration</HeaderCell>
										<Cell dataKey="length" />
									</Column>
								</Table>
							)) ||
							(i18n.language === "es" && (
								<Table
									bordered
									cellBordered
									autoHeight
									wordWrap={true}
									headerHeight={80}
									className="cookie-table"
									data={[
										{
											host: ".app.urlbuilder.online",
											cookies: "__stripe_mid",
											type: "Origen",
											objective:
												"Esta cookie es necesaria para realizar transacciones con tarjeta de crédito en el sitio web. El servicio es proporcionado por Stripe.com, que permite realizar transacciones en línea sin almacenar ningún dato de la tarjeta de crédito.",
											length: "365 días",
										},
										{
											host: ".app.urlbuilder.online",
											cookies: "__stripe_sid",
											type: "Origen",
											objective:
												"Esta cookie es necesaria para realizar transacciones con tarjeta de crédito en el sitio web. El servicio es proporcionado por Stripe.com, que permite realizar transacciones en línea sin almacenar información de tarjetas de crédito.",
											length: "Sesión",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "ppms_privacy_<appID>",
											type: "Origen",
											objective:
												"Esta cookie almacena el consentimiento del visitante para la recopilación y uso de datos.",
											length: "365 días",
										},
										{
											host: ".app.urlbuilder.online",
											cookies: "ppms_privacy_<appID>",
											type: "Origen",
											objective:
												"Esta cookie almacena el consentimiento del visitante para la recopilación y uso de datos.",
											length: "365 días",
										},
										{
											host: "m.stripe.com",
											cookies: "m",
											type: "Terceros",
											objective:
												"Esta cookie ayuda a Stripe a evaluar el riesgo asociado a un intento de transacción en el sitio web. Para la detección de fraudes.",
											length: "400 días",
										},
										{
											host: ".calendly.com",
											cookies: "__cf_bm",
											type: "Terceros",
											objective:
												"Esta cookie se utiliza para distinguir entre humanos y robots. Esto es beneficioso para el sitio web, con el fin de realizar informes válidos sobre el uso de su sitio web.",
											length: "Sesión",
										},
										{
											host: ".calendly.com",
											cookies: "__cf_bm",
											type: "Terceros",
											objective:
												"Esta cookie se utiliza para distinguir entre humanos y robots. Esto es beneficioso para el sitio web, con el fin de realizar informes válidos sobre el uso de su sitio web.",
											length: "Sesión",
										},
									]}
								>
									<Column width={160}>
										<HeaderCell>Nombre</HeaderCell>
										<Cell dataKey="cookies" />
									</Column>
									<Column width={200}>
										<HeaderCell>Anfitrión</HeaderCell>
										<Cell dataKey="host" />
									</Column>
									<Column width={150}>
										<HeaderCell>Tipo</HeaderCell>
										<Cell dataKey="type" />
									</Column>
									<Column width={590}>
										<HeaderCell>Finalidad</HeaderCell>
										<Cell dataKey="objective" />
									</Column>
									<Column width={160}>
										<HeaderCell>Caducidad</HeaderCell>
										<Cell dataKey="length" />
									</Column>
								</Table>
							)) ||
							(i18n.language === "pl" && (
								<Table
									bordered
									cellBordered
									autoHeight
									wordWrap={true}
									headerHeight={80}
									className="cookie-table"
									data={[
										{
											host: ".app.urlbuilder.online",
											cookies: "__stripe_mid",
											type: "1st Party",
											objective:
												"Ten plik cookie jest niezbędny do dokonywania transakcji kartą kredytową. Usługa jest świadczona przez Stripe.com, która umożliwia transakcje online bez",
											length: "365 dni",
										},
										{
											host: ".app.urlbuilder.online",
											cookies: "__stripe_sid",
											type: "1st Party",
											objective:
												"Ten plik cookie jest niezbędny do dokonywania transakcji kartami kredytowymi na stronie internetowej. Usługa świadczona przez Stripe.com, która umożliwia dokonywanie transakcji online bez przechowywania jakichkolwiek informacji o karcie kredytowej.",
											length: "Sesja",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "ppms_privacy_<appID>",
											type: "1st Party",
											objective:
												"Ten plik cookie przechowuje zgodę osoby odwiedzającej stronę na gromadzenie i wykorzystywanie danych.",
											length: "365 dni",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "ppms_privacy_<appID>",
											type: "1st Party",
											objective:
												"Ten plik cookie przechowuje zgodę osoby odwiedzającej stronę na gromadzenie i wykorzystywanie danych.",
											length: "365 dni",
										},
										{
											host: "m.stripe.com",
											cookies: "m",
											type: "3rd Party",
											objective:
												"W celu wykrycia oszustwa, plik ten pomaga Stripe ocenić ryzyko związane z próbą dokonania transakcji na stronie internetowej.",
											length: "400 dni",
										},
										{
											host: ".calendly.com",
											cookies: "__cf_bm",
											type: "3rd Party",
											objective:
												"Ten plik cookie służy do rozróżniania ludzi i botów. Jest to korzystne dla strony internetowej, aby sporządzać prawidłowe raporty dotyczące korzystania z ich strony.",
											length: "Sesja",
										},
										{
											host: "sendinblue.com",
											cookies: "__cf_bm",
											type: "3rd Party",
											objective:
												"Ten plik cookie służy do rozróżniania ludzi i botów. Jest to korzystne dla strony internetowej, aby sporządzać prawidłowe raporty dotyczące korzystania z ich strony.",
											length: "Sesja",
										},
									]}
								>
									<Column width={160}>
										<HeaderCell>Nazwa</HeaderCell>
										<Cell dataKey="cookies" />
									</Column>
									<Column width={200}>
										<HeaderCell>Host</HeaderCell>
										<Cell dataKey="host" />
									</Column>
									<Column width={150}>
										<HeaderCell>Rodzaj</HeaderCell>
										<Cell dataKey="type" />
									</Column>
									<Column width={590}>
										<HeaderCell>Cel</HeaderCell>
										<Cell dataKey="objective" />
									</Column>
									<Column width={160}>
										<HeaderCell>Czas trwania</HeaderCell>
										<Cell dataKey="length" />
									</Column>
								</Table>
							))}

						<h4>
							<br />
						</h4>
						<Divider />
						<h4>
							<strong> {t("cookie_policy.2.title.21b", { ns: "namespace1" })} </strong>
							<br />
							<br />
							{t("cookie_policy.2.paragraph.21b", { ns: "namespace1" })}
							<br />
							<br />
						</h4>

						{(i18n.language === "pt" && (
							<Table
								bordered
								cellBordered
								autoHeight
								wordWrap={true}
								headerHeight={80}
								className="cookie-table"
								data={[
									{
										host: ".urlbuilder.online",
										cookies: "_clck",
										type: "Origem",
										service: "Stripe",
										objective:
											"Esse cookie persiste o ID e as preferências do usuário do Clarity, exclusivas desse site, no navegador. Isso garante que o comportamento nas visitas subsequentes ao mesmo site seja atribuído ao mesmo ID do usuário. É usado para compilar relatórios estatísticos e mapas de calor para o proprietário do site.",
										length: "365 dias",
									},
									{
										host: ".urlbuilder.online",
										cookies: "_clsk",
										type: "Origem",
										service: "Stripe",
										objective:
											"Este cookie conecta várias visualizações de página por um usuário em uma única gravação de sessão do Clarity. É usado para análise interna pelo site operador.",
										length: "1 dia",
									},
									{
										host: ".urlbuilder.online",
										cookies: "_hjAbsoluteSessionInProgress",
										type: "Origem",
										service: "Stripe",
										objective:
											"Este cookie é usado para detectar a primeira sessão de PageView de um usuário.",
										length: "Sessão",
									},
									{
										host: ".urlbuilder.online",
										cookies: "_hjFirstSeen",
										type: "Origem",
										service: "Stripe",
										objective:
											"Este cookie é usado para determinar se o visitante já visitou o site antes ou se é um novo visitante no site.",
										length: "Sessão",
									},
									{
										host: "www.urlbuilder.online",
										cookies: "_hjIncludedInPageviewSample",
										type: "Origem",
										service: "Firebase Auth",
										objective:
											"Este cookie está definido para determinar se um usuário está incluído na amostragem de dados definida pelo limite de PageView do seu site.",
										length: "Sessão",
									},
									{
										host: "www.urlbuilder.online",
										cookies: "_hjIncludedInSessionSample",
										type: "Origem",
										service: "Firebase Auth",
										objective:
											"Este cookie está definido para determinar se um usuário está incluído na amostragem de dados definida pelo limite de sessão diária do seu site.",
										length: "Sessão",
									},
									{
										host: ".urlbuilder.online",
										cookies: "_hjRecordingLastActivity",
										type: "Origem",
										service: "Firebase Auth",
										objective:
											"Este cookie define um ID exclusivo para a sessão. Isso permite que o site obtenha dados sobre o comportamento do visitante para fins estatísticos.",
										length: "Sessão",
									},
									{
										host: ".urlbuilder.online",
										cookies: "_hjSession_<site_id>",
										type: "Origem",
										service: "Firebase Auth",
										objective:
											"Esses cookies contêm dados de sessão atuais. Garante que as solicitações subsequentes na janela da sessão sejam atribuídas à mesma sessão.",
										length: "Sessão",
									},
									{
										host: ".urlbuilder.online",
										cookies: "_hjSessionUser_<site_id>",
										type: "Origem",
										service: "Firebase Auth",
										objective:
											"Este cookie está definido quando um usuário pousa pela primeira vez em uma página. Persiste no ID de usuário do hotjar, exclusivo desse site. Garante que os dados das visitas subsequentes ao mesmo site sejam atribuídos ao mesmo ID do usuário.",
										length: "365 dias",
									},
									{
										host: ".urlbuilder.online",
										cookies: "_hjTLDTest",
										type: "Origem",
										service: "Firebase Auth",
										objective:
											"Este cookies registra dados estatísticos sobre o comportamento dos usuários no site. Usado para análise interna pelo operador do site.",
										length: "Sessão",
									},
									{
										host: ".urlbuilder.online",
										cookies: "hjViewportId",
										type: "Origem",
										service: "Firebase Auth",
										objective:
											"Este cookies define um ID exclusivo para a sessão. Isso permite que o site obtenha dados sobre o comportamento do visitante para fins estatísticos.",
										length: "Sessão",
									},
									{
										host: ".urlbuilder.online",
										cookies: "_hjRecordingEnabled",
										type: "Origem",
										service: "Firebase Auth",
										objective:
											"Este cookie é utilizado para identificar o visitante e optimizar a relevância de anúncios, recolhendo dados de visitantes de múltiplos sítios web - esta troca de dados de visitantes é normalmente fornecida por um centro de dados ou ad-exchange.",
										length: "Sessão",
									},
									{
										host: "www.urlbuilder.online",
										cookies: "_pk_id.<appID>.<domainHash>",
										type: "Origem",
										service: "Firebase Auth",
										objective:
											"Este cookie é usado para reconhecer os visitantes e manter suas várias propriedades.",
										length: "365 dias",
									},
									{
										host: "www.urlbuilder.online",
										cookies: "_pk_ses.<appID>.<domainHash>",
										type: "Origem",
										service: "Firebase Auth",
										objective:
											"Este cookie é usado para rastrear solicitações de página do visitante durante a sessão.",
										length: "Sessão",
									},
									{
										host: "help.urlbuilder.online",
										cookies: "stg_last_interaction",
										type: "Origem",
										service: "Firebase Auth",
										objective:
											"Este cookie determina se a sessão do último visitante ainda está em andamento ou uma nova sessão foi iniciada.",
										length: "365 dias",
									},
									{
										host: "help.urlbuilder.online",
										cookies: "stg_returning_visitor",
										type: "Origem",
										service: "Firebase Auth",
										objective:
											"Este cookie determina se o visitante já esteve no seu site - eles estão retornando visitantes.",
										length: "365 dias",
									},
									{
										host: "www.urlbuilder.online",
										cookies: "stg_traffic_source_priority",
										type: "Origem",
										service: "Firebase Auth",
										objective:
											"Este cookie armazena o tipo de fonte de tráfego que explica como o visitante chegou ao seu site.",
										length: "Sessão",
									},
									{
										host: ".c.bing.com",
										cookies: "SRM_B",
										type: "Terceiros",
										service: "Firebase Auth",
										objective:
											"Este cookie identifica navegadores da Web exclusivos que visitam sites da Microsoft.",
										length: "390 dias",
									},
									{
										host: ".urlbuilder.online",
										cookies: "_cltk",
										type: "Origem",
										service: "Firebase Auth",
										objective:
											"Este cookie registra dados estatísticos sobre o comportamento dos usuários no site. Usado para análise interna pelo operador do site.",
										length: "Sessão",
									},
									{
										host: ".c.clarity.ms",
										cookies: "ANONCHK",
										type: "Terceiros",
										service: "Firebase Auth",
										objective:
											"Este cookie registra dados sobre visitantes de várias visitas e em vários sites. Essas informações são usadas para medir a eficiência do anúncio em sites. Indica se o MUID é transferido para o ANID, um cookie usado para publicidade. A clareza não usa ANID e, portanto, isso é sempre definido como 0.",
										length: "Sessão",
									},
									{
										host: "www.clarity.ms",
										cookies: "CLID",
										type: "Terceiros",
										service: "Firebase Auth",
										objective:
											"Este cookie coleta dados sobre a navegação e o comportamento do usuário no site. Isso é usado para compilar relatórios estatísticos e mapas de calor para o proprietário do site.",
										length: "365 dias",
									},
									{
										host: ".c.clarity.ms",
										cookies: "MR",
										type: "Terceiros",
										service: "Firebase Auth",
										objective:
											"Este cookie indica se deve atualizar o MUID. Usado para medir o uso do site para análises internas.",
										length: "7 dias",
									},
									{
										host: ".c.bing.com",
										cookies: "MR",
										type: "Terceiros",
										service: "Firebase Auth",
										objective:
											"Este cookie indica se deve atualizar o MUID. Usado para medir o uso do site para análises internas.",
										length: "7 dias",
									},
									{
										host: ".clarity.ms",
										cookies: "MUID",
										type: "Terceiros",
										service: "Firebase Auth",
										objective:
											"Este cookie é amplamente utilizado pela Microsoft como um ID de usuário exclusivo. O cookie permite o rastreamento do usuário sincronizando o ID em muitos domínios da Microsoft.",
										length: "390 dias",
									},
									{
										host: ".bing.com",
										cookies: "MUID",
										type: "Terceiros",
										service: "Firebase Auth",
										objective:
											"Este cookie é amplamente utilizado pela Microsoft como um ID de usuário exclusivo. O cookie permite o rastreamento do usuário sincronizando o ID em muitos domínios da Microsoft.",
										length: "390 dias",
									},
									{
										host: ".c.clarity.ms",
										cookies: "SM",
										type: "Terceiros",
										service: "Firebase Auth",
										objective:
											"Este cookie é usado para sincronizar o MUID nos domínios da Microsoft. Usado para medir o uso do site para análises internas.",
										length: "Sessão",
									},
								]}
							>
								<Column width={160}>
									<HeaderCell>Nome</HeaderCell>
									<Cell dataKey="cookies" />
								</Column>
								<Column width={200}>
									<HeaderCell>Anfitrião</HeaderCell>
									<Cell dataKey="host" />
								</Column>
								<Column width={150}>
									<HeaderCell>Tipo</HeaderCell>
									<Cell dataKey="type" />
								</Column>
								<Column width={590}>
									<HeaderCell>Finalidade</HeaderCell>
									<Cell dataKey="objective" />
								</Column>
								<Column width={160}>
									<HeaderCell>Duração</HeaderCell>
									<Cell dataKey="length" />
								</Column>
							</Table>
						)) ||
							(i18n.language === "en" && (
								<Table
									bordered
									cellBordered
									autoHeight
									wordWrap={true}
									headerHeight={80}
									className="cookie-table"
									data={[
										{
											host: ".urlbuilder.online",
											cookies: "_clck",
											type: "1st Party",
											objective:
												"This cookie persists the Clarity User ID and preferences, unique to that site, on the browser. This ensures that behavior in subsequent visits to the same site will be attributed to the same user ID. It is used to compile statistical reports and heatmaps for the website owner.",
											length: "365 days",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_clsk",
											type: "1st Party",
											objective:
												"This cookie connects multiple page views by a user into a single Clarity session recording. It is used for internal analytics by the website operator.",
											length: "1 day",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjAbsoluteSessionInProgress",
											type: "1st Party",
											objective:
												"This cookie is used to detect the first pageview session of a user.",
											length: "Session",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjFirstSeen",
											type: "1st Party",
											objective:
												"This cookie is used to determine if the visitor has visited the website before, or if it is a new visitor on the website.",
											length: "Session",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "_hjIncludedInPageviewSample",
											type: "1st Party",
											objective:
												"This cookie is set to determine if a user is included in the data sampling defined by your site's pageview limit.",
											length: "Session",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "_hjIncludedInSessionSample",
											type: "1st Party",
											objective:
												"This cookie is set to determine if a user is included in the data sampling defined by your site's daily session limit.",
											length: "Session",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjRecordingLastActivity",
											type: "1st Party",
											objective:
												"This cookie sets a unique ID for the session. This allows the website to obtain data on visitor behaviour for statistical purposes.",
											length: "Session",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjSession_<site_id>",
											type: "1st Party",
											objective:
												"This cookies holds current session data. Ensures subsequent requests in the session window are attributed to the same session.",
											length: "Session",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjSessionUser_<site_id>",
											type: "1st Party",
											objective:
												"This cookie is set when a user first lands on a page. Persists the Hotjar User ID which is unique to that site. Ensures data from subsequent visits to the same site are attributed to the same user ID.",
											length: "365 days",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjTLDTest",
											type: "1st Party",
											objective:
												"This cookies registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator.",
											length: "Session",
										},
										{
											host: ".urlbuilder.online",
											cookies: "hjViewportId",
											type: "1st Party",
											objective:
												"This cookies sets a unique ID for the session. This allows the website to obtain data on visitor behaviour for statistical purposes.",
											length: "Session",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjRecordingEnabled",
											type: "1st Party",
											objective:
												"This cookie is used to identify the visitor and optimize ad-relevance by collecting visitor data from multiple websites – this exchange of visitor data is normally provided by a third-party data-center or ad-exchange.",
											length: "Session",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "_pk_id.<appID>.<domainHash>",
											type: "1st Party",
											objective:
												"This cookie is used to recognize visitors and hold their various properties.",
											length: "365 days",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "_pk_ses.<appID>.<domainHash>",
											type: "1st Party",
											objective:
												"This cookie is used to track page requests from the visitor during the session.",
											length: "Session",
										},
										{
											host: "help.urlbuilder.online",
											cookies: "stg_last_interaction",
											type: "1st Party",
											objective:
												"This cookie determines whether the last visitor’s session is still in progress or a new session has started.",
											length: "365 days",
										},
										{
											host: "help.urlbuilder.online",
											cookies: "stg_returning_visitor",
											type: "1st Party",
											objective:
												"This cookie determines if the visitor has already been to your website — they are returning visitors.",
											length: "365 days",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "stg_traffic_source_priority",
											type: "1st Party",
											objective:
												"This cookie stores the type of traffic source that explains how the visitor reached your website.",
											length: "Session",
										},
										{
											host: ".c.bing.com",
											cookies: "SRM_B",
											type: "3rd Party",
											objective:
												"This cookie Identifies unique web browsers visiting Microsoft sites.",
											length: "390 days",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_cltk",
											type: "1st Party",
											objective:
												"This cookie registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator.",
											length: "Session",
										},
										{
											host: ".c.clarity.ms",
											cookies: "ANONCHK",
											type: "3rd Party",
											objective:
												"This cookie registers data on visitors from multiple visits and on multiple websites. This information is used to measure the efficiency of advertisement on websites. Indicates whether MUID is transferred to ANID, a cookie used for advertising. Clarity doesn't use ANID and so this is always set to 0.",
											length: "Session",
										},
										{
											host: "www.clarity.ms",
											cookies: "CLID",
											type: "3rd Party",
											objective:
												"This cookie collects data on the user’s navigation and behavior on the website. This is used to compile statistical reports and heatmaps for the website owner.",
											length: "365 days",
										},
										{
											host: ".c.clarity.ms",
											cookies: "MR",
											type: "3rd Party",
											objective:
												"This cookie indicates whether to refresh MUID. Used to measure the use of the website for internal analytics.",
											length: "7 days",
										},
										{
											host: ".c.bing.com",
											cookies: "MR",
											type: "3rd Party",
											objective:
												"This cookie indicates whether to refresh MUID. Used to measure the use of the website for internal analytics.",
											length: "7 days",
										},
										{
											host: ".clarity.ms",
											cookies: "MUID",
											type: "3rd Party",
											objective:
												"This cookie is used widely by Microsoft as a unique user ID. The cookie enables user tracking by synchronising the ID across many Microsoft domains.",
											length: "390 days",
										},
										{
											host: ".c.bing.com",
											cookies: "MUID",
											type: "3rd Party",
											objective:
												"This cookie is used widely by Microsoft as a unique user ID. The cookie enables user tracking by synchronising the ID across many Microsoft domains.",
											length: "390 days",
										},
										{
											host: ".c.clarity.ms",
											cookies: "SM",
											type: "3rd Party",
											objective:
												"This cookie is used in synchronizing the MUID across Microsoft domains. Used to measure the use of the website for internal analytics.",
											length: "Session",
										},
									]}
								>
									<Column width={160}>
										<HeaderCell>Name</HeaderCell>
										<Cell dataKey="cookies" />
									</Column>
									<Column width={200}>
										<HeaderCell>Host</HeaderCell>
										<Cell dataKey="host" />
									</Column>
									<Column width={150}>
										<HeaderCell>Type</HeaderCell>
										<Cell dataKey="type" />
									</Column>
									<Column width={590}>
										<HeaderCell>Purpose</HeaderCell>
										<Cell dataKey="objective" />
									</Column>
									<Column width={160}>
										<HeaderCell>Expiration</HeaderCell>
										<Cell dataKey="length" />
									</Column>
								</Table>
							)) ||
							(i18n.language === "es" && (
								<Table
									bordered
									cellBordered
									autoHeight
									wordWrap={true}
									headerHeight={80}
									className="cookie-table"
									data={[
										{
											host: ".urlbuilder.online",
											cookies: "_clck",
											type: "Origen",
											objective:
												"Esta cookie persiste en el navegador el ID de usuario de Clarity y las preferencias, únicas para ese sitio. Esto garantiza que el comportamiento en visitas posteriores al mismo sitio se atribuirá al mismo ID de usuario. Se utiliza para elaborar informes estadísticos y mapas de calor para el propietario del sitio web.",
											length: "365 días",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_clsk",
											type: "Origen",
											objective:
												"Esta cookie conecta varias páginas vistas por un usuario en una única grabación de sesión de Clarity. Se utiliza para la analítica interna del analista del sitio web.",
											length: "1 día",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjAbsoluteSessionInProgress",
											type: "Origen",
											objective:
												"Esta cookie se utiliza para detectar la primera sesión de visualización de página de un usuario.",
											length: "Sesión",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjFirstSeen",
											type: "Origen",
											objective:
												"Esta cookie se utiliza para determinar si el visitante ha visitado el sitio web anteriormente, o si se trata de un nuevo visitante en el sitio web.",
											length: "Sesión",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "_hjIncludedInPageviewSample",
											type: "Origen",
											objective:
												"Esta cookie se utiliza para determinar si un usuario está incluido en el muestreo de datos definido por el límite de páginas vistas de su sitio web.",
											length: "Sesión",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "_hjIncludedInSessionSample",
											type: "Origen",
											objective:
												"Esta cookie se establece para determinar si un usuario está incluido en el muestreo de datos definido por el límite de sesiones diarias de su sitio.",
											length: "Sesión",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjRecordingLastActivity",
											type: "Origen",
											objective:
												"Esta cookie establece un ID único para la sesión. Esto permite al sitio web obtener datos sobre el comportamiento de los visitantes con fines estadísticos.",
											length: "Sesión",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjSession_<site_id>",
											type: "Origen",
											objective:
												"Esta cookie conserva los datos de la sesión actual. Garantiza que las solicitudes posteriores en la ventana de sesión se atribuyan a la misma sesión.",
											length: "Sesión",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjSessionUser_<site_id>",
											type: "Origen",
											objective:
												"Esta cookie se establece cuando un usuario entra por primera vez en una página. Persiste el ID de usuario de Hotjar, que es único para ese sitio. Garantiza que los datos de visitas posteriores al mismo sitio se atribuyan al mismo ID de usuario.",
											length: "365 días",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjTLDTest",
											type: "Origen",
											objective:
												"Esta cookie registra datos estadísticos sobre el comportamiento de los usuarios en el sitio web. Utilizada para análisis internos por el analista del sitio web.",
											length: "Sesión",
										},
										{
											host: ".urlbuilder.online",
											cookies: "hjViewportId",
											type: "Origen",
											objective:
												"Esto permite al sitio web obtener datos sobre el comportamiento de los visitantes con fines estadísticos.",
											length: "Sesión",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjRecordingEnabled",
											type: "Origen",
											objective:
												"Esta cookie se utiliza para identificar al visitante y optimizar la relevancia de los anuncios mediante la recopilación de datos de visitantes de varios sitios web; este intercambio de datos de visitantes lo proporciona normalmente un centro de datos ou ad-exchange.",
											length: "Sesión",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "_pk_id.<appID>.<domainHash>",
											type: "Origen",
											objective:
												"Esta cookie se utiliza para reconocer a los visitantes y mantener sus distintas propiedades.",
											length: "365 días",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "_pk_ses.<appID>.<domainHash>",
											type: "Origen",
											objective:
												"Esta cookie se utiliza para realizar un seguimiento de las solicitudes de páginas del visitante durante la sesión.",
											length: "Sesión",
										},
										{
											host: "help.urlbuilder.online",
											cookies: "stg_last_interaction",
											type: "Origen",
											objective:
												"Esta cookie determina si la última sesión del visitante sigue en curso o se ha iniciado una nueva sesión.",
											length: "365 días",
										},
										{
											host: "help.urlbuilder.online",
											cookies: "stg_returning_visitor",
											type: "Origen",
											objective:
												"Esta cookie determina si el visitante ya ha estado en su sitio web - son visitantes que regresan.",
											length: "365 días",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "stg_traffic_source_priority",
											type: "Origen",
											objective:
												"Esta cookie almacena el tipo de fuente de tráfico que explica cómo ha llegado el visitante a su sitio web.",
											length: "Sesión",
										},
										{
											host: ".c.bing.com",
											cookies: "SRM_B",
											type: "Terceros",
											objective:
												"Esta cookie identifica navegadores web únicos que visitan sitios de Microsoft.",
											length: "390 días",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_cltk",
											type: "Origen",
											objective:
												"Esta cookie registra datos estadísticos sobre el comportamiento de los usuarios en el sitio web. Se utiliza para análisis internos del analista del sítio web.",
											length: "Sesión",
										},
										{
											host: ".c.clarity.ms",
											cookies: "ANONCHK",
											type: "Terceros",
											objective:
												"Esta cookie registra datos sobre visitantes de múltiples visitas y en múltiples sitios web. Esta información se utiliza para medir la eficacia de la publicidad en los sitios web. Indica si el MUID se transfiere al ANID, una cookie utilizada para la publicidad. Clarity no utiliza ANID, por lo que siempre se establece en 0.",
											length: "Sesión",
										},
										{
											host: "www.clarity.ms",
											cookies: "CLID",
											type: "Terceros",
											objective:
												"Esta cookie recoge datos sobre la navegación y el comportamiento del usuario en el sitio web. Se utiliza para elaborar informes estadísticos y mapas de calor para el propietario del sitio web.",
											length: "365 días",
										},
										{
											host: ".c.clarity.ms",
											cookies: "MR",
											type: "Terceros",
											objective:
												"Esta cookie indica si se debe actualizar MUID. Se utiliza para medir el uso del sitio web para la analítica interna.",
											length: "7 días",
										},
										{
											host: ".c.bing.com",
											cookies: "MR",
											type: "Terceros",
											objective:
												"Esta cookie indica si se debe actualizar MUID. Se utiliza para medir el uso del sitio web para la analítica interna.",
											length: "7 días",
										},
										{
											host: ".clarity.ms",
											cookies: "MUID",
											type: "Terceros",
											objective:
												"Esta cookie es utilizada ampliamente por Microsoft como ID de usuario único. La cookie permite el seguimiento del usuario sincronizando el ID a través de muchos dominios de Microsoft.",
											length: "390 días",
										},
										{
											host: ".bing.com",
											cookies: "MUID",
											type: "Terceros",
											objective:
												"Esta cookie es utilizada ampliamente por Microsoft como ID de usuario único. La cookie permite el seguimiento del usuario sincronizando el ID a través de muchos dominios de Microsoft.",
											length: "390 días",
										},
										{
											host: ".c.clarity.ms",
											cookies: "SM",
											type: "Terceros",
											objective:
												"Esta cookie se utiliza para sincronizar el MUID en todos los dominios de Microsoft. Se utiliza para medir el uso del sitio web para análisis internos.",
											length: "Sesión",
										},
									]}
								>
									<Column width={160}>
										<HeaderCell>Nombre</HeaderCell>
										<Cell dataKey="cookies" />
									</Column>
									<Column width={200}>
										<HeaderCell>Anfitrión</HeaderCell>
										<Cell dataKey="host" />
									</Column>
									<Column width={150}>
										<HeaderCell>Tipo</HeaderCell>
										<Cell dataKey="type" />
									</Column>
									<Column width={590}>
										<HeaderCell>Finalidad</HeaderCell>
										<Cell dataKey="objective" />
									</Column>
									<Column width={160}>
										<HeaderCell>Caducidad</HeaderCell>
										<Cell dataKey="length" />
									</Column>
								</Table>
							)) ||
							(i18n.language === "pl" && (
								<Table
									bordered
									cellBordered
									autoHeight
									wordWrap={true}
									headerHeight={80}
									className="cookie-table"
									data={[
										{
											host: ".urlbuilder.online",
											cookies: "_clck",
											type: "1st Party",
											objective:
												"Ten plik cookie zachowuje w przeglądarce identyfikator użytkownika Clarity User ID i preferencje, unikalne dla tej strony. Dzięki temu zachowanie podczas kolejnych wizyt na tej samej stronie będzie przypisane do tego samego użytkownika. Jest on używany do tworzenia raportów statystycznych i map ciepła dla właściciela strony.",
											length: "365 dni",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_clsk",
											type: "1st Party",
											objective:
												"Ten plik cookie łączy wiele wyświetleń strony  danego użytkownika w jeden zapis sesji Clarity. Jest on wykorzystywany do analizy wewnętrznej przez analityka strony internetowej.",
											length: "1 dzień",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjAbsoluteSessionInProgress",
											type: "1st Party",
											objective:
												"Ten plik cookie służy do wykrywania pierwszej sesji przeglądania strony przez użytkownika.",
											length: "Sesja",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjFirstSeen",
											type: "1st Party",
											objective:
												"Ten plik cookie służy do określenia, czy użytkownik odwiedził już wcześniej strone , lub czy jest nowym gościem na stronie.",
											length: "Sesja",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "_hjIncludedInPageviewSample",
											type: "1st Party",
											objective:
												"Ten plik cookie jest ustawiany w celu określenia, czy użytkownik jest objęty próbą danych zdefiniowaną przez limit wyświetleń Twojej strony.",
											length: "Sesja",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "_hjIncludedInSessionSample",
											type: "1st Party",
											objective:
												"Ten plik cookie służy do określania, czy użytkownik jest uwzględniany w samplingu danych określonym przez dzienny limit sesji na stronie.",
											length: "Sesja",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjRecordingLastActivity",
											type: "1st Party",
											objective:
												"Ten plik cookie ustala unikalny identyfikator dla sesji. Umozliwia to uzyskanie danych dotyczących zachowania użytkownika do celów statystycznych.",
											length: "Sesja",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjSession_<site_id>",
											type: "1st Party",
											objective:
												"Ten plik cookie przechowuje dane bieżącej sesji. Zapewnia, że kolejne żądania w oknie sesji są przypisane do tej samej sesji.",
											length: "Sesja",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjSessionUser_<site_id>",
											type: "1st Party",
											objective:
												"Ten plik cookie jest ustawiany, gdy użytkownik po raz pierwszy trafia na stronę. Zachowuje identyfikator użytkownika Hotjar, który jest unikalny dla danej strony. Zapewnia, że dane z kolejnych wizyt na tej samej stronie są przypisane do tego samego identyfikatora użytkownika.",
											length: "365 dni",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjTLDTest",
											type: "1st Party",
											objective:
												"Ten plik cookie rejestruje dane statystyczne dotyczące zachowania użytkowników na stronie. Używany do wewnętrznej analizy przez analityka strony.",
											length: "Sesja",
										},
										{
											host: ".urlbuilder.online",
											cookies: "hjViewportId",
											type: "1st Party",
											objective:
												"Ten plik cookie ustala unikalny identyfikator dla sesji. Pozwala to uzyskać dane dotyczące zachowania odwiedzających w celach statystycznych.",
											length: "Sesja",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_hjRecordingEnabled",
											type: "1st Party",
											objective:
												"Ten plik cookie służy do identyfikacji odwiedzającego i optymalizacji dopasowania reklam poprzez gromadzenie danych o odwiedzających z wielu stron internetowych. Taka wymiana danych o użytkownikach jest  zwykle zapewniana przez zewnętrzne centrum danych lub ad-exchange.",
											length: "Sesja",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "_pk_id.<appID>.<domainHash>",
											type: "1st Party",
											objective:
												"Ten plik cookie służy do rozpoznawania odwiedzających i przechowywania ich różnych atrybutów.",
											length: "365 dni",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "_pk_ses.<appID>.<domainHash>",
											type: "1st Party",
											objective:
												"Ten plik cookie służy do śledzenia żądań stron od odwiedzającego podczas sesji.",
											length: "Sesja",
										},
										{
											host: "help.urlbuilder.online",
											cookies: "stg_last_interaction",
											type: "1st Party",
											objective:
												"Ten plik cookie określa, czy sesja odwiedzającego jest nadal w toku, czy też rozpoczęła się nowa sesja.",
											length: "365 dni",
										},
										{
											host: "help.urlbuilder.online",
											cookies: "stg_returning_visitor",
											type: "1st Party",
											objective:
												"Ten plik cookie określa, czy odwiedzający był już na Twojej stronie - są to powracający użytkownicy.",
											length: "365 dni",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "stg_traffic_source_priority",
											type: "1st Party",
											objective:
												"Ten plik cookie przechowuje rodzaj źródła ruchu, który wyjaśnia, w jaki sposób odwiedzający dotarł do strony.",
											length: "Sesja",
										},
										{
											host: ".c.bing.com",
											cookies: "SRM_B",
											type: "3rd Party",
											objective:
												"Ten plik cookie identyfikuje unikalne przeglądarki internetowe odwiedzające  strony Microsoftu.",
											length: "390 dni",
										},
										{
											host: ".urlbuilder.online",
											cookies: "_cltk",
											type: "1st Party",
											objective:
												"Ten plik cookie rejestruje dane statystyczne dotyczące zachowania użytkownikówna stronie. Używany do wewnętrznej analizy i przez analityka.",
											length: "Sesja",
										},
										{
											host: ".c.clarity.ms",
											cookies: "ANONCHK",
											type: "3rd Party",
											objective:
												"Ten plik cookie rejestruje dane o odwiedzających z wielu wizyt i na wielu stronach internetowych. Informacje te są wykorzystywane do pomiaru efektywności reklam na stronach internetowych. Wskazuje, czy MUID jest przekazywany do ANID, pliku cookie używanego do reklam. Clarity nie korzysta z ANID, dlatego jest to zawsze ustawione na 0.",
											length: "Sesja",
										},
										{
											host: "www.clarity.ms",
											cookies: "CLID",
											type: "3rd Party",
											objective:
												"Ten plik cookie gromadzi dane dotyczące nawigacji i zachowania użytkownika na stronie internetowej. Jest to wykorzystywane do sporządzania raportów statystycznych i map ciepła dla właściciela strony.",
											length: "365 dni",
										},
										{
											host: ".c.clarity.ms",
											cookies: "MR",
											type: "3rd Party",
											objective:
												"Ten plik cookie wskazuje, czy należy odświeżyć MUID. Używany do mierzenia stopnia korzystania ze strony internetowej na potrzeby wewnętrznej analizy.",
											length: "7 dni",
										},
										{
											host: ".c.bing.com",
											cookies: "MR",
											type: "3rd Party",
											objective:
												"Ten plik cookie wskazuje, czy należy odświeżyć MUID. Używany do mierzenia stopnia korzystania ze strony internetowej na potrzeby wewnętrznej analizy.",
											length: "7 dni",
										},
										{
											host: ".clarity.ms",
											cookies: "MUID",
											type: "3rd Party",
											objective:
												"Ten plik cookie jest szeroko stosowany przez Microsoft jako unikalny identyfikator użytkownika. Ten plik cookie umożliwia śledzenie użytkownika poprzez synchronizację identyfikatora w wielu domenach Microsoftu.",
											length: "390 dni",
										},
										{
											host: ".c.bing.com",
											cookies: "MUID",
											type: "3rd Party",
											objective:
												"Ten plik cookie jest szeroko stosowany przez Microsoft jako unikalny identyfikator użytkownika. Ten plik cookie umożliwia śledzenie użytkownika poprzez synchronizację identyfikatora w wielu domenach Microsoftu.",
											length: "390 dni",
										},
										{
											host: ".c.clarity.ms",
											cookies: "SM",
											type: "3rd Party",
											objective:
												"Ten plik cookie jest wykorzystywany do synchronizacji identyfikatora MUID w domenach Microsoftu. Używany do pomiaru korzystania z strony na potrzeby wewnętrznej analizy.",
											length: "Sesja",
										},
									]}
								>
									<Column width={160}>
										<HeaderCell>Nazwa</HeaderCell>
										<Cell dataKey="cookies" />
									</Column>
									<Column width={200}>
										<HeaderCell>Host</HeaderCell>
										<Cell dataKey="host" />
									</Column>
									<Column width={150}>
										<HeaderCell>Rodzaj</HeaderCell>
										<Cell dataKey="type" />
									</Column>
									<Column width={590}>
										<HeaderCell>Cel</HeaderCell>
										<Cell dataKey="objective" />
									</Column>
									<Column width={160}>
										<HeaderCell>Czas trwania</HeaderCell>
										<Cell dataKey="length" />
									</Column>
								</Table>
							))}
						<h4>
							<br />
						</h4>
						<Divider />
						<h4>
							<strong> {t("cookie_policy.2.title.21c", { ns: "namespace1" })} </strong>
							<br /> <br />
							{t("cookie_policy.2.paragraph.21c", { ns: "namespace1" })}
							<br />
							<br />
						</h4>
						{(i18n.language === "pt" && (
							<Table
								bordered
								cellBordered
								autoHeight
								wordWrap={true}
								headerHeight={80}
								className="cookie-table"
								data={[
									{
										host: ".urlbuilder.online",
										cookies: "_gcl_au",
										type: "Origem",
										objective:
											"Este cookie é usado pelo Google Adsense para experimentar a eficiência do anúncio em sites usando seus serviços.",
										length: "90 dias",
									},
									{
										host: "www.urlbuilder.online",
										cookies: "ln_or",
										type: "Origem",
										objective:
											"Este cookie registra dados estatísticos sobre o comportamento dos usuários no site. Usado para análise interna pelo operador do site.",
										length: "1 dia",
									},
									{
										host: ".app.urlbuilder.online",
										cookies: "ln_or",
										type: "Origem",
										objective:
											"Este cookie registra dados estatísticos sobre o comportamento dos usuários no site. Usado para análise interna pelo operador do site.",
										length: "1 dia",
									},
									{
										host: "www.urlbuilder.online",
										cookies: "sib_cuid",
										type: "Origem",
										objective:
											"Este cookie coleta informações sobre a navegação e as preferências do site do usuário - isso é usado para segmentar um boletim informativo em potencial com base nessas informações.",
										length: "182 dias",
									},
									{
										host: ".doubleclick.net",
										cookies: "test_cookie",
										type: "Terceiros",
										objective:
											"Este cookie é utilizado para verificar se o navegador do utilizador suporta cookies.",
										length: "Sessão",
									},
									{
										host: "doubleclick.net",
										cookies: "IDE",
										type: "Terceiros",
										objective:
											"Este cookie é utilizado pelo Google DoubleClick para registar e comunicar as acções do utilizador do sítio web após visualização ou clique num dos anúncios do anunciante com o objectivo de medir a eficácia de um anúncio e de apresentar anúncios direccionados ao utilizador.",
										length: "365 dias",
									},
									{
										host: ".linkedin.com",
										cookies: "AnalyticsSyncHistory",
										type: "Terceiros",
										objective:
											"Este cookie é utilizado para armazenar informações sobre o tempo de sincronização com o cookie lms_analytics (utilizado para identificar membros do LinkedIn fora do LinkedIn para análise).",
										length: "30 dias",
									},
									{
										host: ".linkedin.com",
										cookies: "bcookie",
										type: "Terceiros",
										objective:
											"Este cookie é utilizado como um cookie de identificação do Browser para identificar de forma única os dispositivos que acedem ao LinkedIn para detectar a plataforma e para fins de diagnóstico",
										length: "30 dias",
									},
									{
										host: ".www.linkedin.com",
										cookies: "bscookie",
										type: "Terceiros",
										objective:
											"Este cookie é utilizado para lembrar que um utilizador logado é verificado por autenticação de dois factores",
										length: "365 dias",
									},
									{
										host: ".linkedin.com",
										cookies: "li_gc",
										type: "Terceiros",
										objective:
											"Este cookie armazena o estado de consentimento do utilizador do cookie para o domínio actual",
										length: "179 dias",
									},
									{
										host: ".linkedin.com",
										cookies: "lidc",
										type: "Terceiros",
										objective:
											"Este cookie facilita a selecção do centro de dados. Regista que cluster de servidores está a servir o visitante. Isto é utilizado em contexto com equilíbrio de carga, a fim de optimizar a experiência do utilizador.",
										length: "1 dia",
									},
									{
										host: ".linkedin.com",
										cookies: "UserMatchHistory",
										type: "Terceiros",
										objective:
											"Este cookie assegura a segurança da navegação do visitante, evitando a falsificação de pedidos cruzados no local. Este cookie é essencial para a segurança do sítio web e dos visitantes.",
										length: "30 dias",
									},
									{
										host: "sibautomation.com",
										cookies: "uuid",
										type: "Terceiros",
										objective:
											"Este cookie é utilizado para optimizar a relevância da publicidade através da recolha de dados de visitantes de múltiplos sítios web - esta troca de dados de visitantes é normalmente fornecida por um centro de dados ou intercâmbio de Ad-Exchange",
										length: "182 dias",
									},
								]}
							>
								<Column width={160}>
									<HeaderCell>Nome</HeaderCell>
									<Cell dataKey="cookies" />
								</Column>
								<Column width={200}>
									<HeaderCell>Anfitrião</HeaderCell>
									<Cell dataKey="host" />
								</Column>
								<Column width={150}>
									<HeaderCell>Tipo</HeaderCell>
									<Cell dataKey="type" />
								</Column>
								<Column width={590}>
									<HeaderCell>Finalidade</HeaderCell>
									<Cell dataKey="objective" />
								</Column>
								<Column width={160}>
									<HeaderCell>Duração</HeaderCell>
									<Cell dataKey="length" />
								</Column>
							</Table>
						)) ||
							(i18n.language === "en" && (
								<Table
									bordered
									cellBordered
									autoHeight
									wordWrap={true}
									headerHeight={80}
									className="cookie-table"
									data={[
										{
											host: ".urlbuilder.online",
											cookies: "_gcl_au",
											type: "1st Party",
											objective:
												"This cookie is used by Google AdSense for experimenting with advertisement efficiency across websites using their services.",
											length: "90 days",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "ln_or",
											type: "1st Party",
											objective:
												"This cookie registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator",
											length: "1 day",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "sib_cuid",
											type: "1st Party",
											objective:
												"This cookie collects information on the user's website navigation and preferences - This is used to target potential newsletter based upon this information.",
											length: "182 days",
										},
										{
											host: ".doubleclick.net",
											cookies: "test_cookie",
											type: "3rd Party",
											objective:
												"This cookie is used to check if the user's browser supports cookies.",
											length: "Session",
										},
										{
											host: ".doubleclick.net",
											cookies: "IDE",
											type: "3rd Party",
											objective:
												"This cookie is used by Google DoubleClick to register and report the website user's actions after viewing or clicking on e of the advertiser's ads with the purpose of measuring the efficacy of an ad and to present targeted ads to the user.",
											length: "365 days",
										},
										{
											host: ".linkedin.com",
											cookies: "AnalyticsSyncHistory",
											type: "3rd Party",
											objective:
												"This cookies is used to store information about the time a sync took place with the lms_analytics cookie (Used to identify LinkedIn Members off LinkedIn for analytics)",
											length: "30 days",
										},
										{
											host: ".linkedin.com",
											cookies: "bcookie",
											type: "3rd Party",
											objective:
												"This cookie is used as a Browser Identifier cookie to uniquely indentify devices accessing LinkedIn to detect abust on the platform and diagnostic purposes",
											length: "30 days",
										},
										{
											host: ".www.linkedin.com",
											cookies: "bscookie",
											type: "3rd Party",
											objective:
												"This cookie is used for remember that a logged in user is verified by two factor authentication",
											length: "365 days",
										},
										{
											host: ".linkedin.com",
											cookies: "li_gc",
											type: "3rd Party",
											objective:
												"This cookie stores the user's cookie consent state for the current domain",
											length: "179 days",
										},
										{
											host: ".linkedin.com",
											cookies: "lidc",
											type: "3rd Party",
											objective:
												"This cookie facilitate data center selection. Registers which server-cluster is serving the visitor. This is used in context with load balancing, in order to optimize user experience.",
											length: "1 day",
										},
										{
											host: ".linkedin.com",
											cookies: "UserMatchHistory",
											type: "3rd Party",
											objective:
												"This cookies ensures visitor browsing-security by preventing cross-site request forgery. This cookie is essential for the security of the website and visitor.",
											length: "30 days",
										},
										{
											host: "sibautomation.com",
											cookies: "uuid",
											type: "3rd Party",
											objective:
												"This cookie is used to optimize ad relevance by collecting visitor data from multiple websites – this exchange of visitor data is normally provided by a third-party data-center or ad-exchange.",
											length: "182 days",
										},
									]}
								>
									<Column width={160}>
										<HeaderCell>Name</HeaderCell>
										<Cell dataKey="cookies" />
									</Column>
									<Column width={200}>
										<HeaderCell>Host</HeaderCell>
										<Cell dataKey="host" />
									</Column>
									<Column width={150}>
										<HeaderCell>Type</HeaderCell>
										<Cell dataKey="type" />
									</Column>
									<Column width={590}>
										<HeaderCell>Purpose</HeaderCell>
										<Cell dataKey="objective" />
									</Column>
									<Column width={160}>
										<HeaderCell>Expiration</HeaderCell>
										<Cell dataKey="length" />
									</Column>
								</Table>
							)) ||
							(i18n.language === "es" && (
								<Table
									bordered
									cellBordered
									autoHeight
									wordWrap={true}
									headerHeight={80}
									className="cookie-table"
									data={[
										{
											host: ".urlbuilder.online",
											cookies: "_gcl_au",
											type: "Origen",
											objective:
												"Esta cookie es utilizada por Google AdSense para experimentar con la eficacia de los anuncios en los sitios web que utilizan sus servicios.",
											length: "90 días",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "ln_or",
											type: "Origen",
											objective:
												"Esta cookie registra datos estadísticos sobre el comportamiento de los usuarios en el sitio web. Utilizada para análisis internos por el analista del sitio web.",
											length: "1 día",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "sib_cuid",
											type: "Origen",
											objective:
												"Esta cookie recopila información sobre la navegación y las preferencias del usuario en el sitio web. Se utiliza para enviar posibles boletines informativos basados en esta información.",
											length: "182 días",
										},
										{
											host: ".doubleclick.net",
											cookies: "test_cookie",
											type: "Terceros",
											objective:
												"Esta cookie se utiliza para comprobar si el navegador del usuario admite cookies.",
											length: "Sesión",
										},
										{
											host: ".doubleclick.net",
											cookies: "IDE",
											type: "Terceros",
											objective:
												"Esta cookie es utilizada por Google DoubleClick para registrar e informar de las acciones del usuario del sitio web después de ver o hacer clic en uno de los anuncios del anunciante con el fin de medir la eficacia de un anuncio y presentar anuncios dirigidos al usuario.",
											length: "365 días",
										},
										{
											host: ".linkedin.com",
											cookies: "AnalyticsSyncHistory",
											type: "Terceros",
											objective:
												"Esta cookie se utiliza para almacenar información sobre el momento en que se produjo una sincronización con la cookie lms_analytics (Se utiliza para identificar a los miembros de LinkedIn fuera de LinkedIn para análisis)",
											length: "30 días",
										},
										{
											host: ".linkedin.com",
											cookies: "bcookie",
											type: "Terceros",
											objective:
												"Esta cookie se utiliza como una cookie de identificador de navegador para identificar de forma única los dispositivos que acceden a LinkedIn para detectar el abuso en la plataforma y con fines de diagnóstico",
											length: "30 días",
										},
										{
											host: ".www.linkedin.com",
											cookies: "bscookie",
											type: "Terceros",
											objective:
												"Esta cookie se utiliza para recordar que un usuario conectado está verificado por la autenticación de dos factores",
											length: "365 días",
										},
										{
											host: ".linkedin.com",
											cookies: "li_gc",
											type: "Terceros",
											objective:
												"Esta cookie almacena el estado de consentimiento de cookies del usuario para el dominio actual",
											length: "179 días",
										},
										{
											host: ".linkedin.com",
											cookies: "lidc",
											type: "Terceros",
											objective:
												"Esta cookie facilita la selección del centro de datos. Registra qué grupo de servidores está sirviendo al visitante. Se utiliza en contexto con el equilibrio de carga, con el fin de optimizar la experiencia del usuario.",
											length: "1 día",
										},
										{
											host: ".linkedin.com",
											cookies: "UserMatchHistory",
											type: "Terceros",
											objective:
												"Esta cookie garantiza la seguridad de la navegación del visitante al evitar la falsificación de solicitudes entre sitios. Esta cookie es esencial para la seguridad del sitio web y de los visitantes.",
											length: "30 días",
										},
										{
											host: "sibautomation.com",
											cookies: "uuid",
											type: "Terceros",
											objective:
												"Esta cookie se utiliza para optimizar la relevancia de los anuncios mediante la recopilación de datos de visitantes de varios sitios web; este intercambio de datos de visitantes suele ser proporcionado por un centro de datos de terceros o un intercambio de anuncios.",
											length: "182 días",
										},
									]}
								>
									<Column width={160}>
										<HeaderCell>Nombre</HeaderCell>
										<Cell dataKey="cookies" />
									</Column>
									<Column width={200}>
										<HeaderCell>Anfitrión</HeaderCell>
										<Cell dataKey="host" />
									</Column>
									<Column width={150}>
										<HeaderCell>Tipo</HeaderCell>
										<Cell dataKey="type" />
									</Column>
									<Column width={590}>
										<HeaderCell>Finalidad</HeaderCell>
										<Cell dataKey="objective" />
									</Column>
									<Column width={160}>
										<HeaderCell>Caducidad</HeaderCell>
										<Cell dataKey="length" />
									</Column>
								</Table>
							)) ||
							(i18n.language === "pl" && (
								<Table
									bordered
									cellBordered
									autoHeight
									wordWrap={true}
									headerHeight={80}
									className="cookie-table"
									data={[
										{
											host: ".urlbuilder.online",
											cookies: "_gcl_au",
											type: "1st Party",
											objective:
												"Ten plik cookie jest wykorzystywany przez Google AdSense do eksperymentowania z efektywnością reklam na stronach korzystających z ich usług.",
											length: "90 dni",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "ln_or",
											type: "1st Party",
											objective:
												"Ten plik cookie rejestruje dane statystyczne dotyczące zachowania użytkowników na stronie internetowej. Wykorzystywany do wewnętrznej analizy przez operatora strony.",
											length: "1 dzień",
										},
										{
											host: "www.urlbuilder.online",
											cookies: "sib_cuid",
											type: "1st Party",
											objective:
												"Ten plik cookie gromadzi informacje na temat nawigacji użytkownika na stronie internetowej i jego preferencji Jest to wykorzystywane do targetowania potencjalnych newsletterów na podstawie tych informacji.",
											length: "182 dni",
										},
										{
											host: ".doubleclick.net",
											cookies: "test_cookie",
											type: "3rd Party",
											objective:
												"Ten plik cookie służy do sprawdzenia, czy przeglądarka użytkownika obsługuje pliki cookie.",
											length: "Sesja",
										},
										{
											host: ".doubleclick.net",
											cookies: "IDE",
											type: "3rd Party",
											objective:
												"Ten plik cookie jest wykorzystywany przez Google DoubleClick do rejestrowania i raportowania działań użytkownika strony internetowej po obejrzeniu lub kliknięciu jednej z reklam reklamodawcy w celu zmierzenia skuteczności reklamy i przedstawienia użytkownikowi ukierunkowanych reklam.",
											length: "365 dni",
										},
										{
											host: ".linkedin.com",
											cookies: "AnalyticsSyncHistory",
											type: "3rd Party",
											objective:
												"Ten plik cookie służy do przechowywania informacji o czasie, w którym nastąpiła synchronizacja z plikiem cookie lms_analytics (służy do identyfikacji członków LinkedIn poza LinkedIn na potrzeby analizy).",
											length: "30 dni",
										},
										{
											host: ".linkedin.com",
											cookies: "bcookie",
											type: "3rd Party",
											objective:
												"Ten plik cookie jest używany jako plik cookie identyfikujący przeglądarkę, aby jednoznacznie zidentyfikować urządzenia uzyskujące dostęp do LinkedIn w celu wykrycia nadużyć na platformie i w celach diagnostycznych.",
											length: "30 dni",
										},
										{
											host: ".www.linkedin.com",
											cookies: "bscookie",
											type: "3rd Party",
											objective:
												"Ten plik cookie służy do zapamiętania, że zalogowany użytkownik jest weryfikowany za pomocą uwierzytelniania dwuskładnikowego.",
											length: "365 dni",
										},
										{
											host: ".linkedin.com",
											cookies: "li_gc",
											type: "3rd Party",
											objective:
												"Ten plik cookie przechowuje stan zgody użytkownika na korzystanie z plików cookie w bieżącej domenie.",
											length: "179 dni",
										},
										{
											host: ".linkedin.com",
											cookies: "lidc",
											type: "3rd Party",
											objective:
												"Ten plik cookie ułatwia wybór centrum danych. Rejestruje, który klaster serwerów obsługuje odwiedzającego. Jest to wykorzystywane w kontekście równoważenia obciążenia, w celu optymalizacji doświadczeń użytkownika.",
											length: "1 dzień",
										},
										{
											host: ".linkedin.com",
											cookies: "UserMatchHistory",
											type: "3rd Party",
											objective:
												"Ten plik cookie zapewnia bezpieczeństwo przeglądania strony przez odwiedzającego, zapobiegając fałszowaniu żądań cross-site. Ten plik cookie jest niezbędny dla bezpieczeństwa strony i użytkowników.",
											length: "30 dni",
										},
										{
											host: "sibautomation.com",
											cookies: "uuid",
											type: "3rd Party",
											objective:
												"Ten plik cookie jest wykorzystywany do optymalizacji trafności reklam poprzez gromadzenie danych o użytkownikach z wielu stron internetowych - taka wymiana danych o użytkownikach jest zwykle zapewniana przez zewnętrzne centrum danych lub ad-exchange.",
											length: "182 dni",
										},
									]}
								>
									<Column width={160}>
										<HeaderCell>Nazwa</HeaderCell>
										<Cell dataKey="cookies" />
									</Column>
									<Column width={200}>
										<HeaderCell>Host</HeaderCell>
										<Cell dataKey="host" />
									</Column>
									<Column width={150}>
										<HeaderCell>Rodzaj</HeaderCell>
										<Cell dataKey="type" />
									</Column>
									<Column width={590}>
										<HeaderCell>Cel</HeaderCell>
										<Cell dataKey="objective" />
									</Column>
									<Column width={160}>
										<HeaderCell>Czas trwania</HeaderCell>
										<Cell dataKey="length" />
									</Column>
								</Table>
							))}
						<h4>
							<br />
						</h4>
						<Divider />
						<h4>
							<strong>{t("cookie_policy.2.title.22", { ns: "namespace1" })}</strong>
						</h4>
						<h4>
							<br /> {t("cookie_policy.2.paragraph.221", { ns: "namespace1" })}
							<br />
							<br /> <strong>{t("cookie_policy.2.title.22a", { ns: "namespace1" })}</strong>
							<ul>
								<li>
									{t("cookie_policy.2.paragraph.2221_text", {
										ns: "namespace1",
									})}
									<a
										href={t("cookie_policy.2.paragraph.2221_link", {
											ns: "namespace1",
										})}
										target={"_blank"}
										rel="noreferrer"
									>
										{t("cookie_policy.2.paragraph.2221_text2", {
											ns: "namespace1",
										})}
									</a>
								</li>
								<li>
									{t("cookie_policy.2.paragraph.2222_text", {
										ns: "namespace1",
									})}
									<a
										href={t("cookie_policy.2.paragraph.2222_link", {
											ns: "namespace1",
										})}
										target={"_blank"}
										rel="noreferrer"
									>
										{t("cookie_policy.2.paragraph.2222_text2", {
											ns: "namespace1",
										})}
									</a>
								</li>
							</ul>
							<br />
							{t("cookie_policy.2.paragraph.223", { ns: "namespace1" })}
							<br />
							<br />
						</h4>
						<Divider />
						<>
							<h4>
								<strong>{t("cookie_policy.2.title.23", { ns: "namespace1" })}</strong>
							</h4>
							<h4>
								<br />
								{t("cookie_policy.2.paragraph.23", { ns: "namespace1" })}
								<br />
								<br />
								<strong>{t("cookie_policy.2.title.23z", { ns: "namespace1" })}</strong>
								<br />
								<br />
								{t("cookie_policy.2.paragraph.23z", { ns: "namespace1" })}
								<a href="#" onClick={() => window.ppms.cm.api("openConsentForm", function (e) {})}>
									{t("cookie_policy.2.paragraph.23z2", { ns: "namespace1" })}
								</a>
								<br />
								<br />
								<strong>{t("cookie_policy.2.title.23a", { ns: "namespace1" })}</strong>
								<br />
								<br />
								{t("cookie_policy.2.paragraph.23a", { ns: "namespace1" })}
								<br />
								<br /> <strong>{t("cookie_policy.2.title.23b", { ns: "namespace1" })}</strong>
								<br />
								<br />
								{t("cookie_policy.2.paragraph.23b", { ns: "namespace1" })}
								<br />
								<br /> <strong>{t("cookie_policy.2.title.23c", { ns: "namespace1" })}</strong>
								<br />
								<br />
								{t("cookie_policy.2.paragraph.23c", { ns: "namespace1" })}
								<br />
								<br />
								<br /> <strong>{t("cookie_policy.2.title.23d", { ns: "namespace1" })}</strong>
								<br />
								<br />
								<ul>
									<li>
										<a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pt">
											{t("cookie_policy.2.paragraph.23d1", { ns: "namespace1" })}
										</a>
									</li>
									<li>
										<a href="https://support.apple.com/pt-pt/HT201265">
											{t("cookie_policy.2.paragraph.23d2", { ns: "namespace1" })}
										</a>
									</li>
									<li>
										<a href="https://support.apple.com/pt-pt/guide/safari/sfri11471/mac">
											{t("cookie_policy.2.paragraph.23d3", { ns: "namespace1" })}
										</a>
									</li>
									<li>
										<a href="https://support.mozilla.org/pt-BR/kb/limpe-cookies-e-dados-de-sites-no-firefox?redirectslug=exclua-cookies-para-remover-info-armazenadas&redirectlocale=pt-BR">
											{t("cookie_policy.2.paragraph.23d4", { ns: "namespace1" })}
										</a>
									</li>
									<li>
										<a href="https://support.microsoft.com/pt-pt/microsoft-edge/eliminar-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">
											{t("cookie_policy.2.paragraph.23d5", { ns: "namespace1" })}
										</a>
									</li>
									<li>
										<a href="https://support.microsoft.com/pt-pt/help/278835/how-to-delete-cookie-files-in-internet-explorer">
											{t("cookie_policy.2.paragraph.23d6", { ns: "namespace1" })}
										</a>
									</li>
								</ul>
								<br />
							</h4>
							<Divider />
							<h3>
								<strong>{t("cookie_policy.3.title.3", { ns: "namespace1" })}</strong>
							</h3>
							<h4>
								<br />
								{t("cookie_policy.3.paragraph.3", { ns: "namespace1" })}
								<br />
								<br />
								<br />
								<strong>{t("cookie_policy.close.text1", { ns: "namespace1" })}</strong>{" "}
								{t("cookie_policy.close.text2", { ns: "namespace1" })}
							</h4>
						</>
					</Panel>
				</div>
			)}
		</Translation>
	);
}

export default Cookie;
