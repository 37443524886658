import React from "react";
import { Translation } from "react-i18next";
import { Panel } from "rsuite";
import "./style.less";

function ServicePanel(props) {
  const title = props.title;
  const paragraph_1 = props.paragraph_1;
  const paragraph_2 = props.paragraph_2;
  const paragraph_3 = props.paragraph_3;
  const list = props.list;
  const id = props.id;
  return (
    <Translation>
      {(t) => (
        <Panel className="service-panel" id={id}>
          <div className="panel-info">
            <h2>{t(title)}</h2>
            {paragraph_1 && <h5>{t(paragraph_1)}</h5>}
            {paragraph_2 && <h5>{t(paragraph_2)}</h5>}
            {list && (
              <ul>
                {list.map((item) => (
                  <li>
                    <h5>{t(item)}</h5>
                  </li>
                ))}
              </ul>
            )}
            {paragraph_3 && <h5>{t(paragraph_3)}</h5>}
          </div>
          {props.card}
        </Panel>
      )}
    </Translation>
  );
}

export default ServicePanel;
