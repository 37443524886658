import React, { useState } from "react";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import { Divider, Icon, Panel, Tag, Toggle } from "rsuite";
import planManager from "../../planManager";
import PlanCard from "../PlanCard";
import "./style.less";

function PricingMainPanel(props) {
  const [cycle, setCycle] = useState("yearly");
  const [card, setCard] = useState("professional");
  return (
    <Translation>
      {(t) => (
        <Panel className="plan-cycle-selection">
          <div className="plan-list-container" style={{ width: "100%" }}>
            <h4>{t("plans_page.body.intro.text")}</h4>{" "}
            {/* <div className="coupon-announcement">
              <Tag>
                <Icon icon="tag" />
                URL2023
              </Tag>
              <h5>
                {t("plans_page.body.lauch.promo_1")}{" "}
                {t("plans_page.body.lauch.promo_2")}{" "}
                <strong>{t("plans_page.body.lauch.promo_3")}</strong>{" "}
                {t("plans_page.body.lauch.promo_4")}
              </h5>
            </div> */}
            <div className="cycle-selection" id="plans">
              <h4 className={`${cycle === "monthly" && "selected"}`}>
                {t("plans_page.body.toggle.text_1")}
              </h4>
              <Toggle
                style={{ margin: "0 3.5em" }}
                checked={cycle === "yearly"}
                onChange={() =>
                  setCycle(cycle === "monthly" ? "yearly" : "monthly")
                }
              />
              <h4 className={`${cycle === "yearly" && "selected"}`}>
                {t("plans_page.body.toggle.text_21")}
                <div style={{ width: "100%" }}>
                  <p>{t("plans_page.body.toggle.text_22")}</p>
                </div>
              </h4>
            </div>{" "}
          </div>
          <div style={{ width: "100%" }}>
            <div className="plan-popular">
              {(card === "professional" || !props.isMobile) && (
                <h4>
                  {t(
                    "plans_page.body.professional_plan.recommended_plan_recomendado"
                  )}
                </h4>
              )}
            </div>
          </div>
          {(!props.isMobile && (
            <div className="plan-list">
              <PlanCard type="categories" cycle={cycle} />
              <PlanCard
                position={"1"}
                cycle={cycle}
                planName={planManager("standard", "name")}
                planDescription="Tenho, ou terei em breve, uma Governança em vigor. Quero uma solução que se adapte totalmente às minhas políticas e requisitos para garantir consistência de dados, confiabilidade, prevenção de erros e produtividade para todas as equipas. Eu uso uma tecnologia analítica gratuita."
                priceMontly={planManager("standard", "price_monthly")}
                priceYearly={planManager("standard", "price_yearly")}
                basicFeatures={{
                  organizations:
                    "plans_page.body.standard_plan.text_main_features_1",
                  links: "plans_page.body.standard_plan.text_main_features_2",
                  users: "plans_page.body.standard_plan.text_main_features_2",
                  teams: "plans_page.body.standard_plan.text_main_features_3",
                  customDomains:
                    "plans_page.body.standard_plan.text_main_features_3",
                  templates:
                    "plans_page.body.standard_plan.text_main_features_2",
                }}
                keyFeatures={[
                  "plans_page.body.standard_plan.text_key_features",
                  "plans_page.body.standard_plan.text_key_features_2",
                  "plans_page.body.standard_plan.text_key_features_3",
                  "plans_page.body.standard_plan.text_key_features_4",
                  "plans_page.body.standard_plan.text_key_features_5",
                  "plans_page.body.standard_plan.text_key_features_6",
                  "plans_page.body.standard_plan.text_key_features_7",
                  "plans_page.body.standard_plan.text_key_features_8",
                ]}
              />
              <PlanCard
                position={"2"}
                cycle={cycle}
                planName={planManager("professional", "name")}
                planDescription="Tenho, ou terei em breve, uma Governança em vigor. Quero uma solução que se adapte totalmente às minhas políticas e requisitos para garantir consistência de dados, confiabilidade, prevenção de erros abrangente e produtividade para todas as equipas. Eu uso uma tecnologia analítica paga."
                priceMontly={planManager("professional", "price_monthly")}
                priceYearly={planManager("professional", "price_yearly")}
                basicFeatures={{
                  organizations:
                    "plans_page.body.professional_plan.text_main_features_1",
                  links:
                    "plans_page.body.professional_plan.text_main_features_2",
                  users:
                    "plans_page.body.professional_plan.text_main_features_2",
                  teams:
                    "plans_page.body.professional_plan.text_main_features_3",
                  customDomains:
                    "plans_page.body.professional_plan.text_main_features_3",
                  templates:
                    "plans_page.body.professional_plan.text_main_features_2",
                }}
                featuresText="plans_page.body.professional_plan.text_key_features_text"
                keyFeatures={[
                  "plans_page.body.professional_plan.text_key_features_1",
                  "plans_page.body.professional_plan.text_key_features_2",
                  "plans_page.body.professional_plan.text_key_features_3",
                  "plans_page.body.professional_plan.text_key_features_4",
                ]}
              />
              <PlanCard
                position={"3"}
                isEnterprise
                cycle={cycle}
                planName={planManager("enterprise", "name")}
                planDescription="Tenho, ou terei em breve, uma Governança em vigor. Quero uma solução que se adapte totalmente às minhas políticas e requisitos para garantir consistência de dados, confiabilidade, prevenção de erros abrangente e produtividade para todas as equipas. Eu uso uma tecnologia analítica paga."
                basicFeatures={{
                  organizations:
                    "plans_page.body.enterprise_plan.text_main_features_1",
                  links: "plans_page.body.enterprise_plan.text_main_features_1",
                  users: "plans_page.body.enterprise_plan.text_main_features_2",
                  teams: "plans_page.body.enterprise_plan.text_main_features_3",
                  customDomains:
                    "plans_page.body.enterprise_plan.text_main_features_4",
                  templates:
                    "plans_page.body.enterprise_plan.text_main_features_1",
                }}
                featuresText="plans_page.body.enterprise_plan.text_key_features_text"
                keyFeatures={[
                  "plans_page.body.enterprise_plan.text_key_features_1",
                  "plans_page.body.enterprise_plan.text_key_features_2",
                  "plans_page.body.enterprise_plan.text_key_features_3",
                  "plans_page.body.enterprise_plan.text_key_features_4",
                  "plans_page.body.enterprise_plan.text_key_features_5",
                  "plans_page.body.enterprise_plan.text_key_features_6",
                  "plans_page.body.enterprise_plan.text_key_features_7",
                ]}
              />
            </div>
          )) || (
            <div class="container plan-list">
              <input
                type="radio"
                name="slider"
                id="item-1"
                checked={card === "professional"}
                onChange={() => setCard("professional")}
              />
              <input
                type="radio"
                name="slider"
                id="item-2"
                checked={card === "enterprise"}
                onChange={() => setCard("enterprise")}
              />
              <input
                type="radio"
                name="slider"
                id="item-3"
                checked={card === "standard"}
                onChange={() => setCard("standard")}
              />
              <div class="cards">
                <label class="card" for="item-1" id="song-1">
                  <PlanCard
                    position={"1"}
                    mobile={props.isMobile}
                    cycle={cycle}
                    planName={planManager("professional", "name")}
                    planDescription="Tenho, ou terei em breve, uma Governança em vigor. Quero uma solução que se adapte totalmente às minhas políticas e requisitos para garantir consistência de dados, confiabilidade, prevenção de erros abrangente e produtividade para todas as equipas. Eu uso uma tecnologia analítica paga."
                    priceMontly={planManager("professional", "price_monthly")}
                    priceYearly={planManager("professional", "price_yearly")}
                    basicFeatures={{
                      organizations:
                        "plans_page.body.professional_plan.text_main_features_1",
                      links:
                        "plans_page.body.professional_plan.text_main_features_4",
                      users:
                        "plans_page.body.professional_plan.text_main_features_4",
                      teams:
                        "plans_page.body.professional_plan.text_main_features_3",
                      customDomains:
                        "plans_page.body.professional_plan.text_main_features_3",
                      templates:
                        "plans_page.body.professional_plan.text_main_features_4",
                    }}
                    featuresText="plans_page.body.professional_plan.text_key_features_text"
                    keyFeatures={[
                      "plans_page.body.professional_plan.text_key_features_1",
                      "plans_page.body.professional_plan.text_key_features_2",
                      "plans_page.body.professional_plan.text_key_features_3",
                      "plans_page.body.professional_plan.text_key_features_4",
                    ]}
                  />
                </label>
                <label class="card" for="item-2" id="song-2">
                  <PlanCard
                    position={"2"}
                    mobile={props.isMobile}
                    isEnterprise
                    cycle={cycle}
                    planName={planManager("enterprise", "name")}
                    planDescription="Tenho, ou terei em breve, uma Governança em vigor. Quero uma solução que se adapte totalmente às minhas políticas e requisitos para garantir consistência de dados, confiabilidade, prevenção de erros abrangente e produtividade para todas as equipas. Eu uso uma tecnologia analítica paga."
                    basicFeatures={{
                      organizations:
                        "plans_page.body.enterprise_plan.text_main_features_1",
                      links:
                        "plans_page.body.enterprise_plan.text_main_features_2",
                      users:
                        "plans_page.body.enterprise_plan.text_main_features_3",
                      teams:
                        "plans_page.body.enterprise_plan.text_main_features_4",
                      customDomains:
                        "plans_page.body.enterprise_plan.text_main_features_4",
                      templates:
                        "plans_page.body.enterprise_plan.text_main_features_4",
                    }}
                    featuresText="plans_page.body.enterprise_plan.text_key_features_text"
                    keyFeatures={[
                      "plans_page.body.enterprise_plan.text_key_features_1",
                      "plans_page.body.enterprise_plan.text_key_features_2",
                      "plans_page.body.enterprise_plan.text_key_features_3",
                      "plans_page.body.enterprise_plan.text_key_features_4",
                      "plans_page.body.enterprise_plan.text_key_features_5",
                      "plans_page.body.enterprise_plan.text_key_features_6",
                      "plans_page.body.enterprise_plan.text_key_features_7",
                    ]}
                  />
                </label>
                <label class="card" for="item-3" id="song-3">
                  <PlanCard
                    position={"3"}
                    mobile={props.isMobile}
                    cycle={cycle}
                    planName={planManager("standard", "name")}
                    planDescription="Tenho, ou terei em breve, uma Governança em vigor. Quero uma solução que se adapte totalmente às minhas políticas e requisitos para garantir consistência de dados, confiabilidade, prevenção de erros e produtividade para todas as equipas. Eu uso uma tecnologia analítica gratuita."
                    priceMontly={planManager("standard", "price_monthly")}
                    priceYearly={planManager("standard", "price_yearly")}
                    basicFeatures={{
                      organizations:
                        "plans_page.body.standard_plan.text_main_features_1",
                      links:
                        "plans_page.body.standard_plan.text_main_features_2",
                      users:
                        "plans_page.body.standard_plan.text_main_features_2",
                      teams:
                        "plans_page.body.standard_plan.text_main_features_3",
                      customDomains:
                        "plans_page.body.standard_plan.text_main_features_3",
                      templates:
                        "plans_page.body.standard_plan.title_main_features_4",
                    }}
                    keyFeatures={[
                      "plans_page.body.standard_plan.text_key_features",
                      "plans_page.body.standard_plan.text_key_features_2",
                      "plans_page.body.standard_plan.text_key_features_3",
                      "plans_page.body.standard_plan.text_key_features_4",
                      "plans_page.body.standard_plan.text_key_features_5",
                      "plans_page.body.standard_plan.text_key_features_6",
                      "plans_page.body.standard_plan.text_key_features_7",
                      "plans_page.body.standard_plan.text_key_features_8",
                    ]}
                  />
                </label>
              </div>
            </div>
          )}
          <Link to="/support" className="faq-link">
            <h4>{t("faqs_page.body.title_2.text")}</h4>
          </Link>
        </Panel>
      )}
    </Translation>
  );
}

export default PricingMainPanel;
