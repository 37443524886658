import React from "react";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Container, Icon, IconStack, Panel } from "rsuite";
import DemoRequestPopup from "../DemoRequestPopup";
import "./style.less";
import { sendDatalayerEvent } from "../../piwikEvents";
import i18n from "../../i18n";

function DemoPanel(props) {
	return (
		<Translation>
			{(t) => (
				<Panel className="demo-panel">
					{props.text && (
						<Container className="demo-text">
							<h4>{t("feature_page.body.features.text_cta")}</h4>
						</Container>
					)}
					{/*
          <Link
            to={{
              search: "?demo-request",
            }}
          ></Link>
           */}
					<Button
						className="button-primary"
						onClick={() => {
							let name;
							if (window.location.pathname.split("/")[1] === "features") {
								name = "Funcionalidades :: Intent Marcar Demo";
							} else if (window.location.pathname.split("/")[1] === "pricing") {
								name = "Preços :: Intent Marcar Demo";
							} else {
								name = "Homepage :: Intent Marcar Demo";
							}
							let position = props.position;
							sendDatalayerEvent(
								"site_intent",
								{
									category: "CTA",
									action: "intent_book_demo",
									event_name: name,
								},
								{
									ctaName: i18n.t("home_page.body.our_solution.button_cta"),
									ctaPosition: position,
									elementInteraction: "click",
									elementName: "button",
									pageLocation: "body",
									product: "urlbuilder",
									subdomainLocation: "urlbuilder.online",
								}
							);
							window.Calendly.initPopupWidget({
								url: `https://calendly.com/urlbuilder/30min?hide_gdpr_banner=1`,
							});
						}}
					>
						{t("home_page.body.our_solution.button_cta").toUpperCase()}
					</Button>
					{/*
            
          <div className="demo-info">
            <div>
              <IconStack size="lg">
                <Icon icon="credit-card" stack="1x" />
                <Icon icon="close" stack="2x" style={{ color: "#f44336" }} />
              </IconStack>
              <p>{t("home_page.body.our_solution.text_cc")}</p>
            </div>
            <div>
              <Icon icon="eye-slash" size="2x" />
              <p>{t("home_page.body.our_solution.text_gdpr")}</p>
            </div>
          </div>
            */}
					{!props.noImg && (
						<div className="demo-preview">
							<img src="./images/pc2.png" alt="url builder dashboard" />
						</div>
					)}
				</Panel>
			)}
		</Translation>
	);
}

export default DemoPanel;
