import axios from "axios";

const requestService = axios.create({
  baseURL: process.env.REACT_APP_FB_URI,
});

const helpRequest = (body) => {
  if (!body) {
    return;
  }
  return requestService
    .post("/help-email", body)
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
const enterpriseRequest = (body) => {
  if (!body) {
    return;
  }
  return requestService
    .post("/help-email-enterprise", body)
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const demoRequest = (body) => {
  if (!body) {
    return;
  }
  return requestService
    .post("/email-demo", body)
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export { helpRequest, demoRequest, enterpriseRequest };
