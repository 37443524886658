import React, { useState } from "react";
import { Translation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Alert, Button, Divider, Input, Modal } from "rsuite";
import { demoRequest } from "../../services/requests";
import "./style.less";

function DemoRequestPopup(props) {
  const path = window.location.search;
  const [email, setEmail] = useState("");

  const handleRequestSubmit = () => {
    const values = {
      email: email,
    };
    demoRequest(values)
      .then((response) => {
        Alert.success("Pedido enviado com sucesso.");
        props.history.push(window.location.pathname);
      })
      .catch((error) => {
        Alert.success("Não foi possível enviar o seu pedido.");
        props.history.push(window.location.pathname);
        console.log(error);
      });
  };

  return (
    <Translation>{(t)=>(
      <Modal
        id="demo-request-popup"
        size={"sm"}
        show={path.includes("demo-request")}
        onHide={() => props.history.push(window.location.pathname)}
      >
        <Modal.Header>
          <Modal.Title>
            <h4>{t("popup.demo.demo_request.title")}</h4>
          </Modal.Title>
        </Modal.Header>
        <Divider style={{ margin: "0.5em 0" }} />
        <Modal.Body>
          <h5>
            {t("popup.demo.demo_request.text")}
          </h5>
          <Input
            placeholder={t("popup.demo.demo_request.field")}
            type="email"
            value={email}
            onChange={setEmail}
          />
        </Modal.Body>
        <Modal.Footer>
          <Divider style={{ margin: "0.5em 0" }} />
          <Button
            className="button-primary green"
            disabled={!email}
            onClick={handleRequestSubmit}
          >
            {t("popup.demo.demo_request.button")}
          </Button>
        </Modal.Footer>
      </Modal>
    )}</Translation>
  );
}

export default withRouter(DemoRequestPopup);
