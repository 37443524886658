import React from "react";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Panel } from "rsuite";
import "./style.less";
import { sendDatalayerEvent } from "../../piwikEvents";
import i18n from "../../i18n";

function ServiceCard(props) {
	const title = props.title;
	const additionalTitle = props.additionalTitle;
	const priceText = props.priceText;
	const price = props.price;
	const paragraph_1 = props.paragraph_1;
	const paragraph_2 = props.paragraph_2;
	return (
		<Translation>
			{(t) => (
				<div style={{ marginTop: "-5em" }}>
					<div className="service-price">
						<h3>{t(props.priceText)}</h3>
						{props.price && <h2>{t(props.price)}</h2>}
					</div>
					<div className="service-card">
						<Panel className="service-card-container">
							<div className="service-card-info">
								<h2>{t(props.title)}</h2>
							</div>
							<div className="service-description">
								<h4>{t(props.paragraph_1)}</h4>
								{props.paragraph_2 && <h4>{t(props.paragraph_2)}</h4>}
							</div>
							<Link
								to={{
									search: `?help-request=${t(props.index)}`,
								}}
							>
								<Button
									className="button-primary green"
									onClick={() => {
										let quotation = props.title;
										let position = props.index;

										sendDatalayerEvent(
											"site_intent",
											{
												category: "CTA",
												action: "intent_quotation",
												event_name: "Serviços :: Intent Cotação",
											},
											{
												ctaName: i18n.t("services_page.body.services.box_cta_1"),
												ctaPosition: position,
												elementInteraction: "click",
												elementName: "button",
												pageLocation: "body",
												product: "urlbuilder",
												subdomainLocation: "urlbuilder.online",
												quotationType: i18n.t(quotation),
											}
										);
									}}
								>
									<h4>{t("services_page.body.services.box_cta_1")}</h4>
								</Button>
							</Link>
						</Panel>
						{/*
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                stroke="#15DB95"
                strokeWidth={3}
                fill-opacity="0"
                d="M0,128L80,122.7C160,117,320,107,480,138.7C640,171,800,245,960,261.3C1120,277,1280,235,1360,213.3L1440,192L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
              ></path>
            </svg>
            */}
					</div>
				</div>
			)}
		</Translation>
	);
}

export default ServiceCard;
