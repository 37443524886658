import React from "react";
import { Translation } from "react-i18next";
import { Icon, Panel } from "rsuite";
import "./style.less";

function HelpOptionsPanel() {
  return (
    <Translation>
      {(t) => (
        <Panel className="help-options-panel">
          <h2>{t("faqs_page.body.title_1.text")}</h2>
          <div className="options-list">
            <div className="panel-option">
              <img
                src="./images/pc_help.png"
                alt="laptop displaying the help center for the platform"
              />
              <div className="panel-info">
                <img src="./main_logo.png" alt="url builder logo" />
                <h3>{t("faqs_page.body.suport_1.text")}</h3>
              </div>
            </div>

            <div className="panel-option">
              <img
                src="./images/pc_email.png"
                alt="laptop displaying an email"
              />
              <div className="panel-info">
                <Icon icon="envelope-o" size="2x"></Icon>
                <h3>{t("faqs_page.body.suport_2.text")}</h3>
              </div>
            </div>

            <div className="panel-option">
              <img
                src="./images/pc_onboarding.png"
                alt="laptop displaying the onboarding flow on the platform"
              />
              <div className="panel-info">
                <Icon icon="explore" size="2x"></Icon>
                <h3>{t("faqs_page.body.suport_3.text")}</h3>
              </div>
            </div>
          </div>
        </Panel>
      )}
    </Translation>
  );
}

export default HelpOptionsPanel;
