import React, { useState } from "react";
import { Translation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import {
  Alert,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Input,
  Modal,
} from "rsuite";
import i18n from "../../i18n";
import { sendDatalayerEvent } from "../../piwikEvents";
import { helpRequest } from "../../services/requests";
import Recaptcha from "../Recaptcha";
import "./style.less";

function HelpRequestPopup(props) {
  const path = window.location.search;
  const [vals, setVals] = useState("");
  const [checks, setChecks] = useState([]);

  const handleRequestSubmit = () => {
    console.log("first");
    console.log("vals: ", vals);
    console.log("props: ", props);
    const values = {
      name: vals.name,
      email: vals.email,
      subject: checks,
      text: vals.text,
    };
    let topics = [...checks].map((element) => {
      switch (element) {
        case "1":
          return i18n.t("popup.serviços.pedidos_de_cotação.checkbox_2");
        case "2":
          return i18n.t("popup.serviços.pedidos_de_cotação.checkbox_3");
        case "3":
          return i18n.t("popup.serviços.pedidos_de_cotação.checkbox_4");
        case "4":
          return i18n.t("popup.serviços.pedidos_de_cotação.checkbox_5");
        case "5":
          return i18n.t("popup.serviços.pedidos_de_cotação.checkbox_6");
        case "6":
          return i18n.t("popup.serviços.pedidos_de_cotação.checkbox_7");
        default:
          return "Unknown";
      }
    });
    helpRequest(values)
      .then((response) => {
        sendDatalayerEvent(
          "site_intent",
          {
            category: "Form",
            action: "quotation_request",
            event_name: "Serviços :: Popup Cotação",
          },
          {
            ctaName: i18n.t("popup.serviços.pedidos_de_cotação.button"),
            domainEmail: vals.email.split("@")[1],
            elementInteraction: "submit",
            elementName: "button",
            formName: "pedido de cotacao",
            formStepName: "pedido de cotacao",
            pageLocation: "popup pedido de cotacao",
            product: "urlbuilder",
            submitResult: "success",
            subdomainLocation: "urlbuilder.online",
            quotationType: "modelo de dados de media",
            quotationTopics: topics.join(";"),
          }
        );
        Alert.success(i18n.t("popup.all.resposta_envio.sucesso"));
        props.history.push(window.location.pathname);
      })
      .catch((error) => {
        let errorMessage = i18n.t("popup.all.resposta_envio.erro");
        console.log(error);
        if (
          error.response &&
          error.response.data.message === "Must be a valid email Adress"
        ) {
          errorMessage = i18n.t("popup.all.resposta_envio.validacao_email");
        }
        sendDatalayerEvent(
          "site_intent",
          {
            category: "Form",
            action: "quotation_request_error",
            event_name: "Serviços :: Popup Cotação",
          },
          {
            ctaName: i18n.t("popup.serviços.pedidos_de_cotação.button"),
            domainEmail: vals.email.split("@")[1],
            elementInteraction: "submit",
            elementName: "button",
            errorName: errorMessage,
            formName: "pedido de cotacao",
            formStepName: "pedido de cotacao",
            pageLocation: "popup pedido de cotacao",
            product: "urlbuilder",
            submitResult: "error",
            subdomainLocation: "urlbuilder.online",
            quotationType: "modelo de dados de media",
            quotationTopics: topics.join(";"),
          }
        );
        Alert.error(errorMessage);
        console.log(error);
      });
  };

  return (
    <Translation>
      {(t) => (
        <Modal
          id="help-request-popup"
          size={"sm"}
          show={path.includes("help-request")}
          onHide={() => props.history.push(window.location.pathname)}
          onShow={() => {
            let param = decodeURI(window.location.search.split("=")[1]);
            console.log(param);
            setChecks([param.replace("+ ", "")]);
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <h4>{t("popup.serviços.pedidos_de_cotação.title")}</h4>
            </Modal.Title>
          </Modal.Header>
          <Divider style={{ margin: "0.5em 0" }} />
          <Modal.Body>
            <div className="request-item">
              <h5>{t("popup.serviços.pedidos_de_cotação.text_1")}</h5>
              <Input
                placeholder={t("popup.serviços.pedidos_de_cotação.field_1")}
                type="name"
                value={vals.name}
                onChange={(val) => setVals({ ...vals, name: val })}
              />
            </div>
            <div className="request-item">
              <h5 style={{ marginTop: "0.5em" }}>
                {t("popup.serviços.pedidos_de_cotação.text_2")}
              </h5>
              <Input
                placeholder={t("popup.serviços.pedidos_de_cotação.field_2")}
                type="name"
                value={vals.email}
                onChange={(val) => setVals({ ...vals, email: val })}
              />
            </div>

            <div className="request-item">
              <h5>{t("popup.serviços.pedidos_de_cotação.title")}</h5>
              <CheckboxGroup
                value={checks}
                onChange={(val) => {
                  setChecks(val);
                }}
              >
                {/*
                <Checkbox
                  value={t("services_page.body.services.box_title_1")}
                  onChange={(val) => {
                    setChecks([val]);
                  }}
                >
                  {t("popup.serviços.pedidos_de_cotação.checkbox_1")}
                </Checkbox>
                 */}
                <Checkbox value={"1"}>
                  {t("popup.serviços.pedidos_de_cotação.checkbox_2")}
                </Checkbox>
                <Checkbox value={"2"}>
                  {t("popup.serviços.pedidos_de_cotação.checkbox_3")}
                </Checkbox>
                <Checkbox value={"3"}>
                  {t("popup.serviços.pedidos_de_cotação.checkbox_4")}
                </Checkbox>
                <Checkbox value={"4"}>
                  {t("popup.serviços.pedidos_de_cotação.checkbox_5")}
                </Checkbox>
                <Checkbox value={"5"}>
                  {t("popup.serviços.pedidos_de_cotação.checkbox_6")}
                </Checkbox>
                <Checkbox value={"6"}>
                  {t("popup.serviços.pedidos_de_cotação.checkbox_7")}
                </Checkbox>
              </CheckboxGroup>
            </div>
            <div className="request-item">
              <h5>{t("popup.plano_enterprise.pedido_contato.text_5")}</h5>
              <Input
                componentClass="textarea"
                rows={5}
                placeholder={t("popup.serviços.pedidos_de_cotação.field_4")}
                value={vals.text}
                onChange={(val) => setVals({ ...vals, text: val })}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Divider style={{ margin: "0.5em 0" }} />
            <div className="checkbox-item">
              <p>
                {t("popup.plano_enterprise.pedido_contato.legal_text")}{" "}
                <Link to="/cookie-policy">
                  {t("popup.plano_enterprise.pedido_contato.text_link")}
                </Link>
              </p>
            </div>
            <Recaptcha />
            <Button
              className="button-primary green"
              disabled={!vals.email || !vals.email.includes("@")}
              onClick={() => handleRequestSubmit()}
            >
              {t("popup.serviços.pedidos_de_cotação.button")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Translation>
  );
}

export default withRouter(HelpRequestPopup);
