const planPermissions = {
  name: {
    enterprise: "Enterprise",
    professional: "Professional",
    standard: "Standard",
  },
  price_monthly: {
    professional: "159",
    standard: "69",
  },
  price_yearly: {
    professional: "1548",
    standard: "684",
  },
  organizations: {
    enterprise: 9999999,
    professional: 1,
    standard: 1,
  },
  users: {
    enterprise: 9999999,
    professional: 9999999,
    standard: 9999999,
  },
  teams: {
    enterprise: 9999999,
    professional: 5,
    standard: 3,
  },
  custom_domains: {
    enterprise: 9999999,
    professional: 5,
    standard: 3,
  },
  templates: {
    enterprise: 9999999,
    professional: 9999999,
    standard: 9999999,
  },
  user_permissions: {
    enterprise: "custom",
    professional: "advanced",
    standard: "basic",
  },
  error_minimization: {
    enterprise: "premium",
    professional: "premium",
    standard: "premium",
  },
  link_attributes: { enterprise: true },
  dynamic_url_parameters: { enterprise: true },
  custom_parameters: { enterprise: true, professional: true },
  parameter_dependencies: { enterprise: true, professional: true },
  bulk_links: { enterprise: true, professional: true },
  qr_codes: { enterprise: true, professional: true },
  utm_cleaner: { enterprise: true, professional: true },
  campaign_taxonomy: { enterprise: true, professional: true },
  campaign_parameters: { enterprise: true, professional: true },
  concatenated_parameters: {
    enterprise: true,
    professional: true,
    standard: true,
  },
  analytics_tech_param_import: {
    enterprise: true,
    professional: true,
    standard: true,
  },
  campaign_dashboard: { enterprise: true, professional: true, standard: true },
  workflow_status: { enterprise: true, professional: true, standard: true },
  auto_link_qa: { enterprise: true, professional: true, standard: true },
  url_redirect_favicon: {
    enterprise: true,
    professional: true,
    standard: true,
  },
  audit_tool: { enterprise: true, professional: true, standard: true },
  campaign_archive: {
    enterprise: true,
    professional: true,
    standard: true,
  },
  archived_reactivation: {
    enterprise: true,
    professional: true,
    standard: true,
  },
  campaign_tagging: {
    enterprise: true,
    professional: true,
    standard: true,
  },
  csv_param_import: {
    enterprise: true,
    professional: true,
    standard: true,
  },
  ga_param_import: {
    enterprise: true,
    professional: true,
    standard: true,
  },
  csv_exports: {
    enterprise: true,
    professional: true,
    standard: true,
  },
  link_shorteners: {
    enterprise: true,
    professional: true,
    standard: true,
  },
  bitly_click_counts: {
    enterprise: true,
    professional: true,
    standard: true,
  },
  custom_url_slug: {
    enterprise: true,
    professional: true,
    standard: true,
  },
  dark_mode: { enterprise: true, professional: true, standard: true },
  color_schemes: {
    enterprise: true,
    professional: true,
    standard: true,
  },
};

const planManager = (plan, permission) => {
  if (!plan || !permission) {
    return false;
  }

  return planPermissions[permission][plan] || false;
};

export default planManager;
