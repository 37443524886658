import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import { Dropdown, Icon, InputGroup, SelectPicker } from "rsuite";
import i18n from "../../i18n";
import "./style.less";
import { sendDatalayerEvent } from "../../piwikEvents";

const HeaderMessage = (props) => {
	const message = props.message;
	const highlight = props.highlight;

	const languages = ["PT", "EN", "PL", "ES"];
	const countryNames = {
		pt: "Português",
		en: "English",
		pl: "Polskie",
		es: "Español",
	};
	return (
		<Translation>
			{(t) => (
				<div className="header-message gradient-bg">
					<div className="message-container">
						<div></div>
						<div className="header-text">
							<h5>{t(message)}</h5>
							<Link
								style={{ marginLeft: "0.25em" }}
								onClick={() => {
									sendDatalayerEvent(
										"site_intent",
										{
											category: "CTA",
											action: "intent_book_demo",
											event_name: "Header Menu :: Intent Marcar Demo",
										},
										{
											ctaName: i18n.t("all_pages.header.floating_bar.text_highlight"),
											elementInteraction: "click",
											elementName: "link",
											pageLocation: "header",
											product: "urlbuilder",
											subdomainLocation: "urlbuilder.online",
										}
									);
									window.Calendly.initPopupWidget({
										url: `https://calendly.com/urlbuilder/30min?hide_gdpr_banner=1`,
									});
								}}
							>
								<h5>{t(highlight)}</h5>
							</Link>
						</div>
						<div>
							<Dropdown
								title={i18n.language.toUpperCase()}
								icon={<Icon icon="globe" />}
								noCaret
								className="lang-picker"
								placement="bottomEnd"
							>
								{["pt", "en", "pl", "es"].map((country) => (
									<Dropdown.Item
										key={country}
										onSelect={() => {
											i18n.changeLanguage(country);
											localStorage.setItem("lang", country);
										}}
									>
										<div className="country-select">
											<h5>{countryNames[country]}</h5>
										</div>
									</Dropdown.Item>
								))}
							</Dropdown>
						</div>
					</div>
				</div>
			)}
		</Translation>
	);
};

export default HeaderMessage;
