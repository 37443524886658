import "./style.less";

//Component Import

import SupportContactsPanel from "../../components/SupportContactsPanel";
import KnowledgeBaseSearchPanel from "../../components/KnowledgeBaseSearchPanel";
import PlansPanel from "../../components/PlansPanel";
import DemoRequestPanel from "../../components/DemoRequestPanel";
import PopularArticlesPanel from "../../components/PopularArticlesPanel";
import { List } from "rsuite";
import React, { useEffect } from "react";
import HeaderPanel from "../../components/HeaderPanel";
import QuestionsPanel from "../../components/QuestionsPanel";
import HelpOptionsPanel from "../../components/HelpOptionsPanel";
import SEO from "../../components/SEO";

const Support = (props) => {
  useEffect(() => {
    let section = window.location.hash;
    let el = document.getElementById(section.split("#")[1]);
    if (el) {
      el.scrollIntoView();
    }
  }, [window.location.hash]);

  const isMobile = props.isMobile;
  return (
    <React.Fragment>
      <SEO pageTitle="Support | URL Builder Online" />
      <section id="support-contacts-section">
        <HeaderPanel header="faqs_page.header.page_name.text" />
        <HelpOptionsPanel />
        <QuestionsPanel />
      </section>
      <section id="support-knowledge-section"></section>
    </React.Fragment>
  );
};

export default Support;
