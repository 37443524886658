import "./style.less";
import { Navbar, Nav, Drawer, Affix, Button, IconButton, Icon } from "rsuite";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18n from "../../i18n";
import { sendDatalayerEvent } from "../../piwikEvents";

const MainNav = (props) => {
	const [drawer, toggleDrawer] = useState(false);
	const isMobile = props.isMobile;

	return (
		<Translation>
			{(t) => (
				<Affix top={0}>
					<div className="page-nav">
						<Navbar>
							<Navbar.Header className="nav-header">
								<Link to="/" className="nav-logo-link">
									<img src="/main_logo.png" alt="url builder logo" className="logo-img" />
									<h3 className="logo">urlbuilder.online</h3>
								</Link>
							</Navbar.Header>
							<Navbar.Body>
								{(isMobile && (
									<React.Fragment>
										<Icon size="2x" icon="bars" onClick={() => toggleDrawer(true)} />

										<Drawer
											show={drawer}
											placement="bottom"
											size="sm"
											onHide={() => toggleDrawer(false)}
										>
											<div className="nav-drawer">
												<Link to={{ pathname: "/" }}>
													<Button appearance="link" onClick={() => toggleDrawer(false)}>
														{t("all_pages.header.menu.text_1")}
													</Button>
												</Link>
												<Link to={{ pathname: "/features" }}>
													<Button appearance="link" onClick={() => toggleDrawer(false)}>
														{t("all_pages.header.menu.text_2")}
													</Button>
												</Link>
												<Link
													to={{
														pathname: "/pricing",
													}}
												>
													<Button appearance="link" onClick={() => toggleDrawer(false)}>
														{t("all_pages.header.menu.text_4")}
													</Button>
												</Link>
												<Link to={{ pathname: "/services" }}>
													<Button appearance="link" onClick={() => toggleDrawer(false)}>
														{t("all_pages.header.menu.text_6")}
													</Button>
												</Link>
												<Link
													to={{
														pathname: "/support",
													}}
												>
													<Button appearance="link" onClick={() => toggleDrawer(false)}>
														{t("all_pages.header.menu.text_5")}
													</Button>
												</Link>
												<Button
													className="button-primary"
													onClick={() => {
														sendDatalayerEvent(
															"site_intent",
															{
																category: "CTA",
																action: "intent_book_demo",
																event_name: "Header Menu :: Intent Marcar Demo",
															},
															{
																ctaName: i18n.t("all_pages.header.menu.button_cta"),
																elementInteraction: "click",
																elementName: "button",
																pageLocation: "header",
																product: "urlbuilder",
																subdomainLocation: "urlbuilder.online",
															}
														);
														window.Calendly.initPopupWidget({
															url: `https://calendly.com/urlbuilder/30min?hide_gdpr_banner=1`,
														});
													}}
												>
													{t("all_pages.header.menu.button_cta")}
												</Button>
												{/*
                        
                        <Link
                          to={{
                            pathname: window.location.pathname,
                            search: "?demo-request",
                          }}
                        >
                          <Button className="button-primary">
                            {t("all_pages.header.menu.button_cta")}
                          </Button>
                        </Link>
                        */}
												<a
													style={{ width: "100%" }}
													href={`https://app.urlbuilder.online?lang=${i18n.language}`}
													target={"_blank"}
													rel="noreferrer"
												>
													<Button className="button-secondary blue">
														{t("all_pages.header.menu.button_login")}
													</Button>
												</a>
											</div>
										</Drawer>
									</React.Fragment>
								)) || (
									<Nav className="main-nav">
										<Link to={{ pathname: "/" }}>
											<Button appearance="link">{t("all_pages.header.menu.text_1")}</Button>
										</Link>
										<Link to={{ pathname: "/features" }}>
											<Button appearance="link">{t("all_pages.header.menu.text_2")}</Button>
											<Link
												to={{
													pathname: "/pricing",
												}}
											>
												<Button appearance="link">{t("all_pages.header.menu.text_4")}</Button>
											</Link>
										</Link>
										<Link to={{ pathname: "/services" }}>
											<Button appearance="link">{t("all_pages.header.menu.text_6")}</Button>
										</Link>
										<Link
											to={{
												pathname: "/support",
											}}
										>
											<Button appearance="link">{t("all_pages.header.menu.text_5")}</Button>
										</Link>
										<Button
											className="button-primary"
											onClick={() => {
												sendDatalayerEvent(
													"site_intent",
													{
														category: "CTA",
														action: "intent_book_demo",
														event_name: "Header Menu :: Intent Marcar Demo",
													},
													{
														ctaName: i18n.t("all_pages.header.menu.button_cta"),
														elementInteraction: "click",
														elementName: "button",
														pageLocation: "header",
														product: "urlbuilder",
														subdomainLocation: "urlbuilder.online",
													}
												);
												window.Calendly.initPopupWidget({
													url: `https://calendly.com/urlbuilder/30min?hide_gdpr_banner=1`,
												});
											}}
											style={{ marginRight: "1em" }}
										>
											{t("all_pages.header.menu.button_cta")}
										</Button>
										{/*
                    <Link
                      to={{
                        search: "?demo-request",
                      }}
                    >
                      <Button className="button-primary">
                        {t("all_pages.header.menu.button_cta")}
                      </Button>
                    </Link>
                     */}
										<a
											href={`https://app.urlbuilder.online?lang=${i18n.language}`}
											target={"_blank"}
											rel="noreferrer"
										>
											<Button className="button-secondary blue">
												{t("all_pages.header.menu.button_login")}
											</Button>
										</a>
									</Nav>
								)}
							</Navbar.Body>
						</Navbar>
					</div>
				</Affix>
			)}
		</Translation>
	);
};

export default MainNav;
