import React from "react";
import { Translation } from "react-i18next";
import { Icon, Panel } from "rsuite";
import "./style.less";

function ProblemPanel(props) {
  const align = props.align;
  const img = props.img;
  const alt = props.alt;
  const title = props.title;
  const type = props.type;

  return (
    <Translation>
      {(t) => (
        <Panel className={`problem-panel ${align}`}>
          <h2>{t(title).toUpperCase()}</h2>
          <div className={`problem-container ${align}`}>
            <div className="problem-points">
              {props.points.map((point) => (
                <div className="problem-point">
                  {(type && <Icon size="2x" icon={type} />) || (
                    <img
                      className="check-img"
                      src="/images/check-img.png"
                      alt="checkmark"
                    />
                  )}
                  <h5>{t(point)}</h5>
                </div>
              ))}
            </div>
            <div className="problem-image">
              <img src={img} alt={alt} />
            </div>
          </div>
        </Panel>
      )}
    </Translation>
  );
}

export default ProblemPanel;
