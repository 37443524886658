import "./style.less";
import {
  Panel,
  Container,
  Drawer,
  Affix,
  Button,
  IconButton,
  Icon,
} from "rsuite";
import React from "react";
import FeatureCard from "../FeatureCard";
import { Translation } from "react-i18next";

const FeaturesPanel = (props) => {
  // const isMobile = props.isMobile;
  const featureData = [
    {
      title: "feature_page.body.features.title_1",
      list: [
        "feature_page.body.features.text_1_list_1",
        "feature_page.body.features.text_1_list_2",
        "feature_page.body.features.text_1_list_3",
        "feature_page.body.features.text_1_list_4",
      ],
      paragraph: ``,
      img: "./images/illustrations/feature_1.svg",
      alt: "several people collaborating on creating and managing UTM parameters for their online marketing campaigns",
    },
    {
      title: "feature_page.body.features.title_2",
      paragraph: `feature_page.body.features.text_2_list_header`,
      list: [
        "feature_page.body.features.text_2_list_1",
        "feature_page.body.features.text_2_list_2",
      ],
      img: "./images/illustrations/feature_2.svg",
      alt: "several people working with a set of rules to automate and streamline their UTM parameter creation and management process",
    },
    {
      title: "feature_page.body.features.title_3",
      paragraph: `feature_page.body.features.text_3_list_header`,
      list: [
        "feature_page.body.features.text_3_list_1",
        "feature_page.body.features.text_3_list_2",
        "feature_page.body.features.text_3_list_3",
        "feature_page.body.features.text_3_list_4",
        "feature_page.body.features.text_3_list_5",
        "feature_page.body.features.text_3_list_6",
        "feature_page.body.features.text_3_list_7",
      ],
      img: "./images/illustrations/feature_3.svg",
      alt: "person using a laptop to apply error prevention rules to ensure the accuracy and consistency of their UTM parameters and web analytics data",
    },
    {
      title: "feature_page.body.features.title_4",
      paragraph: `feature_page.body.features.text_4`,
      img: "./images/illustrations/feature_4.svg",
      alt: "computer showing several types of media and content that can be tracked with UTM parameters",
    },
    {
      title: "feature_page.body.features.title_5",
      paragraph: "feature_page.body.features.text_5",
      img: "./images/illustrations/feature_5.svg",
      alt: "person using a laptop to monitor and analyze the web analytics data from their UTM parameters",
    },
    {
      title: "feature_page.body.features.title_6",
      paragraph: `feature_page.body.features.text_6`,
      img: "./images/illustrations/feature_6.svg",
      alt: "team saving time by using the available templates on the platform",
    },
    {
      title: "feature_page.body.features.title_7",
      paragraph: `feature_page.body.features.text_7`,
      img: "./images/illustrations/feature_7.svg",
      alt: "person using a laptop to access a dashboard that shows various metrics and insights from their UTM parameters and web analytics data",
    },
    /*
    {
      title: "Domínios Personalizados Para Gerar Confiança.",
      paragraph: `Crie links UTM com shortener custom domains e URIs personalizadas, para criar confiança e aumentar a taxa de click.`,
      img: "./images/problem_1.png",
    },
    {
      title: "Ferramenta de Auditoria.",
      paragraph: `A Ferramenta de Auditoria permite-lhe auditar todos os registos criados, editados e eliminados por organização, por equipa, por utilizador. 
      Quem não está a cumprir as políticas definidas?
  `,
      img: "./images/problem_1.png",
    },
     */
    {
      title: "feature_page.body.features.title_9",
      paragraph: `feature_page.body.features.text_9`,
      button: "feature_page.body.features.button_services",
      img: "./images/illustrations/feature_11.svg",
      alt: "team of people working together to create and manage UTM parameters for their online marketing campaigns",
    },
    {
      title: "feature_page.body.features.title_8",
      paragraph: `feature_page.body.features.text_8_message`,
      link: "/pricing",
      linkText: "feature_page.body.features.text_8_link",
      img: null,
    },
  ];
  return (
    <Translation>
      {(t) => (
        <Panel className="features-panel ">
          <Container className="panel-text">
            <h2>{t("feature_page.body.features.title")}</h2>
            <h3>{t("feature_page.body.features.subtitle")}</h3>
          </Container>
          <Container className="panel-features">
            {featureData.map((feature, index) => (
              <FeatureCard feature={feature} index={index}></FeatureCard>
            ))}
          </Container>
        </Panel>
      )}
    </Translation>
  );
};

export default FeaturesPanel;
