import "./style.less";
import { Panel, Container, List, Divider, Button } from "rsuite";
import React from "react";
import { Translation } from "react-i18next";
import i18n from "../../i18n";
import { Link } from "react-router-dom";
import { sendDatalayerEvent } from "../../piwikEvents";

const PlanCard = (props) => {
	// const isMobile = props.isMobile;
	const monthly = props.cycle === "monthly";
	const current = props.current || false;
	const isCategories = props.type === "categories";
	const enterprise = props.isEnterprise;
	const title = props.planName;
	const minimize = props.minimize;
	const description = props.planDescription;
	const priceMontly = props.priceMontly;
	const priceYearly = props.priceYearly / 12;
	const basicFeatures = props.basicFeatures;
	const featuresText = props.featuresText;
	const keyFeatures = props.keyFeatures;
	return (
		<Translation>
			{(t) => (
				<Panel
					className={`plan-card ${(title === "Professional" && " secondary") || "primary"} ${
						isCategories ? "categories" : ""
					}`}
				>
					<Container className={`plan-intro ${(isCategories && "cat") || ""}`}>
						{!isCategories && <h4 className="plan-title">{t(title).toUpperCase()}</h4>}
					</Container>
					<Container className={`plan-pricing ${(isCategories && "annual cat") || ""}`}>
						{(!enterprise && (
							<React.Fragment>
								{(!isCategories && (
									<Container>
										<h3>
											<strong>
												{(monthly && t(priceMontly)) || t(priceYearly)}
												{t("plans_page.body.standard_plan.currency")}
											</strong>
											{"/" + t("plans_page.body.standard_plan.period")}
										</h3>
										{!monthly && <h5>{t("plans_page.body.standard_plan.subtitle_12")}</h5>}
									</Container>
								)) || (
									<div>
										<img
											src="/images/URL White.png"
											alt="url builder logo"
											width={100}
											style={{ marginTop: "2em" }}
										/>
									</div>
								)}
							</React.Fragment>
						)) || (
							<React.Fragment>
								<Container>
									<h3>
										<strong>{t("plans_page.body.enterprise_plan.text_title")}</strong>
									</h3>

									{!monthly && <h5>{t("plans_page.body.standard_plan.subtitle_12")}</h5>}
								</Container>
							</React.Fragment>
						)}{" "}
						{(!enterprise && !isCategories && (
							<React.Fragment>
								<a href={`https://app.urlbuilder.online/signup?lang=${i18n.language}`} target="_blank">
									<Button
										style={{ width: "90%" }}
										className={
											title === "Professional"
												? "button-primary blue"
												: "button-secondary bordered green"
										}
										onClick={() => {
											let position = props.position;
											let price;
											if (props.cycle === "monthly") {
												price = props.priceMontly;
											} else {
												price = props.priceYearly;
											}
											sendDatalayerEvent(
												"site_intent",
												{
													category: "CTA",
													action: "intent_registo",
													event_name: "Preços :: Selecionar Plano",
												},
												{
													ctaName: i18n.t("plans_page.body.standard_plan.button_cta"),
													ctaPosition: position,
													elementInteraction: "click",
													elementName: "button",
													licensingPeriod: props.cycle,
													licensingPlan: props.planName,
													licensingValue: price,
													pageLocation: "body",
													product: "urlbuilder",
													subdomainLocation: "urlbuilder.online",
												}
											);
										}}
									>
										{t("plans_page.body.standard_plan.button_cta").toUpperCase()}
									</Button>
								</a>
							</React.Fragment>
						)) ||
							(!isCategories && (
								<React.Fragment>
									<Link to="/pricing?contact" style={{ display: "flex", textDecoration: "none" }}>
										<Button
											className={"button-secondary bordered green"}
											onClick={() => {
												let position = props.position;
												sendDatalayerEvent(
													"site_intent",
													{
														category: "CTA",
														action: "intent_registo",
														event_name: "Preços :: Selecionar Plano",
													},
													{
														ctaName: i18n.t("plans_page.body.enterprise_plan.button_cta"),
														ctaPosition: position,
														elementInteraction: "click",
														elementName: "button",
														licensingPeriod: props.cycle,
														licensingPlan: props.planName,
														pageLocation: "body",
														product: "urlbuilder",
														subdomainLocation: "urlbuilder.online",
													}
												);
											}}
											style={{ width: "100%" }}
										>
											{t("plans_page.body.enterprise_plan.button_cta")}
										</Button>
									</Link>
								</React.Fragment>
							))}
					</Container>
					<Container className="plan-features">
						<Container>
							<List className="plan-basic-features">
								<Divider />
								<div className="plan-stat">
									{isCategories ? (
										<h5 className="">
											{t("plans_page.body.plans_detail.feat_1.text").toUpperCase()}
										</h5>
									) : (
										<div>
											{props.mobile && (
												<h5>{t("plans_page.body.plans_detail.feat_1.text").toUpperCase()}</h5>
											)}
											<h5 className="">{t(basicFeatures.organizations)}</h5>
										</div>
									)}
								</div>
								<Divider />
								<div className="plan-stat">
									{isCategories ? (
										<h5 className="">{t("plans_page.body.table_colums.line_2").toUpperCase()}</h5>
									) : (
										<div>
											{props.mobile && (
												<h5>{t("plans_page.body.table_colums.line_2").toUpperCase()}</h5>
											)}
											<h5 className="">{t(basicFeatures.users)}</h5>
										</div>
									)}
								</div>
								<Divider />
								<div className="plan-stat">
									{isCategories ? (
										<h5 className="">{t("plans_page.body.table_colums.line_3").toUpperCase()}</h5>
									) : (
										<div>
											{props.mobile && (
												<h5>{t("plans_page.body.table_colums.line_3").toUpperCase()}</h5>
											)}

											<h5 className="">{t(basicFeatures.teams)}</h5>
										</div>
									)}
								</div>
								<Divider />
								<div className="plan-stat">
									{isCategories ? (
										<h5 className="">{t("plans_page.body.table_colums.line_4").toUpperCase()}</h5>
									) : (
										<div>
											{props.mobile && (
												<h5>{t("plans_page.body.table_colums.line_4").toUpperCase()}</h5>
											)}

											<h5 className="">{t(basicFeatures.links)}</h5>
										</div>
									)}
								</div>
								<Divider />
								{isCategories && (
									<div className="plan-stat">
										<h5 className="">{t("plans_page.body.table_colums.line_5").toUpperCase()}</h5>
									</div>
								)}
							</List>

							<div>
								{featuresText && <h5 className="key-features-text"> {t(featuresText)}</h5>}
								{!isCategories && (
									<List className="plan-key-features">
										{keyFeatures.map((feature) => (
											<h5>{t(feature)}</h5>
										))}
									</List>
								)}
							</div>
						</Container>
						{(!enterprise && !isCategories && (
							<React.Fragment>
								<a href={`https://app.urlbuilder.online/signup?lang=${i18n.language}`} target="_blank">
									<Button
										style={{ width: "90%" }}
										className={
											title === "Professional"
												? "button-primary blue"
												: "button-secondary bordered green"
										}
										onClick={() => {
											let position = props.position === "1" ? "4" : "5";
											let price;
											if (props.cycle === "monthly") {
												price = props.priceMontly;
											} else {
												price = props.priceYearly;
											}
											sendDatalayerEvent(
												"site_intent",
												{
													category: "CTA",
													action: "intent_registo",
													event_name: "Preços :: Selecionar Plano",
												},
												{
													ctaName: i18n.t("plans_page.body.standard_plan.button_cta"),
													ctaPosition: position,
													elementInteraction: "click",
													elementName: "button",
													licensingPeriod: props.cycle,
													licensingPlan: props.planName,
													licensingValue: price,
													pageLocation: "body",
													product: "urlbuilder",
													subdomainLocation: "urlbuilder.online",
												}
											);
										}}
									>
										{t("plans_page.body.standard_plan.button_cta").toUpperCase()}
									</Button>
								</a>
							</React.Fragment>
						)) ||
							(!isCategories && (
								<React.Fragment>
									<Link to="/pricing?contact" style={{ display: "flex", textDecoration: "none" }}>
										<Button
											className={"button-secondary bordered green"}
											onClick={() => {
												let position = "6";
												sendDatalayerEvent(
													"site_intent",
													{
														category: "CTA",
														action: "intent_registo",
														event_name: "Preços :: Selecionar Plano",
													},
													{
														ctaName: i18n.t("plans_page.body.enterprise_plan.button_cta"),
														ctaPosition: position,
														elementInteraction: "click",
														elementName: "button",
														licensingPeriod: props.cycle,
														licensingPlan: props.planName,
														pageLocation: "body",
														product: "urlbuilder",
														subdomainLocation: "urlbuilder.online",
													}
												);
											}}
											style={{ width: "100%" }}
										>
											{t("plans_page.body.enterprise_plan.button_cta")}
										</Button>
									</Link>
								</React.Fragment>
							))}
					</Container>
				</Panel>
			)}
		</Translation>
	);
};

export default PlanCard;
