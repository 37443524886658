import "./style.less";
import {
  Panel,
  Container,
  Drawer,
  Affix,
  Button,
  IconButton,
  Icon,
} from "rsuite";
import React from "react";
import { Translation } from "react-i18next";

const TestimonyCard = (props) => {
  // const isMobile = props.isMobile;
  const img = props.testimony.profile;
  const logo = props.testimony.logo;
  const quote = props.testimony.quote;
  const name = props.testimony.name;
  const job = props.testimony.job;
  const linkedIn = props.testimony.linkedIn;
  return (
    <Translation>
      {(t) => (
        <Panel className="testimony-card" shaded>
          <Container className="testimony-panel-info">
            <div
              className="testimony-img"
              style={{ backgroundImage: `url(${img})` }}
            >
              {!img && <Icon icon="user" size="2x"></Icon>}
            </div>
            <img src={logo} alt="company logo" className="company-logo" />
          </Container>
          <Container className="testimony-panel-text">
            <h5>
              <em>“{t(quote)}”</em>
            </h5>
          </Container>
          <Container className="testimony-footer">
            <div className="testimony-details">
              <h5>
                <strong>{t(name)}</strong>
              </h5>
              <h5 className="testimony-job">{t(job)}</h5>
            </div>
            <a href={linkedIn} target="_blank" rel="noreferrer">
              <Icon icon="linkedin" size="3x" />
            </a>
          </Container>
        </Panel>
      )}
    </Translation>
  );
};

export default TestimonyCard;
