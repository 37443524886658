import React from "react";
import { Panel } from "rsuite";
import "./style.less";

function PaymentLogosPanel() {
  const brandImgs = [
    "./images/pricing/ssl.png",
    "./images/pricing/godaddy.png",
    "./images/pricing/visa.png",
    "./images/pricing/mastercard.png",
    "./images/pricing/amex.png",
  ];
  return (
    <Panel className="payment-logos-panel">
      <span id="siteseal">
        <script
          async
          type="text/javascript"
          src="https://seal.godaddy.com/getSeal?sealID=0FQGg9DP36qrNc3X8EsHHKbWn4w0vFaITdqxIFeRTJ4aGkuKYyzKpl6K9eep"
        ></script>
      </span>
      {brandImgs.map((img) => (
        <img src={img} alt={img.split(".")[1].split("/")[3]} />
      ))}
    </Panel>
  );
}

export default PaymentLogosPanel;
