import React from "react";
import { Helmet } from "react-helmet";

function SEO(props) {
  return (
    <Helmet>
      <title>{props.pageTitle}</title>
      <meta
        name="description"
        content={
          "Easily create and manage UTM codes with our powerful URL Builder service. Track your marketing campaigns and improve ROI with accurate analytics."
        }
      />
    </Helmet>
  );
}

export default SEO;
