import "./style.less";

//Component Import

import MainPanel from "../../components/MainPanel";
import SolutionPanel from "../../components/SolutionPanel";
import FeaturesPanel from "../../components/FeaturesPanel";
import FeaturePointsPanel from "../../components/FeaturePointsPanel";
import TestimonyPanel from "../../components/TestimonyPanel";
import { Container, Divider, List } from "rsuite";
import React, { useEffect } from "react";
import KeyPointsPanel from "../../components/KeyPointsPanel";
import BrandsPanel from "../../components/BrandsPanel";
import ProblemPanel from "../../components/ProblemsPanel";
import DemoPanel from "../../components/DemoPanel";
import JoinPanel from "../../components/JoinPanel";
import HeaderPanel from "../../components/HeaderPanel";
import { Translation } from "react-i18next";
import SEO from "../../components/SEO";

const Features = (props) => {
	const isMobile = props.isMobile;

	useEffect(() => {
		let section = window.location.hash;
		let el = document.getElementById(section.split("#")[1]);
		if (el) {
			el.scrollIntoView();
		}
	}, [window.location.hash]);
	return (
		<Translation>
			{(t) => (
				<React.Fragment>
					<SEO pageTitle="Features | URL Builder Online" />
					<section id="intro-section"></section>

					<section id="features-section">
						<HeaderPanel header="all_pages.footer.column_1.text_1" />
						<FeaturesPanel />

						<DemoPanel text noImg position={"3"} />
						<JoinPanel img />
					</section>
				</React.Fragment>
			)}
		</Translation>
	);
};

export default Features;
