import "./style.less";
import {
  Panel,
  Container,
  Carousel,
  Affix,
  Button,
  IconButton,
  Icon,
} from "rsuite";
import React from "react";
import TestimonyCard from "../TestimonyCard";
import { Translation } from "react-i18next";

const TestimonyPanel = (props) => {
  const isMobile = props.isMobile;
  const testimonyData = [
    [
      {
        quote: "home_page.body.testemonials.text_2",
        name: "home_page.body.testemonials.name_2",
        profile: "./images/testimonies/nuno_dias.jpeg",
        job: "home_page.body.testemonials.job_2",
        logo: "./images/logos/cegoc.png",
        linkedIn: "https://www.linkedin.com/in/nuno-dias-webmarketing/",
      },
    ],
    [
      {
        quote: "home_page.body.testemonials.text_2",
        name: "home_page.body.testemonials.name_2",
        profile: "./images/testimonies/nuno_dias.jpeg",
        job: "home_page.body.testemonials.job_2",
        logo: "./images/logos/cegoc.png",
        linkedIn: "https://www.linkedin.com/in/nuno-dias-webmarketing/",
      },
    ],
    [
      {
        quote: "home_page.body.testemonials.text_2",
        name: "home_page.body.testemonials.name_2",
        profile: "./images/testimonies/nuno_dias.jpeg",
        job: "home_page.body.testemonials.job_2",
        logo: "./images/logos/cegoc.png",
        linkedIn: "https://www.linkedin.com/in/nuno-dias-webmarketing/",
      },
    ],
  ];
  return (
    <Translation>
      {(t) => (
        <Panel className="testimony-panel ">
          <Container className="panel-text">
            <h2>{t("home_page.body.testemonials.title")}</h2>
          </Container>
          <Container className="panel-features">
            <Carousel autoplayInterval={5000}>
              {(isMobile &&
                testimonyData.map((testimonyList) =>
                  testimonyList.map((testimony) => (
                    <TestimonyCard testimony={testimony}></TestimonyCard>
                  ))
                )) ||
                testimonyData.map((testimonyList) => (
                  <div className="testimony-card-wrapper">
                    {testimonyList.map((testimony) => (
                      <TestimonyCard testimony={testimony}></TestimonyCard>
                    ))}
                  </div>
                ))}
            </Carousel>
          </Container>
        </Panel>
      )}
    </Translation>
  );
};

export default TestimonyPanel;
