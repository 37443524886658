import React, { useEffect } from "react";
import { Divider, Panel } from "rsuite";
import HeaderPanel from "../../../components/HeaderPanel";
import i18n from "../../../i18n";
import manageData from "../../../services/requestData";

import i18next from "i18next";

import ptVersion1 from "./Current/pt-PT.json";
import enVersion1 from "./Current/en-GB.json";
import esVersion1 from "./Current/es-ES.json";
import plVersion1 from "./Current/pl-PL.json";

import { Translation } from "react-i18next";
import SEO from "../../../components/SEO";

i18next.addResourceBundle("pt", "namespace1", ptVersion1);
i18next.addResourceBundle("en", "namespace1", enVersion1);
i18next.addResourceBundle("es", "namespace1", esVersion1);
i18next.addResourceBundle("pl", "namespace1", plVersion1);

const lang = i18n.language;

function Privacy() {
  useEffect(() => {
    manageData(window, document);
  }, []);
  return (
    <Translation ns={[lang, "namespace1"]}>
      {(t) => (
        <div>
          <SEO pageTitle="Privacy | URL Builder Online" />
          <HeaderPanel header="all_pages.footer.column_1.text_6" />
          <Panel className="legal-panel">
            <h4>
              <br />
              {t("privacy.policy.1.intro1", { ns: "namespace1" })}{" "}
              <img src="./images/email_dpo.png" alt="dpo email address" />
              <br />
              <br />
              {t("privacy.policy.1.intro2", { ns: "namespace1" })}
              <br />
              <br />
            </h4>
            <Divider />
            <h3>
              <strong>
                {t("privacy.policy.1.title.1", { ns: "namespace1" })}{" "}
              </strong>
            </h3>
            <h4>
              <br />
              <strong>
                {t("privacy.policy.1.title.11", { ns: "namespace1" })}{" "}
              </strong>
              <br />
              <br />
              {t("privacy.policy.1.parapraph.111", { ns: "namespace1" })}
              <br />
              <br />
              <ul>
                <li>
                  {t("privacy.policy.1.bullet.101a", { ns: "namespace1" })}
                  <a href="https://www.urlbuilder.online/cookies">
                    {t("privacy.policy.1.bullet.101b", { ns: "namespace1" })}
                  </a>
                </li>
                <li>
                  {t("privacy.policy.1.bullet.102", { ns: "namespace1" })}
                </li>
                <li>
                  {t("privacy.policy.1.bullet.103", { ns: "namespace1" })}
                </li>
                <li>
                  {t("privacy.policy.1.bullet.104", { ns: "namespace1" })}
                </li>
              </ul>
            </h4>
            <h4>
              <br />
              <strong>
                {t("privacy.policy.1.title.12", { ns: "namespace1" })}{" "}
              </strong>
              <br />
              <br />
              {t("privacy.policy.1.parapraph.121", { ns: "namespace1" })}
              <ul>
                <br />
                <li>
                  {t("privacy.policy.1.bullet.201a", { ns: "namespace1" })}
                  <a href="https://www.urlbuilder.online/cookies">
                    {t("privacy.policy.1.bullet.201b", { ns: "namespace1" })}
                  </a>
                </li>{" "}
                <li>
                  {t("privacy.policy.1.bullet.202", { ns: "namespace1" })}
                </li>{" "}
                <li>
                  {t("privacy.policy.1.bullet.203", { ns: "namespace1" })}
                </li>{" "}
                <li>
                  {t("privacy.policy.1.bullet.204", { ns: "namespace1" })}
                </li>{" "}
                <li>
                  {t("privacy.policy.1.bullet.205", { ns: "namespace1" })}
                </li>
              </ul>
              <br />
              {t("privacy.policy.1.parapraph.122", { ns: "namespace1" })}
              <br />
              <br />
            </h4>
            <>
              <Divider />
              <h3>
                <strong>
                  {t("privacy.policy.2.title.20", { ns: "namespace1" })}{" "}
                </strong>
              </h3>
              <h4>
                <br />
                {t("privacy.policy.2.paragraph.1", { ns: "namespace1" })}
                <ul>
                  <li>
                    {t("privacy.policy.2.bullet.1", { ns: "namespace1" })}
                  </li>
                  <li>
                    <li>
                      {t("privacy.policy.2.bullet.2", { ns: "namespace1" })}
                    </li>
                  </li>{" "}
                  <li>
                    <li>
                      {t("privacy.policy.2.bullet.3", { ns: "namespace1" })}
                    </li>
                  </li>{" "}
                  <li>
                    <li>
                      {t("privacy.policy.2.bullet.4", { ns: "namespace1" })}
                    </li>
                  </li>{" "}
                  <li>
                    <li>
                      {t("privacy.policy.2.bullet.5", { ns: "namespace1" })}
                    </li>
                  </li>{" "}
                  <li>
                    <li>
                      {t("privacy.policy.2.bullet.6", { ns: "namespace1" })}
                    </li>
                  </li>{" "}
                  <li>
                    {t("privacy.policy.2.bullet.7", { ns: "namespace1" })}
                  </li>{" "}
                  <li>
                    {t("privacy.policy.2.bullet.8", { ns: "namespace1" })}
                  </li>{" "}
                  <li>
                    {t("privacy.policy.2.bullet.9", { ns: "namespace1" })}
                  </li>
                </ul>
                <br />
                {t("privacy.policy.2.paragraph.2", { ns: "namespace1" })}
              </h4>
              <br />
            </>
            <Divider />
            <h3>
              <strong>
                {t("privacy.policy.3.title", { ns: "namespace1" })}
              </strong>
            </h3>
            <h4>
              <br />
              {t("privacy.policy.3.paragraph.1", { ns: "namespace1" })}
              <ul>
                <li>{t("privacy.policy.3.bullet.1", { ns: "namespace1" })}</li>{" "}
                <li>{t("privacy.policy.3.bullet.2", { ns: "namespace1" })}</li>{" "}
                <li>{t("privacy.policy.3.bullet.3", { ns: "namespace1" })}</li>
                <li>
                  {t("privacy.policy.3.bullet.4", { ns: "namespace1" })}
                </li>{" "}
                <li>{t("privacy.policy.3.bullet.5", { ns: "namespace1" })}</li>
                <li>
                  {t("privacy.policy.3.bullet.6", { ns: "namespace1" })}
                </li>{" "}
                <li>{t("privacy.policy.3.bullet.7", { ns: "namespace1" })}</li>{" "}
                <li>
                  {t("privacy.policy.3.bullet.8a", { ns: "namespace1" })}
                  <img src="./images/email_dpo.png" alt="dpo email address" />
                  {t("privacy.policy.3.bullet.8b", { ns: "namespace1" })}
                </li>{" "}
                <li>{t("privacy.policy.3.bullet.9", { ns: "namespace1" })}</li>
              </ul>
              <br />
              <h3>
                {(i18n.language === "pt" && (
                  <strong>Aceda a todos os seus dados</strong>
                )) || <strong>Access all your data</strong>}
              </h3>
              {(i18n.language === "pt" && (
                <div
                  id="ppms_cm_data_subject"
                  class="ppms_cm_data_subject_widget__wrapper"
                  data-editor-centralize="true"
                  data-main-container="true"
                  data-root="true"
                >
                  <h3 id="ppms_cm_data_subject_header" class="header3">
                    Acesso aos seus dados
                  </h3>
                  <p id="ppms_cm_data_subject_paragraph" class="paragraph">
                    Preencha o formulário para solicitar acesso aos seus dados.
                    Utilizaremos o seu e-mail apenas para processar esta
                    solicitação.
                  </p>
                  <form
                    id="ppms_cm_data_subject_form"
                    class="ppms_cm_data_subject_form"
                    data-disable-select="true"
                  >
                    <label
                      id="ppms_cm_data_subject_form_label"
                      class="ppms_cm_data_subject_form_label"
                    >
                      Correio electrónico
                    </label>
                    <input
                      type="email"
                      name="email"
                      required
                      id="ppms_cm_data_subject_form_input"
                      class="ppms_cm_data_subject_form_input"
                      tabindex="0"
                    />
                    <label
                      id="ppms_cm_data_subject_form_label_request_type"
                      class="ppms_cm_data_subject_form_label"
                    >
                      Tipo de pedido
                    </label>
                    <div
                      id="ppms_cm_data_subject_form_select_wrapper"
                      class="ppms_cm_data_subject_form_select_wrapper"
                      data-disable-select="true"
                      data-select-name="event"
                    >
                      <div
                        id="ppms_cm_data_subject_form_select_btn"
                        data-type="customSelect"
                        class="ppms_select_component_btn"
                        tabindex="0"
                      >
                        <span id="ppms_cm_data_subject_form_select_btn_text">
                          Acesso aos meus dados
                        </span>
                        <span
                          class="ppms_select_component-btn-chevron"
                          id="ppms-84111d03-5640-4af7-8467-9a1ccbc17aca"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            id="ppms-69b1fb4f-2cd3-4379-895b-15e4d0de3451"
                          >
                            <path
                              fill="#131313"
                              d="M8.2,8.7l2.8-2.8l1.4,1.4l-2.8,2.8l-1.4,1.4L4,7.2l1.4-1.4L8.2,8.7z"
                              id="ppms-e8db3997-efab-4fba-9fbc-46524f532cd8"
                            ></path>
                          </svg>
                        </span>
                      </div>
                      <div
                        id="ppms_cm_data_subject_form_select_extendable"
                        class="ppms_select_component_extendable"
                        data-disable-select="true"
                        data-type="customSelectWrapper"
                      >
                        <div
                          id="ppms_cm_data_subject_form_select_extended_item_1"
                          class="ppms_select_component_extended_item"
                          data-type="customOption"
                          data-value="view_data"
                          tabindex="0"
                        >
                          Acesso aos meus dados
                        </div>
                        <div
                          id="ppms_cm_data_subject_form_select_extended_item_2"
                          class="ppms_select_component_extended_item"
                          data-type="customOption"
                          data-value="delete_data"
                          tabindex="0"
                        >
                          Excluir meus dados
                        </div>
                        <div
                          id="ppms_cm_data_subject_form_select_extended_item_3"
                          class="ppms_select_component_extended_item"
                          data-type="customOption"
                          data-value="change_data"
                          tabindex="0"
                        >
                          Alterar meus dados
                        </div>
                      </div>
                    </div>
                    <label
                      id="ppms_cm_data_subject_form_label_textarea"
                      class="ppms_cm_data_subject_form_label"
                    >
                      Mensagem
                    </label>
                    <textarea
                      name="message"
                      rows="7"
                      id="ppms_cm_data_subject_form_textarea"
                      class="ppms_cm_data_subject_form_textarea"
                      tabindex="0"
                    ></textarea>
                    <input
                      type="submit"
                      id="ppms_cm_data_subject_form_submit"
                      class="ppms_cm_data_subject_form_submit"
                      value="ENVIAR"
                      tabindex="0"
                    />
                  </form>
                </div>
              )) ||
                (i18n.language === "en" && (
                  <div
                    id="ppms_cm_data_subject"
                    class="ppms_cm_data_subject_widget__wrapper"
                    data-editor-centralize="true"
                    data-main-container="true"
                    data-root="true"
                  >
                    <h3 id="ppms_cm_data_subject_header" class="header3">
                      Access to your data
                    </h3>
                    <p id="ppms_cm_data_subject_paragraph" class="paragraph">
                      Fill out the form to request access to your data. We'll
                      only use your email to process this request.
                    </p>
                    <form
                      id="ppms_cm_data_subject_form"
                      class="ppms_cm_data_subject_form"
                      data-disable-select="true"
                    >
                      <label
                        id="ppms_cm_data_subject_form_label"
                        class="ppms_cm_data_subject_form_label"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        required
                        id="ppms_cm_data_subject_form_input"
                        class="ppms_cm_data_subject_form_input"
                        tabindex="0"
                      />
                      <label
                        id="ppms_cm_data_subject_form_label_request_type"
                        class="ppms_cm_data_subject_form_label"
                      >
                        Request type
                      </label>
                      <div
                        id="ppms_cm_data_subject_form_select_wrapper"
                        class="ppms_cm_data_subject_form_select_wrapper"
                        data-disable-select="true"
                        data-select-name="event"
                      >
                        <div
                          id="ppms_cm_data_subject_form_select_btn"
                          data-type="customSelect"
                          class="ppms_select_component_btn"
                          tabindex="0"
                        >
                          <span id="ppms_cm_data_subject_form_select_btn_text">
                            Access to my data
                          </span>
                          <span
                            class="ppms_select_component-btn-chevron"
                            id="ppms-5b08f0d8-02f7-465d-9e96-08f39b5266d3"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              id="ppms-dd363a9e-2cfc-490b-a3fd-32565853737d"
                            >
                              <path
                                fill="#131313"
                                d="M8.2,8.7l2.8-2.8l1.4,1.4l-2.8,2.8l-1.4,1.4L4,7.2l1.4-1.4L8.2,8.7z"
                                id="ppms-8c57976a-9273-4160-8681-c9a21fda0cab"
                              ></path>
                            </svg>
                          </span>
                        </div>
                        <div
                          id="ppms_cm_data_subject_form_select_extendable"
                          class="ppms_select_component_extendable"
                          data-disable-select="true"
                          data-type="customSelectWrapper"
                        >
                          <div
                            id="ppms_cm_data_subject_form_select_extended_item_1"
                            class="ppms_select_component_extended_item"
                            data-type="customOption"
                            data-value="view_data"
                            tabindex="0"
                          >
                            Access to my data
                          </div>
                          <div
                            id="ppms_cm_data_subject_form_select_extended_item_2"
                            class="ppms_select_component_extended_item"
                            data-type="customOption"
                            data-value="delete_data"
                            tabindex="0"
                          >
                            Delete my data
                          </div>
                          <div
                            id="ppms_cm_data_subject_form_select_extended_item_3"
                            class="ppms_select_component_extended_item"
                            data-type="customOption"
                            data-value="change_data"
                            tabindex="0"
                          >
                            Change my data
                          </div>
                        </div>
                      </div>
                      <label
                        id="ppms_cm_data_subject_form_label_textarea"
                        class="ppms_cm_data_subject_form_label"
                      >
                        Message
                      </label>
                      <textarea
                        name="message"
                        rows="7"
                        id="ppms_cm_data_subject_form_textarea"
                        class="ppms_cm_data_subject_form_textarea"
                        tabindex="0"
                      ></textarea>
                      <input
                        type="submit"
                        id="ppms_cm_data_subject_form_submit"
                        class="ppms_cm_data_subject_form_submit"
                        value="Send"
                        tabindex="0"
                      />
                    </form>
                  </div>
                )) ||
                (i18n.language === "es" && (
                  <div
                    id="ppms_cm_data_subject"
                    class="ppms_cm_data_subject_widget__wrapper"
                    data-editor-centralize="true"
                    data-main-container="true"
                    data-root="true"
                  >
                    <h3 id="ppms_cm_data_subject_header" class="header3">
                      Acceder a sus datos
                    </h3>
                    <p id="ppms_cm_data_subject_paragraph" class="paragraph">
                      Rellene el formulario para solicitar acceso a sus datos.
                      Solo usaremos su correo electrónico para procesar esta
                      solicitud.
                    </p>
                    <form
                      id="ppms_cm_data_subject_form"
                      class="ppms_cm_data_subject_form"
                      data-disable-select="true"
                    >
                      <label
                        id="ppms_cm_data_subject_form_label"
                        class="ppms_cm_data_subject_form_label"
                      >
                        Correo electrónico
                      </label>
                      <input
                        type="email"
                        name="email"
                        required
                        id="ppms_cm_data_subject_form_input"
                        class="ppms_cm_data_subject_form_input"
                        tabindex="0"
                      />
                      <label
                        id="ppms_cm_data_subject_form_label_request_type"
                        class="ppms_cm_data_subject_form_label"
                      >
                        Tipo de solicitud
                      </label>
                      <div
                        id="ppms_cm_data_subject_form_select_wrapper"
                        class="ppms_cm_data_subject_form_select_wrapper"
                        data-disable-select="true"
                        data-select-name="event"
                      >
                        <div
                          id="ppms_cm_data_subject_form_select_btn"
                          data-type="customSelect"
                          class="ppms_select_component_btn"
                          tabindex="0"
                        >
                          <span id="ppms_cm_data_subject_form_select_btn_text">
                            Acceder a mis datos
                          </span>
                          <span
                            class="ppms_select_component-btn-chevron"
                            id="ppms-e6c55d33-9a52-4b69-aeb4-43416c6da31e"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              id="ppms-03d8c902-d05d-45d4-a321-844c1e2a8dcb"
                            >
                              <path
                                fill="#131313"
                                d="M8.2,8.7l2.8-2.8l1.4,1.4l-2.8,2.8l-1.4,1.4L4,7.2l1.4-1.4L8.2,8.7z"
                                id="ppms-a46bad1e-1030-4034-8b8f-3f4fefb764c9"
                              ></path>
                            </svg>
                          </span>
                        </div>
                        <div
                          id="ppms_cm_data_subject_form_select_extendable"
                          class="ppms_select_component_extendable"
                          data-disable-select="true"
                          data-type="customSelectWrapper"
                        >
                          <div
                            id="ppms_cm_data_subject_form_select_extended_item_1"
                            class="ppms_select_component_extended_item"
                            data-type="customOption"
                            data-value="view_data"
                            tabindex="0"
                          >
                            Acceder a mis datos
                          </div>
                          <div
                            id="ppms_cm_data_subject_form_select_extended_item_2"
                            class="ppms_select_component_extended_item"
                            data-type="customOption"
                            data-value="delete_data"
                            tabindex="0"
                          >
                            Eliminar mis datos
                          </div>
                          <div
                            id="ppms_cm_data_subject_form_select_extended_item_3"
                            class="ppms_select_component_extended_item"
                            data-type="customOption"
                            data-value="change_data"
                            tabindex="0"
                          >
                            Cambiar mis datos
                          </div>
                        </div>
                      </div>
                      <label
                        id="ppms_cm_data_subject_form_label_textarea"
                        class="ppms_cm_data_subject_form_label"
                      >
                        Mensaje
                      </label>
                      <textarea
                        name="message"
                        rows="7"
                        id="ppms_cm_data_subject_form_textarea"
                        class="ppms_cm_data_subject_form_textarea"
                        tabindex="0"
                      ></textarea>
                      <input
                        type="submit"
                        id="ppms_cm_data_subject_form_submit"
                        class="ppms_cm_data_subject_form_submit"
                        value="Enviar"
                        tabindex="0"
                      />
                    </form>
                  </div>
                )) ||
                (i18n.language === "pl" && (
                  <div
                    id="ppms_cm_data_subject"
                    class="ppms_cm_data_subject_widget__wrapper"
                    data-editor-centralize="true"
                    data-main-container="true"
                    data-root="true"
                  >
                    <h3 id="ppms_cm_data_subject_header" class="header3">
                      Dostęp do twoich danych
                    </h3>
                    <p id="ppms_cm_data_subject_paragraph" class="paragraph">
                      Wypełnij formularz, aby otrzymać dostęp do swoich danych.
                      Użyjemy twojego adresu e-mail tylko do realizacji tego
                      zapytania.
                    </p>
                    <form
                      id="ppms_cm_data_subject_form"
                      class="ppms_cm_data_subject_form"
                      data-disable-select="true"
                    >
                      <label
                        id="ppms_cm_data_subject_form_label"
                        class="ppms_cm_data_subject_form_label"
                      >
                        E-mail
                      </label>
                      <input
                        type="email"
                        name="email"
                        required
                        id="ppms_cm_data_subject_form_input"
                        class="ppms_cm_data_subject_form_input"
                        tabindex="0"
                      />
                      <label
                        id="ppms_cm_data_subject_form_label_request_type"
                        class="ppms_cm_data_subject_form_label"
                      >
                        Rodzaj wniosku
                      </label>
                      <div
                        id="ppms_cm_data_subject_form_select_wrapper"
                        class="ppms_cm_data_subject_form_select_wrapper"
                        data-disable-select="true"
                        data-select-name="event"
                      >
                        <div
                          id="ppms_cm_data_subject_form_select_btn"
                          data-type="customSelect"
                          class="ppms_select_component_btn"
                          tabindex="0"
                        >
                          <span id="ppms_cm_data_subject_form_select_btn_text">
                            Dostęp do moich danych
                          </span>
                          <span
                            class="ppms_select_component-btn-chevron"
                            id="ppms-ecbcef3d-6bad-4ad6-806e-05661aecc1a5"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              id="ppms-eae05fae-b082-4b3f-a7d5-ab257ee86647"
                            >
                              <path
                                fill="#131313"
                                d="M8.2,8.7l2.8-2.8l1.4,1.4l-2.8,2.8l-1.4,1.4L4,7.2l1.4-1.4L8.2,8.7z"
                                id="ppms-1d217dae-f950-467e-80c3-b7c33b25f6f1"
                              ></path>
                            </svg>
                          </span>
                        </div>
                        <div
                          id="ppms_cm_data_subject_form_select_extendable"
                          class="ppms_select_component_extendable"
                          data-disable-select="true"
                          data-type="customSelectWrapper"
                        >
                          <div
                            id="ppms_cm_data_subject_form_select_extended_item_1"
                            class="ppms_select_component_extended_item"
                            data-type="customOption"
                            data-value="view_data"
                            tabindex="0"
                          >
                            Dostęp do moich danych
                          </div>
                          <div
                            id="ppms_cm_data_subject_form_select_extended_item_2"
                            class="ppms_select_component_extended_item"
                            data-type="customOption"
                            data-value="delete_data"
                            tabindex="0"
                          >
                            Usunięcie moich danych
                          </div>
                          <div
                            id="ppms_cm_data_subject_form_select_extended_item_3"
                            class="ppms_select_component_extended_item"
                            data-type="customOption"
                            data-value="change_data"
                            tabindex="0"
                          >
                            Zmiana moich danych
                          </div>
                        </div>
                      </div>
                      <label
                        id="ppms_cm_data_subject_form_label_textarea"
                        class="ppms_cm_data_subject_form_label"
                      >
                        Wiadomość
                      </label>
                      <textarea
                        name="message"
                        rows="7"
                        id="ppms_cm_data_subject_form_textarea"
                        class="ppms_cm_data_subject_form_textarea"
                        tabindex="0"
                      ></textarea>
                      <input
                        type="submit"
                        id="ppms_cm_data_subject_form_submit"
                        class="ppms_cm_data_subject_form_submit"
                        value="Wyślij"
                        tabindex="0"
                      />
                    </form>
                  </div>
                ))}
              <br />
              <br />
            </h4>
            <Divider />
            <h3>
              <strong>
                {t("privacy.policy.4.title", { ns: "namespace1" })}
              </strong>
            </h3>
            <h4>
              <br />
              {t("privacy.policy.4.paragraph.1", { ns: "namespace1" })}
              <ul>
                <li>{t("privacy.policy.4.bullet.1", { ns: "namespace1" })}</li>{" "}
                <li>{t("privacy.policy.4.bullet.2", { ns: "namespace1" })}</li>
                <li>
                  {t("privacy.policy.4.bullet.3", { ns: "namespace1" })}
                </li>{" "}
                <li>{t("privacy.policy.4.bullet.4", { ns: "namespace1" })}</li>
                <li>{t("privacy.policy.4.bullet.5", { ns: "namespace1" })}</li>
                <li>
                  {t("privacy.policy.4.bullet.6", { ns: "namespace1" })}
                </li>{" "}
                <li>
                  {t("privacy.policy.4.bullet.7", { ns: "namespace1" })}
                  <ul>
                    <li>
                      {t("privacy.policy.4.bullet.71", { ns: "namespace1" })}
                    </li>
                  </ul>
                </li>{" "}
                <li>{t("privacy.policy.4.bullet.8", { ns: "namespace1" })}</li>
                <li>
                  {t("privacy.policy.4.bullet.9", { ns: "namespace1" })}
                  <ul>
                    <li>
                      {t("privacy.policy.4.bullet.91", { ns: "namespace1" })}
                    </li>{" "}
                    <li>
                      {t("privacy.policy.4.bullet.92", { ns: "namespace1" })}
                    </li>{" "}
                    <li>
                      {t("privacy.policy.4.bullet.93", { ns: "namespace1" })}
                    </li>
                    <li>
                      {t("privacy.policy.4.bullet.94", { ns: "namespace1" })}
                    </li>
                    <li>
                      {t("privacy.policy.4.bullet.95", { ns: "namespace1" })}
                    </li>
                  </ul>
                </li>{" "}
                <li>{t("privacy.policy.4.bullet.10", { ns: "namespace1" })}</li>{" "}
              </ul>{" "}
              <br />
              {t("privacy.policy.4.paragraph.2", { ns: "namespace1" })}
              <ul>
                <li>
                  <a href="https://piwik.pro/privacy-policy/">
                    {t("privacy.policy.4.paragraph.link.10", {
                      ns: "namespace1",
                    })}
                  </a>
                </li>
                <li>
                  <a href="https://stripe.com/en-gb-pt/privacy">
                    {t("privacy.policy.4.paragraph.link.20", {
                      ns: "namespace1",
                    })}
                  </a>
                </li>
                <li>
                  <a href="https://www.occ.pt/fotos/editor2/toconline_privacidade.pdf">
                    {t("privacy.policy.4.paragraph.link.30", {
                      ns: "namespace1",
                    })}
                  </a>
                </li>
                <li>
                  <a href="https://policies.google.com/privacy">
                    {t("privacy.policy.4.paragraph.link.40", {
                      ns: "namespace1",
                    })}
                  </a>
                </li>
                <li>
                  <a href="https://www.hotjar.com/legal/policies/privacy/">
                    {t("privacy.policy.4.paragraph.link.50", {
                      ns: "namespace1",
                    })}
                  </a>
                </li>
                <li>
                  <a href="https://privacy.microsoft.com/en-gb/privacystatement">
                    {t("privacy.policy.4.paragraph.link.60", {
                      ns: "namespace1",
                    })}
                  </a>
                </li>
                <li>
                  <a href="https://policies.google.com/privacy">
                    {t("privacy.policy.4.paragraph.link.70", {
                      ns: "namespace1",
                    })}
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/legal/privacy-policy">
                    {t("privacy.policy.4.paragraph.link.80", {
                      ns: "namespace1",
                    })}
                  </a>
                </li>
                <li>
                  <a href="https://www.sendinblue.com/legal/privacypolicy/">
                    {t("privacy.policy.4.paragraph.link.90", {
                      ns: "namespace1",
                    })}
                  </a>
                </li>
                <li>
                  <a href="https://www.twilio.com/legal/privacy#twilio-privacy-notice">
                    {t("privacy.policy.4.paragraph.link.100", {
                      ns: "namespace1",
                    })}
                  </a>
                </li>
                <li>
                  <a href="https://www.atlassian.com/legal/privacy-policy">
                    {t("privacy.policy.4.paragraph.link.110", {
                      ns: "namespace1",
                    })}
                  </a>
                </li>
                <li>
                  <a href="https://calendly.com/privacy">
                    {t("privacy.policy.4.paragraph.link.120", {
                      ns: "namespace1",
                    })}
                  </a>
                </li>
                <li>
                  <a href="https://zapier.com/help/account/data-management/data-privacy-at-zapier">
                    {t("privacy.policy.4.paragraph.link.130", {
                      ns: "namespace1",
                    })}
                  </a>
                </li>
                <li>
                  <a href="https://productfruits.com/policies/privacy">
                    {t("privacy.policy.4.paragraph.link.140", {
                      ns: "namespace1",
                    })}
                  </a>
                </li>
              </ul>{" "}
              <br />
              {t("privacy.policy.4.paragraph.3", { ns: "namespace1" })}
              <br />
              <br />
            </h4>
            <Divider />
            <h3>
              <strong>
                {t("privacy.policy.5.title", { ns: "namespace1" })}
              </strong>
            </h3>
            <h4>
              <br />
              {t("privacy.policy.5.paragraph.1", { ns: "namespace1" })}
              <br />
              <br />
            </h4>
            <Divider />
            <h3>
              <strong>
                {t("privacy.policy.6.title", { ns: "namespace1" })}
              </strong>
            </h3>
            <h4>
              <br />
              {t("privacy.policy.6.paragraph.1", { ns: "namespace1" })}
              <br />
              <br />
            </h4>
            <Divider />
            <h3>
              <strong>
                {t("privacy.policy.7.title", { ns: "namespace1" })}
              </strong>
            </h3>
            <h4>
              <br />
              {t("privacy.policy.7.paragraph.1", { ns: "namespace1" })}
              <br />
              <br />
            </h4>
            <Divider />
            <h3>
              <strong>
                {t("privacy.policy.close.title", { ns: "namespace1" })}
              </strong>
            </h3>
            <h4>
              {t("privacy.policy.close.paragraph.1", { ns: "namespace1" })}
              {":"} <img src="./images/email_dpo.png" alt="dpo email address" />
              <br />
              <br />
              <strong>
                {t("privacy.policy.7.actualization.text.1", {
                  ns: "namespace1",
                })}{" "}
              </strong>
              {t("privacy.policy.7.actualization.text.2", {
                ns: "namespace1",
              })}
            </h4>
          </Panel>
        </div>
      )}
    </Translation>
  );
}

//<img src="./images/email_dpo.png" /> Close paragraph 1 substituir texto por imagem

export default Privacy;
