const manageData = function (window, document, undefined) {
  var PREFIX = "ppms_select_component";
  var CLASSES = {
    DROP_DOWN: PREFIX + "_btn",
    ITEM: PREFIX + "_extended_item",
    LIST_EXPANDED: "expanded",
    ITEM_ACTIVE: PREFIX + "_extended_item-active",
  };
  var SELECTORS = {
    WRAPPER: ".ppms_cm_data_subject_form_select_wrapper",
    DROP_DOWN: "." + CLASSES.DROP_DOWN,
    LABEL: "." + PREFIX + "_btn span",
    LIST: "." + PREFIX + "_extendable",
    ITEM: "." + CLASSES.ITEM,
  };
  var RX_CLOSE = new RegExp(
    "\b" + CLASSES.ITEM + "|" + CLASSES.DROP_DOWN + "\b"
  );
  var wrappers = document.querySelectorAll(SELECTORS.WRAPPER);
  var i;
  var timer = {};
  for (i = 0; i < wrappers.length; ++i) {
    init(wrappers[i], i);
  }
  function init(wrapper, index) {
    var select = wrapper.querySelector(SELECTORS.DROP_DOWN);
    addSelect(wrapper, index);
    var form = (function (el) {
      while (!(el = el.parentElement) | (el.tagName.toUpperCase() === "FORM"));
      return el;
    })(wrapper);
    if (form !== null) {
      addEventListener(form, "reset", function () {
        var optionsContainer = wrapper.querySelector(SELECTORS.LIST);
        if (!optionsContainer || optionsContainer.children.length === 0) return;
        chooseOption(wrapper, select, optionsContainer.children[0]);
      });
      addEventListener(form, "submit", function (event) {
        event = event || window.event;
        window.sendUserDataSubjectRequest(event.target);
        preventDefault(event);
      });
    }
    addEventListener(select, "mousedown", function (event) {
      event = event || window.event;
      if (event.button === 0 || and(isIE8(), event.button === 1)) {
        toggleSelect(wrapper);
      }
    });
    addEventListener(select, ["focus", "focusin"], function () {
      focusHandler(index);
      openSelect(wrapper);
    });
    addEventListener(select, ["blur", "focusout"], function (event) {
      blurHandler(wrapper, index, event);
    });
    addEventListener(select, "keydown", function (event) {
      event = event || window.event;
      switch (event.code | event.key | event.keyCode) {
        case "ArrowDown":
        case "Down":
        case 40:
          preventDefault(event);
          openSelect(wrapper);
          wrapper.querySelector(SELECTORS.ITEM).focus();
          break;
        case "Tab":
        case 9:
          if (event.shiftKey) {
            closeSelect(wrapper);
          } else if (isSelectOpened(wrapper)) {
            preventDefault(event);
            wrapper.querySelector(SELECTORS.ITEM).focus();
          }
          break;
        case "Enter":
        case 13:
        case "Space":
        case "Spacebar":
        case " ":
        case 32:
          preventDefault(event);
          toggleSelect(wrapper);
          break;
      }
    });
  }
  function blurHandler(wrapper, index, event) {
    event = event || window.event;
    if (
      !event.relatedTarget ||
      !event.relatedTarget.className.toString().match(RX_CLOSE)
    ) {
      timer[index] = setTimeout(function () {
        closeSelect(wrapper);
      }, 5);
    }
  }
  function and(a, b) {
    return !(!a || !b);
  }
  function isIE8() {
    return and(document.all, !document.addEventListener);
  }
  function preventDefault(event) {
    if ("preventDefault" in event) {
      event.preventDefault();
    } else {
      event.returnValue = false;
    }
  }
  function addSelect(wrapper, index) {
    var optionsContainer = wrapper.querySelector(SELECTORS.LIST);
    var select, i, option, origOption;
    select = createSelect(wrapper);
    for (i = 0; i < optionsContainer.children.length; ++i) {
      origOption = optionsContainer.children[i];
      if (origOption.nodeType === 8) {
        continue;
      }
      option = createOption(wrapper, index, select, origOption);
      select.appendChild(option);
    }
    wrapper.appendChild(select);
  }
  function createSelect(wrapper) {
    var select = document.createElement("select");
    select.setAttribute("hidden", "hidden");
    select.setAttribute("aria-hidden", "true");
    select.setAttribute("name", wrapper.getAttribute("data-select-name"));
    return select;
  }
  function createOption(wrapper, index, select, origOption) {
    var option = document.createElement("option");
    option.setAttribute("value", origOption.getAttribute("data-value"));
    option.setAttribute("label", origOption.innerHTML);
    option.innerHTML = origOption.innerHTML;
    addEventListener(origOption, "mousedown", function (event) {
      event = event || window.event;
      if (event.button === 0 || and(isIE8(), event.button === 1)) {
        chooseOption(wrapper, select, origOption);
      }
    });
    addEventListener(origOption, "keydown", function (event) {
      var node;
      event = event || window.event;
      switch (event.code | event.key | event.keyCode) {
        case "Enter":
        case 13:
        case "Space":
        case "Spacebar":
        case " ":
        case 32:
          preventDefault(event);
          chooseOption(wrapper, select, origOption);
          break;
        case "ArrowUp":
        case "Up":
        case 38:
          preventDefault(event);
          node = previousElementSibling(origOption);
          if (node) {
            node.focus();
          } else {
            lastElementChild(origOption.parentNode).focus();
          }
          break;
        case "ArrowDown":
        case "Down":
        case 40:
          preventDefault(event);
          node = nextElementSibling(origOption);
          if (node) {
            node.focus();
          } else {
            firstElementChild(origOption.parentNode).focus();
          }
          break;
      }
    });
    addEventListener(origOption, ["blur", "focusout"], function (event) {
      blurHandler(wrapper, index, event);
    });
    addEventListener(origOption, ["focus", "focusin"], function () {
      focusHandler(index);
    });
    return option;
  }
  function focusHandler(index) {
    if (timer[index]) {
      clearTimeout(timer[index]);
      timer[index] = null;
    }
  }
  function chooseOption(wrapper, select, option) {
    var label = wrapper.querySelector(SELECTORS.LABEL);
    select.value = option.getAttribute("data-value");
    label.innerHTML = option.innerHTML;
    closeSelect(wrapper);
  }
  function isSelectOpened(wrapper) {
    var select = wrapper.querySelector(SELECTORS.DROP_DOWN);
    return hasClass(select, CLASSES.LIST_EXPANDED);
  }
  function openSelect(wrapper) {
    var select = wrapper.querySelector(SELECTORS.DROP_DOWN);
    addClass(select, CLASSES.LIST_EXPANDED);
  }
  function closeSelect(wrapper) {
    var select = wrapper.querySelector(SELECTORS.DROP_DOWN);
    removeClass(select, CLASSES.LIST_EXPANDED);
  }
  function toggleSelect(wrapper) {
    var select = wrapper.querySelector(SELECTORS.DROP_DOWN);
    toggleClass(select, CLASSES.LIST_EXPANDED);
  }
  function addEventListener(element, events, handler) {
    var i;
    if (!(events instanceof Array)) {
      events = [events];
    }
    for (i = 0; i < events.length; ++i) {
      if (element.addEventListener) {
        element.addEventListener(events[i], handler, false);
      } else {
        element.attachEvent("on" + events[i], handler);
      }
    }
  }
  function addClass(element, className) {
    if (element.className.indexOf(className) === -1) {
      element.className += " " + className;
    }
  }
  function removeClass(element, className) {
    if (element.className.indexOf(className) !== -1) {
      element.className = element.className.replace(" " + className, "");
    }
  }
  function toggleClass(element, className) {
    if (element.className.indexOf(className) !== -1) {
      element.className = element.className.replace(" " + className, "");
    } else {
      element.className += " " + className;
    }
  }
  function hasClass(element, className) {
    return element.className.indexOf(className) !== -1;
  }
  function previousElementSibling(element) {
    var node;
    if ("previousElementSibling" in element) {
      return element.previousElementSibling;
    }
    while ((node = element.previousSibling)) {
      if (node.nodeType === 1) {
        return node;
      }
    }
    return null;
  }
  function nextElementSibling(element) {
    var node;
    if ("nextElementSibling" in element) {
      return element.nextElementSibling;
    }
    while ((node = element.nextSibling)) {
      if (node.nodeType === 1) {
        return node;
      }
    }
    return null;
  }
  function firstElementChild(element) {
    var node,
      i = 0;
    if ("firstElementChild" in element) {
      return element.firstElementChild;
    }
    while ((node = element.childNodes[i++])) {
      if (node.nodeType === 1) {
        return node;
      }
    }
    return null;
  }
  function lastElementChild(element) {
    var node,
      i = 0;
    if ("lastElementChild" in element) {
      return element.lastElementChild;
    }
    while ((node = element.childNodes[element.childNodes.length - ++i])) {
      if (node.nodeType === 1) {
        return node;
      }
    }
    return null;
  }
};

export default manageData;
