import "./style.less";

//Component Import

import PricingIntroPanel from "../../components/PricingIntroPanel";
import GuaranteePanel from "../../components/GuaranteePanel";
import PlansPanel from "../../components/PlansPanel";
import DemoRequestPanel from "../../components/DemoRequestPanel";
import QuestionsPanel from "../../components/QuestionsPanel";
import { Divider, List } from "rsuite";
import React, { useEffect } from "react";
import HeaderPanel from "../../components/HeaderPanel";
import PricingMainPanel from "../../components/PricingMainPanel";
import DemoPanel from "../../components/DemoPanel";
import CardListMobile from "../../components/CardListMobile";
import PaymentLogosPanel from "../../components/PaymentLogosPanel";
import FeatureDetailsTable from "../../components/FeatureDetailsTable";
import { Link } from "react-router-dom";
import SEO from "../../components/SEO";
import ContactPopup from "../../components/ContactPopup";

const Pricing = (props) => {
  useEffect(() => {
    let section = window.location.hash;
    let el = document.getElementById(section.split("#")[1]);
    if (el) {
      el.scrollIntoView();
    }
  }, [window.location.hash]);

  const isMobile = props.isMobile;
  return (
    <React.Fragment>
      <SEO pageTitle="Pricing | URL Builder Online" />
      <ContactPopup />
      <section id="pricing-intro-section">
        <HeaderPanel header="plans_page.header.page_name.text" />
      </section>
      <section id="plans-section">
        <PricingMainPanel isMobile={isMobile} />

        <PaymentLogosPanel />
        <DemoPanel text="plans_page.body.below_plans.text" noImg position = {"7"}/>
        <Divider />
        <FeatureDetailsTable isMobile={isMobile} />
      </section>
      <section id="faq-section"></section>
    </React.Fragment>
  );
};

export default Pricing;
