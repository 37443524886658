import React from "react";
import { Translation } from "react-i18next";
import { Divider, Panel } from "rsuite";
import HeaderPanel from "../../../components/HeaderPanel";
import i18n from "../../../i18n";
import i18next from "i18next";

import pt from "./Current/pt-PT.json";
import en from "./Current/en-GB.json";
import SEO from "../../../components/SEO";

i18next.addResourceBundle("pt", "namespace1", pt);
i18next.addResourceBundle("en", "namespace1", en);

const lang = i18n.language;

function Terms() {
  return (
    <Translation ns={[lang, "namespace1"]}>
      {(t) => (
        <div>
          <SEO pageTitle="Terms and Conditions | URL Builder Online" />
          <HeaderPanel header="all_pages.footer.column_1.text_5" />
          <Panel className="legal-panel">
            <h4>
              <br />
              {t("terms_of_use.intro", { ns: "namespace1" })}
              <br />
              <br />
            </h4>
            <Divider />
            <h3>
              <strong>
                {t("terms_of_use.1.title.1", { ns: "namespace1" })}
              </strong>
            </h3>
            <h4>
              <br />
              <strong>
                {t("terms_of_use.1.title.11", { ns: "namespace1" })}
                {". "}
              </strong>
              {t("terms_of_use.1.paragraph.111", { ns: "namespace1" })}
            </h4>
            <h4>
              <strong>
                {t("terms_of_use.1.title.12", { ns: "namespace1" })}
                {". "}
              </strong>
              {t("terms_of_use.1.paragraph.112", { ns: "namespace1" })}
            </h4>
            <h4>
              <strong>
                {t("terms_of_use.1.title.13", { ns: "namespace1" })}
                {". "}
              </strong>
              {t("terms_of_use.1.paragraph.113", { ns: "namespace1" })}
              <br />
              <br />
            </h4>
            <Divider />
            <h3>
              <strong>
                {t("terms_of_use.2.title.2", { ns: "namespace1" })}
              </strong>
            </h3>
            <h4>
              <br />
              <strong>
                {t("terms_of_use.2.title.21", { ns: "namespace1" })}
                {". "}
              </strong>
              {t("terms_of_use.2.paragraph.221", { ns: "namespace1" })}
            </h4>
            <h4>
              <strong>
                {t("terms_of_use.2.title.22", { ns: "namespace1" })}
                {". "}
              </strong>
              {t("terms_of_use.2.paragraph.222", { ns: "namespace1" })}
            </h4>
            <h4>
              <strong>
                {t("terms_of_use.2.title.23", { ns: "namespace1" })}
                {". "}
              </strong>
              {t("terms_of_use.2.paragraph.223", { ns: "namespace1" })}
              <br />
              <br />
            </h4>
            <Divider />
            <h3>
              <strong>
                {t("terms_of_use.3.title.3", { ns: "namespace1" })}
              </strong>
            </h3>
            <h4>
              <br />
              <strong>
                {t("terms_of_use.3.title.31", { ns: "namespace1" })}
                {". "}
              </strong>{" "}
              {t("terms_of_use.3.paragraph.331", { ns: "namespace1" })}
            </h4>
            <h4>
              <strong>
                {t("terms_of_use.3.title.32", { ns: "namespace1" })}
                {". "}
              </strong>{" "}
              {t("terms_of_use.3.paragraph.332", { ns: "namespace1" })}
            </h4>
            <h4>
              <strong>
                {t("terms_of_use.3.title.33", { ns: "namespace1" })}
                {". "}
              </strong>{" "}
              {t("terms_of_use.3.paragraph.333", { ns: "namespace1" })}
              <br />
              <br />
            </h4>
            <Divider />
            <h3>
              <strong>
                {t("terms_of_use.4.title.4", { ns: "namespace1" })}
              </strong>
            </h3>
            <h4>
              <br />
              <strong>
                {t("terms_of_use.4.title.41", { ns: "namespace1" })}
                {". "}
              </strong>{" "}
              {t("terms_of_use.4.paragraph.441", { ns: "namespace1" })}
            </h4>
            <h4>
              <strong>
                {t("terms_of_use.4.title.42", { ns: "namespace1" })}
                {". "}
              </strong>{" "}
              {t("terms_of_use.4.paragraph.442", { ns: "namespace1" })}
              <br />
              <br />
            </h4>
            <Divider />
            <h3>
              <strong>
                {t("terms_of_use.5.title.5", { ns: "namespace1" })}
              </strong>
            </h3>
            <h4>
              <br />
              <strong>
                {t("terms_of_use.5.title.51", { ns: "namespace1" })}
                {". "}
              </strong>{" "}
              {t("terms_of_use.5.paragraph.551_part 1", { ns: "namespace1" })}{" "}
              <a href="https://www.urlbuilder.online/privacy">
                {t("terms_of_use.5.paragraph.551_part2_link", {
                  ns: "namespace1",
                })}{" "}
              </a>
              {t("terms_of_use.5.paragraph.551_parte3", { ns: "namespace1" })}
            </h4>
            <h4>
              <strong>
                {t("terms_of_use.5.title.52", { ns: "namespace1" })}
                {". "}
              </strong>{" "}
              {t("terms_of_use.5.paragraph.552", { ns: "namespace1" })}
            </h4>
            <h4>
              <strong>
                {t("terms_of_use.5.title.53", { ns: "namespace1" })}
                {". "}
              </strong>{" "}
              {t("terms_of_use.5.paragraph.553", { ns: "namespace1" })}
              <br />
              <br />
            </h4>
            <Divider />
            <h3>
              <strong>
                {t("terms_of_use.6.title.6", { ns: "namespace1" })}
              </strong>
            </h3>
            <h4>
              <br />
              {t("terms_of_use.6.paragraph.6", { ns: "namespace1" })}
              <br />
              <br />
            </h4>
            <Divider />
            <h3>
              <strong>
                {t("terms_of_use.7.title.7", { ns: "namespace1" })}
              </strong>
            </h3>
            <h4>
              <br />
              {t("terms_of_use.7.paragraph.7", { ns: "namespace1" })}
              <br />
              <br />
            </h4>
            <Divider />
            <h3>
              <strong>
                {t("terms_of_use.8.title.8", { ns: "namespace1" })}
              </strong>
            </h3>
            <h4>
              <br />
              {t("terms_of_use.8.paragraph.8", { ns: "namespace1" })}
              <br />
              <br />
            </h4>
            <Divider />
            <h3>
              <strong>
                {t("terms_of_use.9.title.9", { ns: "namespace1" })}
              </strong>
            </h3>
            <h4>
              <br />
              <strong>
                {t("terms_of_use.9.title.91", { ns: "namespace1" })}
                {". "}
              </strong>{" "}
              {t("terms_of_use.9.paragraph.991", { ns: "namespace1" })}
            </h4>
            <h4>
              <strong>
                {t("terms_of_use.9.title.92", { ns: "namespace1" })}
                {". "}
              </strong>{" "}
              {t("terms_of_use.9.paragraph.992", { ns: "namespace1" })}
            </h4>
            <h4>
              <strong>
                {t("terms_of_use.9.title.93", { ns: "namespace1" })}
                {". "}
              </strong>{" "}
              {t("terms_of_use.9.paragraph.993", { ns: "namespace1" })}
            </h4>
            <br />
            <Divider />
            <h3>
              <strong>
                {t("terms_of_use.10.title.10", { ns: "namespace1" })}
              </strong>
            </h3>
            <h4>
              <br />
              {t("terms_of_use.10.paragraph.10", { ns: "namespace1" })}
              <br />
              <br />
            </h4>
            <Divider />
            <h3>
              <strong>
                {t("terms_of_use.11.title.11", { ns: "namespace1" })}
              </strong>
            </h3>
            <h4>
              <br />
              {t("terms_of_use.11.paragraph.11", { ns: "namespace1" })}
              <br />
              <br />
            </h4>
            <Divider />
            <h3>
              <strong>
                {t("terms_of_use.12.title.12", { ns: "namespace1" })}
              </strong>
            </h3>
            <h4>
              <br />
              {t("terms_of_use.12.paragraph.1212a", { ns: "namespace1" })}
              <img
                src="./images/legal@urlbuilder.online.png"
                alt="legal email address"
              />
              {t("terms_of_use.12.paragraph.1212b", { ns: "namespace1" })}
              <br />
              <br />
              <strong>
                {t("terms_of_use.close.text1", { ns: "namespace1" })}{" "}
              </strong>
              {t("terms_of_use.close.text2", { ns: "namespace1" })}
            </h4>
          </Panel>
        </div>
      )}
    </Translation>
  );
}

export default Terms;
