import React from "react";
import { Panel } from "rsuite";
import "./style.less";

function BrandsPanel() {
  const brandImgs = [
    "./images/logos/cetelem.png",
    "./images/logos/bpi.png",
    "./images/logos/millenium.png",
    "./images/logos/cegoc.png",
  ];
  return (
    <Panel className="brands-panel">
      <div>
        {brandImgs.map((img) => (
          <div className="img-container">
            <img src={img} alt={img.split(".")[1].split("/")[3] + " logo"} />
          </div>
        ))}
      </div>
    </Panel>
  );
}

export default BrandsPanel;
