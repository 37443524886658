import "./style.less";
import {
  Panel,
  Container,
  Drawer,
  Affix,
  Button,
  IconButton,
  Icon,
} from "rsuite";
import React from "react";
import { Translation } from "react-i18next";

const FeaturePointCard = (props) => {
  // const isMobile = props.isMobile;
  const title = props.feature.title;
  const paragraph = props.feature.paragraph;
  const list = props.feature.list;
  const index = props.index;
  const img = props.feature.img;
  const button = props.feature.button;
  return (
    <Translation>
      {(t) => (
        <Panel className="feature-point-card">
          {" "}
          <Container className="feature-panel-img">
            <img src={props.feature.img} alt={props.feature.alt} />
          </Container>
          <Container className="feature-panel-text">
            <div className="feature-title">
              <h4>{t(title)}</h4>
            </div>
            <h4>{t(paragraph)}</h4>
          </Container>
        </Panel>
      )}
    </Translation>
  );
};

export default FeaturePointCard;
