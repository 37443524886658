import React from "react";
import { Translation } from "react-i18next";
import { Panel } from "rsuite";
import "./style.less";

function HeaderPanel(props) {
  return (
    <Translation>{(t)=>(
      <Panel className="header-panel gradient-bg" shaded>
        <h1>{t(props.header)}</h1>
      </Panel>
    )}</Translation>    
  );
}

export default HeaderPanel;
