import React from "react";
import { Helmet } from "react-helmet";

function Recaptcha(props) {
  return (
    <>
      <Helmet></Helmet>
      <div
        class="g-recaptcha"
        data-sitekey="6LeHDRklAAAAAFg0Ug8rV8zEvSJw4MsKfK2jGcVv"
        data-action="login"
      ></div>
    </>
  );
}

export default Recaptcha;
