import "./style.less";

//Component Import

import MainPanel from "../../components/MainPanel";
import SolutionPanel from "../../components/SolutionPanel";
import FeaturesPanel from "../../components/FeaturesPanel";
import FeaturePointsPanel from "../../components/FeaturePointsPanel";
import TestimonyPanel from "../../components/TestimonyPanel";
import { Container, Divider, List } from "rsuite";
import React, { useEffect } from "react";
import KeyPointsPanel from "../../components/KeyPointsPanel";
import BrandsPanel from "../../components/BrandsPanel";
import ProblemPanel from "../../components/ProblemsPanel";
import DemoPanel from "../../components/DemoPanel";
import JoinPanel from "../../components/JoinPanel";
import { Translation } from "react-i18next";

const Home = (props) => {
	const isMobile = props.isMobile;

	useEffect(() => {
		let section = window.location.hash;
		let el = document.getElementById(section.split("#")[1]);
		if (el) {
			el.scrollIntoView();
		}
	}, [window.location.hash]);
	return (
		<Translation>
			{(t) => (
				<React.Fragment>
					<section id="intro-section">
						<MainPanel></MainPanel>
						<KeyPointsPanel />
						<BrandsPanel />
					</section>
					<section id="features-section">
						<ProblemPanel
							align="left"
							type="circle"
							img="./images/illustrations/problem_1.svg"
							alt="tired man by a computer and stacks of papers"
							title="home_page.body.your_problem.title"
							points={[
								"home_page.body.your_problem.text_1",
								"home_page.body.your_problem.text_2",
								"home_page.body.your_problem.text_3",
								"home_page.body.your_problem.text_4",
							]}
						/>
						<ProblemPanel
							align="right"
							img="./images/illustrations/problem_2.svg"
							alt="team collaborating on a project"
							title="home_page.body.our_solution.title"
							points={[
								"home_page.body.our_solution.text_1",
								"home_page.body.our_solution.text_2",
								"home_page.body.our_solution.text_3",
								"home_page.body.our_solution.text_4",
								"home_page.body.our_solution.text_5",
								"home_page.body.our_solution.text_6",
							]}
						/>
						<DemoPanel position={"1"} />
					</section>
					<section id="testimony-section">
						<FeaturePointsPanel />
						<TestimonyPanel isMobile={isMobile} />
						<JoinPanel />
					</section>
				</React.Fragment>
			)}
		</Translation>
	);
};

export default Home;
