import "./style.less";
import {
  Panel,
  Container,
  Drawer,
  Affix,
  Button,
  IconButton,
  Icon,
} from "rsuite";
import React from "react";
import FeatureCard from "../FeatureCard";
import { Translation } from "react-i18next";

const KeyPointsPanel = (props) => {
  // const isMobile = props.isMobile;
  const featureData = [
    {
      title: "home_page.body.features_icons.text_safe",
      img: "./images/key2.png",
      alt: "security warning sign",
    },
    {
      title: "home_page.body.features_icons.text_quality",
      img: "./images/key1.png",
      alt: "data container with a flow depicted inside",
    },
    {
      title: "home_page.body.features_icons.text_taxonomy",
      img: "./images/key4.png",
      alt: "data tree flow",
    },
    {
      title: "home_page.body.features_icons.text_process",
      img: "./images/key3.png",
      alt: "data organization diagram",
    },
  ];
  return (
    <Translation>
      {(t) => (
        <Panel className="key-points-panel ">
          {featureData.map((feature) => (
            <div className="key-point">
              <div className="img-container">
                <img src={feature.img} alt={feature.alt} />
              </div>
              <h5>{t(feature.title)}</h5>
            </div>
          ))}
        </Panel>
      )}
    </Translation>
  );
};

export default KeyPointsPanel;
