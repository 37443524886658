import React from "react";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Divider, Dropdown, Icon, Panel } from "rsuite";
import i18n from "../../i18n";
import "./style.less";

function FooterNav() {
  const countryNames = {
    pt: "Português",
    en: "English",
    pl: "Polskie",
    es: "Español",
  };
  return (
    <Translation>
      {(t) => (
        <Panel className="footer-nav">
          <div className="footer-main">
            <div>
              <h3 className="logo">
                <img
                  src="/images/URL Blue.png"
                  alt="url builder logo"
                  className="logo-img"
                />
                urlbuilder.online
              </h3>
              <Divider />
              <h6>{t("all_pages.footer.product_description.text")}</h6>
              <a href="#" target="_blank" rel="noreferrer">
                <Icon icon="linkedin-square" size="3x" />
              </a>
            </div>
            <h6>{t("all_pages.footer.rights_researved.text")}</h6>
          </div>
          <div className="footer-page-list">
            <Link to="/features">
              <h6>{t("all_pages.header.menu.text_2")}</h6>
            </Link>
            <Link to="/pricing">
              <h6>{t("all_pages.footer.column_1.text_2")}</h6>
            </Link>
            <Link to="/services">
              <h6>{t("all_pages.footer.column_1.text_4")}</h6>
            </Link>
            <Divider />
            <Link to="/terms">
              <h6>{t("all_pages.footer.column_1.text_5")}</h6>
            </Link>
            <Link to="privacy">
              <h6>{t("all_pages.footer.column_1.text_6")}</h6>
            </Link>
            <Link to="cookies">
              <h6>{t("all_pages.footer.column_1.text_7")}</h6>
            </Link>
            <Button
              className="consent-manage-button"
              onClick={() => {
                window.ppms.cm.api("openConsentForm", function (e) {});
              }}
            >
              <h6>{t("all_pages.footer.column_1.text_8")}</h6>
            </Button>
            <div>
              <Dropdown
                className="country-select-dropdown"
                renderTitle={() => (
                  <div className="country-select">
                    <Icon
                      icon="globe"
                      style={{ marginRight: "10px", fontSize: "16px" }}
                    />
                    <h5 style={{ color: "black", marginTop: "0.5em" }}>
                      {countryNames[i18n.language]}
                    </h5>
                  </div>
                )}
                placement="topStart"
              >
                {["pt", "en", "pl", "es"].map((country) => (
                  <Dropdown.Item
                    key={country}
                    onSelect={() => {
                      i18n.changeLanguage(country);
                      localStorage.setItem("lang", country);
                    }}
                  >
                    <div className="country-select">
                      <h5>{countryNames[country]}</h5>
                    </div>
                  </Dropdown.Item>
                ))}
              </Dropdown>
            </div>
          </div>
        </Panel>
      )}
    </Translation>
  );
}

export default FooterNav;
