import "./style.less";
import { Panel, Container, Drawer, Affix, Button, IconButton, Icon, Divider } from "rsuite";
import React from "react";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import DemoPanel from "../DemoPanel";
import { sendDatalayerEvent } from "../../piwikEvents";
import i18n from "../../i18n";

const FeatureCard = (props) => {
	// const isMobile = props.isMobile;
	const title = props.feature.title;
	const paragraph = props.feature.paragraph;
	const list = props.feature.list;
	const index = props.index;
	const img = props.feature.img;
	const alt = props.feature.alt;
	const button = props.feature.button;
	return (
		<Translation>
			{(t) => (
				<>
					<Panel className="feature-card">
						<Container className="feature-panel-text">
							<div className="feature-title">
								<h3 className="feature-index">{`#${index + 1} `}</h3>
								<h3 className="feature-title-text">{t(title)}</h3>
							</div>
							<Divider style={{ margin: "12px 0" }} />
							{paragraph && (
								<h5>
									{t(paragraph)}
									{props.feature.link && (
										<Link to={props.feature.link}>{t(props.feature.linkText)}</Link>
									)}
								</h5>
							)}
							{list && (
								<ul className="feature-ul">
									{list.map((item) => (
										<li>
											<h5>{t(item)}</h5>
										</li>
									))}
								</ul>
							)}
							{button && (
								<Link to="/services#governance" className="feature-button">
									<Button
										className=" button-secondary"
										style={{
											width: "100%",
											whiteSpace: "pre-wrap",
											margin: "0",
										}}
										onClick={() => {
											sendDatalayerEvent(
												"site_intent",
												{
													category: "CTA",
													action: "governance_help_request",
													event_name: "Funcionalidades :: Intent Definir Governance",
												},
												{
													ctaName: i18n.t("feature_page.body.features.button_services"),
													ctaPosition: "2",
													elementInteraction: "click",
													elementName: "button",
													pageLocation: "body",
													product: "urlbuilder",
													subdomainLocation: "urlbuilder.online",
												}
											);
										}}
									>
										{t(button).toLocaleUpperCase()}
									</Button>
								</Link>
							)}
						</Container>
						<Container className="feature-panel-img">{img && <img src={img} alt={alt} />}</Container>
					</Panel>
					{index === 3 && <DemoPanel noImg text position={"1"} />}
				</>
			)}
		</Translation>
	);
};

export default FeatureCard;
