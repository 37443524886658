import "./style.less";
import { Panel, Container, Drawer, Affix, Button, IconButton, Icon } from "rsuite";
import React from "react";
import FeaturePointCard from "../FeaturePointCard";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import { sendDatalayerEvent } from "../../piwikEvents";
import i18n from "../../i18n";

const JoinPanel = (props) => {
	// const isMobile = props.isMobile;
	return (
		<Translation>
			{(t) => (
				<Panel className="join-panel gradient-bg" shaded style={props.img && { marginTop: "10em" }}>
					<div>
						{props.img && (
							<Container className="panel-img">
								<img
									src="./images/pc1.png"
									alt="laptop showing the link creation process of the platform"
								/>
							</Container>
						)}

						<Container className="panel-text">
							<h3>{t("home_page.body.close_body.title")}</h3>
							<Link
								to="/pricing#plans"
								onClick={() => {
									let name;
									let position;
									if (window.location.pathname.split("/")[1] === "features") {
										name = "Funcionalidades :: Intent Aderir";
										position = "4";
									} else {
										name = "Homepage :: Intent Aderir";
										position = "2";
									}
									sendDatalayerEvent(
										"site_intent",
										{
											category: "CTA",
											action: "intent_subscription",
											event_name: name,
										},
										{
											ctaName: i18n.t("home_page.body.close_body.buton"),
											ctaPosition: position,
											elementInteraction: "click",
											elementName: "button",
											pageLocation: "body",
											product: "urlbuilder",
											subdomainLocation: "urlbuilder.online",
										}
									);
								}}
							>
								<Button className="button-primary">{t("home_page.body.close_body.buton")}</Button>
							</Link>
						</Container>
					</div>
				</Panel>
			)}
		</Translation>
	);
};

export default JoinPanel;
