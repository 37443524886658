import React, { useState } from "react";
import { Translation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import {
  Alert,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Icon,
  Input,
  Modal,
} from "rsuite";
import i18n from "../../i18n";
import { sendDatalayerEvent } from "../../piwikEvents";
import { enterpriseRequest, helpRequest } from "../../services/requests";
import Recaptcha from "../Recaptcha";
import "./style.less";

function ContactPopup(props) {
  const path = window.location.search;
  const [vals, setVals] = useState("");
  const [checks, setChecks] = useState([]);

  const handleRequestSubmit = () => {
    console.log("first");
    const values = {
      name: vals.name,
      email: vals.email,
      tel: vals.tel,
      company: vals.company,
      text: vals.text,
      consent: vals.consent,
    };
    enterpriseRequest(values)
      .then((response) => {
        sendDatalayerEvent(
          "site_intent",
          {
            category: "Form",
            action: "enterprise_contact_request",
            event_name: "Price :: Popup Enterprise",
          },
          {
            ctaName: i18n.t("popup.serviços.pedidos_de_cotação.button"),
            domainEmail: vals.email.split("@")[1],
            elementInteraction: "submit",
            elementName: "button",
            formName: "plano enterprise pedido de contacto",
            formStepName: "plano enterprise pedido de contacto",
            licensingPlan: "Enterprise",
            pageLocation: "popup plano enterprise pedido de contacto",
            product: "urlbuilder",
            submitResult: "success",
            subdomainLocation: "urlbuilder.online",
          }
        );
        Alert.success(i18n.t("popup.all.resposta_envio.sucesso"));
        props.history.push(window.location.pathname);
      })
      .catch((error) => {
        let errorMessage = i18n.t("popup.all.resposta_envio.erro");
        console.log(error);
        if (
          error.response &&
          error.response.data.message === "Must be a valid email Adress"
        ) {
          errorMessage = i18n.t("popup.all.resposta_envio.validacao_email");
        }
        sendDatalayerEvent(
          "site_intent",
          {
            category: "Form",
            action: "enterprise_contact_request_error",
            event_name: "Price :: Popup Enterprise",
          },
          {
            ctaName: i18n.t("popup.serviços.pedidos_de_cotação.button"),
            domainEmail: vals.email.split("@")[1],
            elementInteraction: "submit",
            elementName: "button",
            errorName: errorMessage,
            formName: "plano enterprise pedido de contacto",
            formStepName: "plano enterprise pedido de contacto",
            licensingPlan: "Enterprise",
            pageLocation: "popup plano enterprise pedido de contacto",
            product: "urlbuilder",
            submitResult: "error",
            subdomainLocation: "urlbuilder.online",
          }
        );
        Alert.error(errorMessage);
        console.log(error);
      });
  };

  return (
    <Translation>
      {(t) => (
        <Modal
          id="help-request-popup"
          size={"sm"}
          show={path.includes("contact")}
          onHide={() => props.history.push(window.location.pathname)}
          onShow={() => {
            let param = decodeURI(window.location.search.split("=")[1]);
            console.log(param);
            setChecks([param.replace("+ ", "")]);
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <h4>{t("popup.plano_enterprise.pedido_contato.title")}</h4>
            </Modal.Title>
          </Modal.Header>
          <Divider style={{ margin: "0.5em 0" }} />
          <Modal.Body>
            <form>
              <div className="request-item">
                <h5>{t("popup.serviços.pedidos_de_cotação.text_1")}</h5>
                <Input
                  placeholder={t("popup.serviços.pedidos_de_cotação.field_1")}
                  type="name"
                  value={vals.name}
                  onChange={(val) => setVals({ ...vals, name: val })}
                />
              </div>
              <div className="request-item">
                <h5></h5>
                <p>
                  <Icon icon="info" style={{ marginRight: "0.5em" }} />
                  {t("popup.plano_enterprise.pedido_contato.instructions")}
                </p>
              </div>
              <div className="request-item">
                <h5 style={{ marginTop: "0.5em" }}>
                  {t("popup.serviços.pedidos_de_cotação.text_2")}
                </h5>
                <Input
                  placeholder={t("popup.serviços.pedidos_de_cotação.field_2")}
                  type="email"
                  value={vals.email}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  onChange={(val) => setVals({ ...vals, email: val })}
                />
              </div>
              <div className="request-item">
                <h5>{t("popup.plano_enterprise.pedido_contato.text_3")}</h5>
                <Input
                  placeholder={t("popup.serviços.pedidos_de_cotação.field_3")}
                  type="number"
                  value={vals.tel}
                  onChange={(val) => setVals({ ...vals, tel: val })}
                />
              </div>
              <div className="request-item">
                <h5>{t("popup.plano_enterprise.pedido_contato.text_5")}</h5>
                <Input
                  componentClass="textarea"
                  rows={5}
                  maxLength={2000}
                  placeholder={t("popup.serviços.pedidos_de_cotação.field_4")}
                  value={vals.text}
                  onChange={(val) => setVals({ ...vals, text: val })}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Divider style={{ margin: "0.5em 0" }} />
            <div className="checkbox-item">
              <p>
                {t("popup.plano_enterprise.pedido_contato.legal_text")}{" "}
                <Link to="/cookie-policy">
                  {t("popup.plano_enterprise.pedido_contato.text_link")}
                </Link>
              </p>
            </div>
            <Recaptcha />
            <Button
              className="button-primary green"
              disabled={(!vals.email && !vals.tel) || !vals.email.includes("@")}
              onClick={() => handleRequestSubmit()}
            >
              {t("popup.serviços.pedidos_de_cotação.button")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Translation>
  );
}

export default withRouter(ContactPopup);
