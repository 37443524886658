import "./style.less";

//Component Import

import PricingIntroPanel from "../../components/PricingIntroPanel";
import GuaranteePanel from "../../components/GuaranteePanel";
import PlansPanel from "../../components/PlansPanel";
import DemoRequestPanel from "../../components/DemoRequestPanel";
import QuestionsPanel from "../../components/QuestionsPanel";
import { Divider, List } from "rsuite";
import React, { useEffect } from "react";
import HeaderPanel from "../../components/HeaderPanel";
import PricingMainPanel from "../../components/PricingMainPanel";
import DemoPanel from "../../components/DemoPanel";
import CardListMobile from "../../components/CardListMobile";
import PaymentLogosPanel from "../../components/PaymentLogosPanel";
import FeatureDetailsTable from "../../components/FeatureDetailsTable";
import ServicePanel from "../../components/ServicePanel";
import ServiceCard from "../../components/ServiceCard";
import i18n from "../../i18n";
import SEO from "../../components/SEO";

const Services = (props) => {
  useEffect(() => {
    let section = window.location.hash;
    let el = document.getElementById(section.split("#")[1]);
    if (el) {
      el.scrollIntoView();
    }
  }, [window.location.hash]);

  const isMobile = props.isMobile;
  return (
    <React.Fragment>
      <SEO pageTitle="Services | URL Builder Online" />
      <section>
        <HeaderPanel header="services_page.header.page_name.text" />
      </section>
      <section id="sercices-section">
        <ServicePanel
          title="services_page.body.services.box_title_1"
          paragraph_1={`services_page.body.services.text_1_list_header`}
          list={[
            "services_page.body.services.text_1_list_1",
            "services_page.body.services.text_1_list_2",
            "services_page.body.services.text_1_list_3",
            "services_page.body.services.text_1_list_4",
            "services_page.body.services.text_1_list_5",
            "services_page.body.services.text_1_list_6",
          ]}
          card={
            <ServiceCard 
              title="services_page.body.services.box_title_1"
              priceText="services_page.body.services.price_tag_text_1"
              price="500€"
              paragraph_1="services_page.body.services.box_text_1_par_1"
              paragraph_2="services_page.body.services.box_text_1_par_2"
              index={"1"}
            />
          }
          id="training"
        />
        <ServicePanel
          title="services_page.body.services.title_2"
          paragraph_1={`services_page.body.services.text_2_par_1`}
          paragraph_2={`services_page.body.services.text_2_par_2`}
          card={
            <ServiceCard
              title="services_page.body.services.box_title_2"
              additionalTitle="urlbuilder.online"
              priceText="services_page.body.services.price_tag_text_1"
              price="990€"
              paragraph_1="services_page.body.services.box_text_2"
              index={"2"}
            />
          }
          id="implementation"
        />
        <ServicePanel
          title="services_page.body.services.title_3"
          paragraph_1={`services_page.body.services.text_3_par_1`}
          paragraph_2={`services_page.body.services.text_3_par_2`}
          list={[
            "services_page.body.services.text_3_list_1",
            "services_page.body.services.text_3_list_2",
            "services_page.body.services.text_3_list_3",
          ]}
          paragraph_3={`services_page.body.services.text_3_par_3`}
          card={
            <ServiceCard
              title="services_page.body.services.box_title_3"
              additionalTitle="urlbuilder.online"
              priceText="services_page.body.services.price_tag_text_1"
              price="1950€"
              paragraph_1="services_page.body.services.box_text_3_par_1"
              paragraph_2="services_page.body.services.box_text_3_par_2"
              index={"3"}
            />
          }
          id="governance"
        />
        <ServicePanel
          title="popup.serviços.pedidos_de_cotação.checkbox_5"
          paragraph_1={`services_page.body.services.text_4_par_1`}
          paragraph_2={`services_page.body.services.text_4_par_2`}
          list={[
            "services_page.body.services.text_4_list_1",
            "services_page.body.services.text_4_list_2",
            "services_page.body.services.text_4_list_3",
          ]}
          paragraph_3={`services_page.body.services.text_4_par_3`}
          card={
            <ServiceCard
              title="services_page.body.services.box_title_4"
              additionalTitle="urlbuilder.online"
              priceText="services_page.body.services.price_tag_text_1"
              price="3950€"
              paragraph_1="services_page.body.services.box_text_4"
              index={"4"}
            />
          }
          id="taxonomies"
        />
        <ServicePanel
          title="services_page.body.services.title_5"
          paragraph_1={`services_page.body.services.text_5_par_1`}
          paragraph_2={`services_page.body.services.text_5_par_2`}
          paragraph_3={`services_page.body.services.text_5_par_3`}
          card={
            <ServiceCard
              title="services_page.body.services.title_5"
              additionalTitle="Porquê um"
              priceText={"services_page.body.services.box_cta_1"} //"Peça-nos uma cotação"
              index={"5"}
            />
          }
          id="media"
        />
      </section>
      <section id="faq-section"></section>
    </React.Fragment>
  );
};

export default Services;
