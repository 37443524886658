import "./style.less";
import {
  Panel,
  Container,
  Drawer,
  Affix,
  Button,
  IconButton,
  Icon,
} from "rsuite";
import React from "react";
import FeaturePointCard from "../FeaturePointCard";
import { Translation } from "react-i18next";

const FeaturePointsPanel = (props) => {
  // const isMobile = props.isMobile;
  const pointData = [
    {
      title: "home_page.body.resume_value.text_prevention",
      img: "/images/warning2.svg",
      alt: "computer displaying a warning sign",
    },
    {
      title: "home_page.body.resume_value.text_consistency",
      img: "/images/database-checklist2.svg",
      alt: "diagram of a checklist workflow",
    },
    {
      title: "home_page.body.resume_value.text_productivity",
      img: "/images/productivity3.svg",
      alt: "clock with a wrench next to it",
    },
  ];
  return (
    <Translation>
      {(t) => (
        <Panel className="feature-points-panel gradient-bg" shaded>
          <Container className="panel-text">
            <h2>
              <img
                src="/images/URL White.png"
                alt="url builder logo"
                className="logo-img"
              />
              urlbuilder.online{" "}
              <strong>{t("home_page.body.resume_value.title")}</strong>
            </h2>
          </Container>
          <Container className="panel-features">
            {pointData.map((feature) => (
              <FeaturePointCard feature={feature}></FeaturePointCard>
            ))}
          </Container>
        </Panel>
      )}
    </Translation>
  );
};

export default FeaturePointsPanel;
