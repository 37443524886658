import "./style.less";
import {
  Panel,
  Container,
  Drawer,
  Affix,
  Button,
  IconButton,
  Icon,
} from "rsuite";
import React from "react";
import { Translation } from "react-i18next";

const MainPanel = (props) => {
  // const isMobile = props.isMobile;

  return (
    <Translation>
      {(t) => (
        <Panel className="intro-panel gradient-bg" shaded>
          <Container className="panel-text">
            <h1>{t("home_page.slider.value_proposition_statement.text")}</h1>
            <h5>{t("home_page.slider.value_proposition_text.text")}</h5>

            {/*
          <div className="brand-list">
            <img src="./images/logos/cetelem.png" alt="" />
            <img src="./images/logos/ctt.png" alt="" />
            <img src="./images/logos/ageas.png" alt="" />
            <img src="./images/logos/kia.png" alt="" />
            <img src="./images/logos/bmw.png" alt="" />
            <img src="./images/logos/mini.png" alt="" />
          </div>
          */}
          </Container>
          <Container
            className="panel-img"
            style={{
              backgroundImage: " url(/images/circles.png)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain",
            }}
          >
            <img src="./images/pc1.png" alt="" />
          </Container>
        </Panel>
      )}
    </Translation>
  );
};

export default MainPanel;
