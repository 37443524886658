import "rsuite/lib/styles/index.less";
import "./App.less";

import i18n from "./i18n";

//Function Import
import { useMediaQuery } from "react-responsive";
import { createContext, useEffect, useLayoutEffect } from "react";
//Component Import
import {
  BrowserRouter,
  Link,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { Container, Header, Content, Footer, Navbar } from "rsuite";
import HeaderMessage from "./components/HeaderMessage";
import MainNav from "./components/MainNav";

//View Import
import Home from "./views/Home";
import Pricing from "./views/Pricing";
import Support from "./views/Support";
import FooterNav from "./components/FooterNav";
import Features from "./views/Features";
import Services from "./views/Services";
import DemoRequestPopup from "./components/DemoRequestPopup";
import Terms from "./views/Legal/Terms";
import Privacy from "./views/Legal/Privacy";
import Cookie from "./views/Legal/Cookie";
import HelpRequestPopup from "./components/HelpRequestPopup";
import SEO from "./components/SEO";
import { PiwikGlobals } from "./piwikEvents";
//Set context

const ScrollTop = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  return (
    <div className="App">
      <BrowserRouter>
        <SEO pageTitle="URL Builder Online: UTM Management SaaS" />
        <PiwikGlobals />
        <ScrollTop>
          <Container>
            <Header>
              <HeaderMessage
                message="all_pages.header.floating_bar.text_message"
                highlight="all_pages.header.floating_bar.text_highlight"
              />
            </Header>
            <DemoRequestPopup />
            <HelpRequestPopup />
            <MainNav isMobile={isMobile} />
            <Switch>
              <Route
                path="/"
                exact
                render={(props) => <Home isMobile={isMobile} />}
              />
              <Route
                path="/features"
                exact
                render={(props) => <Features isMobile={isMobile} />}
              />
              <Route
                path="/pricing"
                exact
                render={(props) => <Pricing isMobile={isMobile} />}
              />
              <Route
                path="/services"
                exact
                render={(props) => <Services isMobile={isMobile} />}
              />
              <Route
                path="/support"
                exact
                render={(props) => <Support isMobile={isMobile} />}
              />
              <Route
                path="/terms"
                exact
                render={(props) => <Terms isMobile={isMobile} />}
              />
              <Route
                path="/privacy"
                exact
                render={(props) => <Privacy isMobile={isMobile} />}
              />
              <Route
                path="/cookies"
                exact
                render={(props) => <Cookie isMobile={isMobile} />}
              />
            </Switch>
            <FooterNav />
          </Container>
        </ScrollTop>
      </BrowserRouter>
    </div>
  );
};

export default App;
